export const RoleDisplayName = (roleName: string) => {
  switch (roleName) {
    case 'ProjectOwner':
      return 'Project Owner';
    case 'ProjectViewer':
      return 'Project Viewer';
    case 'ProjectCollaborator':
      return 'Project Collaborator';
    default:
      return roleName;
  }
};
