import { ListResponse } from './types';
import { api } from './api';
import { Connection, NewConnection } from '../types/connections';

export const connectionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConnections: build.query<ListResponse<Connection>, { organizationId: string; entityIds?: string[] }>({
      query: ({ organizationId, entityIds }) => {
        const params = new URLSearchParams();
        if (entityIds) params.append('entityIds', entityIds.join(','));
        return {
          url: `api/organizations/${organizationId}/integrations/connections?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result, _error, { organizationId }) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Connections' as const, id })),
              { type: 'Connections', id: `${organizationId}-LIST` },
            ]
          : [],
    }),
    createConnection: build.mutation<
      Connection,
      { organizationId: string; integrationId: string; connection: NewConnection }
    >({
      query: ({ organizationId, integrationId, connection }) => ({
        url: `api/organizations/${organizationId}/integrations/${integrationId}/connections`,
        method: 'POST',
        body: connection,
      }),
      invalidatesTags: (_result, _error, { organizationId }) => [
        { type: 'Connections', id: `${organizationId}-LIST` },
      ],
    }),
    deleteConnection: build.mutation<void, { organizationId: string; integrationId: string; connectionId: string }>({
      query: ({ organizationId, integrationId, connectionId }) => ({
        url: `api/organizations/${organizationId}/integrations/${integrationId}/connections/${connectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { organizationId, connectionId }) => [
        { type: 'Connections', id: connectionId },
        { type: 'Connections', id: `${organizationId}-LIST` },
      ],
    }),
  }),
});

export const { useGetConnectionsQuery, useCreateConnectionMutation, useDeleteConnectionMutation } = connectionsApi;
