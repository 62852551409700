import { Helmet } from 'react-helmet';
import { useGetBillingAccountByIdQuery } from '../../services/billingAccount';
import { useParams } from 'react-router-dom';
import { CreatePaymentMethod } from '../../components/billing/CreatePaymentMethod';
import { Spinner } from '@hyphen/hyphen-components';

export function AccountSetup() {
  let { billingAccountId } = useParams();

  const { data: billingAccount, isLoading, error } = useGetBillingAccountByIdQuery(billingAccountId || '');

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error Fetching Billing Account</div>;
  }

  const showPaymentForm: boolean = !billingAccount?.paymentMethods?.length;

  return (
    <>
      <Helmet>
        <title>Setup Account</title>
      </Helmet>
      {showPaymentForm && !!billingAccount?.providerData?.setupIntent.clientSecret && (
        <CreatePaymentMethod
          clientSecret={billingAccount.providerData.setupIntent.clientSecret}
        />
      )}
    </>
  );
}
