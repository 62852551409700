import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Button, FormikSelectInput, Modal } from '@hyphen/hyphen-components';
import { ModalProps } from '../../../components/types/modal';
import { ApiError } from '../../../components/ApiError';
import { InferType } from 'yup';
import { ConnectionType } from '../../../types/connections';
import { EntityType } from '../../../types/executionContext';
import { useMemo, useRef, useState } from 'react';
import { useCreateConnectionMutation } from '../../../services/connections';
import { Integration } from '../../../types/integrations';
import { CONNECTION_NAME_MAP } from '../../../constants/integrations';

const connectionSchema = yup.object().shape({
  type: yup.object().shape({
    label: yup.string(),
    value: yup.string().required('Type is required'),
  }),
  entity: yup.object().shape({
    label: yup.string(),
    value: yup.string().required('Entity is required'),
  }),
});

export type ConnectionSchema = InferType<typeof connectionSchema>;

interface ConnectionModalProps extends ModalProps {
  title?: string;
  message?: string;
  organizationId: string;
  integration: Integration;
  entity?: {
    type: EntityType;
    id: string;
    name: string;
  };
}

export const ConnectionModal = ({
  isOpen,
  onClose,
  title = 'Create Connection',
  integration,
  entity,
  message = entity ? `Add new connection to ${entity.name}` : 'Add a new connection',
  organizationId,
}: ConnectionModalProps) => {
  const [CreateConnection] = useCreateConnectionMutation();

  const modalRef = useRef<HTMLFormElement>(null);
  const [apiError, setApiError] = useState<any>(null);

  const typeOptions = useMemo(() => {
    const applicableProvides = entity?.type
      ? integration.provides.filter((provides) => provides.for.includes(entity.type))
      : integration.provides;
    return applicableProvides.map((provides) => ({
      label: CONNECTION_NAME_MAP[provides.connectionType],
      value: provides.connectionType,
    }));
  }, [integration, entity?.type]);

  const entityOptions = [
    {
      label: entity?.name || 'Entity',
      value: entity?.id || '',
    },
  ];

  const initialValues = {
    type: {
      label: typeOptions.length === 1 ? typeOptions[0].label : '',
      value: typeOptions.length === 1 ? typeOptions[0].value : '',
    },
    entity: entity
      ? { label: entity.name, value: entity.id }
      : {
          label: '',
          value: '',
        },
  };

  const onSubmit = async (values: ConnectionSchema) => {
    const { type, entity } = values;
    const { error } = await CreateConnection({
      organizationId,
      integrationId: integration.id,
      connection: {
        type: type.value as ConnectionType,
        entity: {
          id: entity.value,
        },
        config: {},
      },
    });

    if (error) {
      setApiError(error);
    } else {
      onClose();
    }
  };

  return (
    <Modal ariaLabelledBy="connectionModal" maxWidth="9xl" isOpen={isOpen} onDismiss={onClose} ref={modalRef}>
      <Formik
        initialValues={initialValues}
        validationSchema={connectionSchema}
        enableReinitialize
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ errors, isSubmitting }) => {
          return (
            <Form noValidate>
              <Box gap={{ base: '2xl', tablet: '4xl' }}>
                <Modal.Header id="connectionModal" title={title} onDismiss={onClose} />
                <Modal.Body gap="2xl">
                  {message && (
                    <Box as="p" color="secondary" fontSize="sm">
                      {message}
                    </Box>
                  )}
                  <Field
                    label="Type"
                    name="type"
                    id="type"
                    options={typeOptions}
                    component={FormikSelectInput}
                    error={errors.type?.value}
                    isRequired
                    isDisabled={isSubmitting}
                    menuPortalTarget={modalRef.current}
                  />
                  {!entity && (
                    <Field
                      label="Entity"
                      name="entity"
                      id="entity"
                      options={entityOptions}
                      component={FormikSelectInput}
                      error={errors.entity?.value}
                      isRequired
                      isDisabled={isSubmitting}
                      menuPortalTarget={modalRef.current}
                    />
                  )}
                  {apiError && <ApiError error={apiError} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={onClose} isDisabled={isSubmitting} shadow="sm">
                    Cancel
                  </Button>
                  <Button variant="primary" isLoading={isSubmitting} type="submit" shadow="sm">
                    Save
                  </Button>
                </Modal.Footer>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
