import { Box, Button, Card, Heading, Icon, toast } from '@hyphen/hyphen-components';
import { App, CidrAllowDenyEntry, useUpdateAppSettingsMutation } from '../../services/apps';
import EnvAccessControlItem, { getAccessControlsBefore, updateAccessControls } from './EnvAccessControlItem';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { FontColor, IconName } from '@hyphen/hyphen-components/dist/types';
import { useCallback, useState } from 'react';
import EnvAccessControlForm, { EditCidrAccessControlSchema } from './EnvAccessControlForm';
import { ApiError } from '../ApiError';

interface EnvVersionsTableProps {
  app: App;
  isDefault: boolean;
  environmentAlternateId?: string;
  type: 'allow' | 'deny';
  accessControls: CidrAllowDenyEntry[];
  defaultRules: CidrAllowDenyEntry[];
}

export default function EnvAccessControlsList({
  type,
  accessControls,
  app,
  isDefault,
  environmentAlternateId,
  defaultRules,
}: EnvVersionsTableProps) {
  const [isAdding, setAdding] = useState(false);
  const ability = useOrganizationAbilityContext();
  const canUpdateApp = ability.can('update', app);

  const [updateAppSettings, { isLoading: appUpdateLoading, error }] = useUpdateAppSettingsMutation();

  const iconProps =
    type === 'allow'
      ? { name: 'c-add' as IconName, color: 'success' as FontColor }
      : { name: 'c-remove' as IconName, color: 'danger' as FontColor };

  const handleAddCancel = useCallback(() => {
    setAdding(false);
  }, []);

  const handleAddClick = useCallback(() => {
    setAdding(true);
  }, []);

  const handleAddSave = useCallback(
    async (data: EditCidrAccessControlSchema) => {
      const accessControlsBefore = getAccessControlsBefore(app, isDefault, environmentAlternateId);
      const listBefore = accessControlsBefore[type] || [];
      const updatedList = [...listBefore, { type: 'cidr' as const, description: data.description, cidr: data.cidr }];

      const { error } = await updateAccessControls(
        app,
        type,
        updatedList,
        isDefault,
        updateAppSettings,
        environmentAlternateId,
      );

      if (!error) {
        toast.success('Added access control rule');
        handleAddCancel();
      }
    },
    [app, handleAddCancel, isDefault, environmentAlternateId, type, updateAppSettings],
  );

  return (
    <Card>
      <Card.Section>
        <Box direction="row" gap="2xl" justifyContent="space-between" alignItems="center">
          <Box gap="sm" direction="row" alignItems="center">
            <Icon {...iconProps} size="md" />
            <Heading as="h4" size="sm">
              {type === 'allow' ? 'Allow' : 'Deny'} List
            </Heading>
          </Box>
          {isAdding ? (
            <Button size="sm" type="button" variant="secondary" onClick={handleAddCancel}>
              Cancel
            </Button>
          ) : (
            canUpdateApp && (
              <Button size="sm" type="button" onClick={handleAddClick}>
                Add
              </Button>
            )
          )}
        </Box>
      </Card.Section>
      {isAdding && (
        <>
          <EnvAccessControlForm
            isLoading={appUpdateLoading}
            handleSave={handleAddSave}
            handleCancel={handleAddCancel}
            initialValues={{ description: '', cidr: '' }}
          />
          {error && <ApiError error={error} title="Error adding access control rule" />}
        </>
      )}

      {accessControls.length > 0
        ? accessControls.map((v, index) => (
            <EnvAccessControlItem
              key={index}
              type={type}
              index={index}
              accessControl={v}
              app={app}
              isDefault={isDefault}
              environmentAlternateId={environmentAlternateId}
              canUpdate={canUpdateApp}
              isInherited={false}
            />
          ))
        : !isAdding && (
            <Card.Section>
              <Box fontSize="sm" color="tertiary">
                No {type} rules directly configured
              </Box>
            </Card.Section>
          )}

      {!isDefault && defaultRules.length > 0 && (
        <Card.Section padding="0" borderWidth="sm 0 0 0" borderColor="subtle">
          {defaultRules.map((v, index) => (
            <EnvAccessControlItem
              key={index}
              type={type}
              index={index}
              accessControl={v}
              app={app}
              isDefault={isDefault}
              environmentAlternateId={environmentAlternateId}
              canUpdate={canUpdateApp}
              isInherited
            />
          ))}
        </Card.Section>
      )}
    </Card>
  );
}
