import { SubjectRawRule } from "@casl/ability";

export type Action = 'manage' | 'create' | 'read' | 'update' | 'delete';

// TODO - This is copied from nucleus - we may want to import nucleus instead if the duplication gets out of hand.
export const EntityNames = {
  BillingAccount: 'BillingAccount',
  Organization: 'Organization',
  Team: 'Team',
  Member: 'Member',
  Project: 'Project',
  Environment: 'Environment',
  ProjectEnvironment: 'ProjectEnvironment',
  Event: 'Event',
  Domain: 'Domain',
  ApiKey: 'ApiKey',
  ApiKeySecret: 'ApiKeySecret',
  OrganizationIntegration: 'OrganizationIntegration',
  LinkCode: 'LinkCode',
  LinkQrCode: 'LinkQrCode',
  Env: 'Env',
  Access: 'Access',
  App: 'App',
  Connection: 'Connection',
} as const;

export const EntityPrefixes = {
  [EntityNames.BillingAccount]: 'bila_',
  [EntityNames.Event]: 'evnt_',
  [EntityNames.Member]: 'memb_',
  [EntityNames.Organization]: 'org_',
  [EntityNames.Team]: 'team_',
  [EntityNames.ApiKey]: 'api_',
  [EntityNames.ApiKeySecret]: 'scrt_',
  [EntityNames.OrganizationIntegration]: 'oint_',
  [EntityNames.LinkCode]: 'code_',
  [EntityNames.LinkQrCode]: `lqr_`,
  [EntityNames.Domain]: 'doma_',
  [EntityNames.Project]: 'proj_',
  [EntityNames.Environment]: 'evr_',
  [EntityNames.ProjectEnvironment]: 'pevr_',
  [EntityNames.Env]: 'env_',
  [EntityNames.App]: 'app_',
  [EntityNames.Connection]: 'conn_',
  [EntityNames.Access]: 'acc_',
} as const satisfies Record<EntityType, string>;
export type EntityType = (typeof EntityNames)[keyof typeof EntityNames];

type Rule = SubjectRawRule<Action, EntityType, Record<string, string>>;

type RequestSchema = {
  id: string;
  causationId: string;
  correlationId: string;
};

type UserSchema = {
  id: string;
  name: string;
  rules: Rule[];
  type: string;
};

type OrganizationSchema = {
  id: string;
  name: string;
};

type MemberSchema = {
  id: string;
  name: string;
  organization: OrganizationSchema;
  rules: Rule[];
};

export type GetExecutionContextResponse = {
  request: RequestSchema;
  user: UserSchema;
  member: MemberSchema;
};