import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { AwsIntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface AwsSetupFormProps {
  organizationId: string;
}

const awsSetupFormSchema = yup.object().shape({
  region: yup.string().required('Required'),
  managementAccountId: yup.string().required('Required'),
});

const awsFormConfig = [
  { id: 'region', type: 'text', label: 'Default Region', initialValue: 'us-east-1' },
  { id: 'managementAccountId', type: 'text', label: 'Management Account Id', initialValue: '' },
];

export const AwsSetupForm = ({ organizationId }: AwsSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={awsSetupFormSchema}
    formConfig={awsFormConfig}
    configMapper={(values: AwsIntegrationConfig) => ({
      region: values.region,
      managementAccountId: values.managementAccountId,
    })}
    integrationType={IntegrationType.aws}
  />
);
