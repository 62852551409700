import { Button } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { AwsSetupForm } from './AwsSetupForm';
import { CodeBlock } from '../../../Codeblock';

interface AwsSetupGuideProps {
  organizationId: string;
}

const AddRole = `aws iam create-role --role-name=hyphen --assume-role-policy-document="{\\"Version\\":\\"2012-10-17\\",\\"Statement\\":[{\\"Effect\\":\\"Allow\\",\\"Principal\\":{\\"AWS\\":[\\"${process.env.REACT_APP_AWS_ACCOUNT_ID}\\"]},\\"Action\\":\\"sts:AssumeRole\\",\\"Condition\\":{}}]}"`;
const AddRolePolicy = `aws iam put-role-policy --role-name=hyphen --policy-name=hyphen --policy-document="{\\"Version\\":\\"2012-10-17\\",\\"Statement\\":[{\\"Sid\\":\\"VisualEditor0\\",\\"Effect\\":\\"Allow\\",\\"Action\\":[\\"sso:ListPermissionSets\\",\\"organizations:ListRoots\\",\\"sso:CreateAccountAssignment\\",\\"identitystore:ListUsers\\",\\"sso:DeleteAccountAssignment\\",\\"organizations:DescribeAccount\\",\\"organizations:CreateAccount\\",\\"identitystore:ListGroupMembershipsForMember\\",\\"sso:ListAccountAssignments\\",\\"organizations:ListAccounts\\",\\"organizations:DescribeOrganization\\",\\"identitystore:DeleteGroupMembership\\",\\"identitystore:CreateGroupMembership\\",\\"identitystore:DescribeGroup\\",\\"organizations:DescribeOrganizationalUnit\\",\\"identitystore:CreateGroup\\",\\"sso:ListInstances\\",\\"organizations:ListParents\\",\\"identitystore:ListGroups\\",\\"organizations:ListOrganizationalUnitsForParent\\",\\"organizations:CreateOrganizationalUnit\\",\\"sso:DescribePermissionSet\\",\\"organizations:MoveAccount\\",\\"organizations:DescribeCreateAccountStatus\\"],\\"Resource\\":\\"*\\"}]}"`;
export const AwsSetupGuide = ({ organizationId }: AwsSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Create or sign into your Amazon Web Service account">
        <p>
          <Button as="a" target="_blank" href="https://console.aws.amazon.com/" iconSuffix="launch-app">
            Sign in to Amazon Web Service console
          </Button>
        </p>
        <p>or</p>
        <p>
          <Button as="a" target="_blank" href="https://aws.amazon.com/free" iconSuffix="launch-app">
            Create a new Amazon Web Service account
          </Button>
        </p>
      </GuideStep>
      <GuideStep title="Create an Organization in your Amazon Web Service account">
        <p>
          <Link
            to="https://docs.aws.amazon.com/organizations/latest/userguide/orgs_manage_create.html"
            target="_blank"
          >
            Create Organization
          </Link>{' '}
          if one does not already exist.
        </p>
      </GuideStep>
      <GuideStep
        title={
          <>
            <p>Add Hyphen role</p>
            <p>
              <Link to="https://docs.aws.amazon.com/cloudshell/latest/userguide/welcome.html" target="_blank">
                Amazon Web Service Cloud Shell Documentation
              </Link>
            </p>
          </>
        }
      >
        <Button as="a" target="_blank" href="https://console.aws.amazon.com/cloudshell/home" iconSuffix="launch-app">
          Open Amazon Web Service Cloud Shell
        </Button>
        <p>Execute the following command:</p>
        <p>
          <CodeBlock>{AddRole}</CodeBlock>
        </p>
        <p>
          You should see in the response that the role <code>hyphen</code> has been created and allows the Hyphen app
          to assume that role.
        </p>
      </GuideStep>
      <GuideStep title="Add Hyphen policy">
        <p>In the Amazon Web Service Cloud Shell, enter the following command.</p>
        <p>
          <CodeBlock>{AddRolePolicy}</CodeBlock>
        </p>
        <p>
          You should see in the response that the policy <code>hyphen</code> has been created and allows the{' '}
          <code>hyphen</code> role to access the necessary resources.
        </p>
      </GuideStep>
      <GuideStep title="Add Admin Permission Set">
        <p>In the Amazon Web Service Console, navigate to IAM Identity Center.</p>
        <p>In the left navigation of the IAM Identity Center, navigate to Permissions Sets.</p>
        <p>
          Click <strong>Create permission set</strong>.
        </p>
        <p>
          Select <strong>Predefined permission set</strong> as the permission set type
        </p>
        <p>
          Select <strong>AdministratorAccess</strong> as the policy
        </p>
        <p>
          Click <strong>next</strong> and confirm the permission set name is <strong>AdministratorAccess</strong>
        </p>
        <p>
          Click <strong>next</strong> and then click <strong>create</strong>
        </p>
      </GuideStep>
      <GuideStep title="Connect Account">
        <AwsSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
