import { Box, Card, Heading } from '@hyphen/hyphen-components';
import { OrganizationBillingAccount } from '../../services/billingAccount';

export const OrgBillingCard = ({org}: { org: OrganizationBillingAccount }) => {
  return (
    <>
      <Heading as="h5" size="sm">{org.name}</Heading>
      <Card padding="xl" >
        <Card.Section gap="xl" display="flex">
          <Box gap={{ base: 'lg', tablet: 'sm' }} direction="row" justifyContent={{ base:'center', tablet:'space-between' }} wrap>
            <OrgBillingItem label="ENV Pulls" value={(org.env?.pull || 0).toLocaleString()} />
            <OrgBillingItem label="ENV Push" value={(org.env?.push || 0).toLocaleString()} />
            <OrgBillingItem label="Links Created" value={(org.link?.created || 0).toLocaleString()} />
            <OrgBillingItem label="Net.Info Requests" value={(org.netInfo?.requests || 0).toLocaleString()} />
            <OrgBillingItem label="Toggle Evaluations" value="0" />
          </Box>
        </Card.Section>
      </Card>
    </>
  )
}

const OrgBillingItem = ({label, value}: { label: string; value: string }) => (
  <Box direction="column" alignItems="flex-start">
    <Box fontSize="2xl" fontWeight="bold">{value}</Box>
    <Box fontSize="sm" color="secondary">{label}</Box>
  </Box>
)
