import { GoogleWorkspaceIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface GoogleWorkspaceDetailsProps {
  integration: GoogleWorkspaceIntegration;
}

export const GoogleWorkspaceDetails = ({ integration }: GoogleWorkspaceDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Domain', value: integration.config.domain },
        { label: 'Admin User Email', value: integration.config.adminUserEmail },
      ]}
      integration={integration}
    />
  );
};

export default GoogleWorkspaceDetails;
