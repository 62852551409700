import { Badge, Box, Button, Card, Heading } from '@hyphen/hyphen-components';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useOrganizationsList } from '../providers/OrganizationsListProvider';
import { Helmet } from 'react-helmet';
import { OrganizationAvatar } from '../components/organization/OrganizationAvatar';
import { routes } from '..';

export const OrganizationList = () => {
  const navigate = useNavigate();
  const { organizations, isLoading: areOrganizationsLoading } = useOrganizationsList();

  useEffect(() => {
    if (!areOrganizationsLoading) {
      if (organizations?.length === 0) {
        navigate(`/${routes.getStarted}`);
      } else if (organizations?.length === 1) {
        navigate(`/${organizations[0].id}`);
      }
    }
  }, [areOrganizationsLoading, navigate, organizations]);
  return (
    <>
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <Box
        margin={{ base: 'lg auto 5xl', desktop: '2xl auto 5xl' }}
        width={{ base: '100', desktop: '90' }}
        gap="xl"
        padding={{ base: '3xl', tablet: '4xl', desktop: '5xl' }}
      >
        <Box childGap="sm">
          <Heading as="h1" size="xl">
            Organizations
          </Heading>
        </Box>
        {areOrganizationsLoading && <Box>Loading...</Box>}
        <Card>
          {!areOrganizationsLoading &&
            organizations?.map((org, i) => (
              <Box
                key={org.id}
                childGap="sm"
                padding="xl"
                direction="row"
                alignItems="center"
                borderColor="default"
                borderWidth={i === 0 ? '0' : 'sm 0 0 0'}
                className="row-item"
              >
                <Box flex="auto" alignItems="center" direction="row" gap="lg">
                  <OrganizationAvatar name={org?.name || 'Unknown Organization'} logoUrl={org.logoUrl} />

                  <Link to={`/${org.id}`} key={org.id}>
                    <Box as="span" display="inline" fontWeight="bold">
                      {org.name}
                    </Box>
                  </Link>
                  <Badge variant="light-grey" message="Owner" size="sm" />
                </Box>
                <Box direction="row" alignItems="center" gap="sm">
                  <Link to={`/${org?.id}/settings`}>
                    <Button variant="secondary" size="sm">
                      Settings
                    </Button>
                  </Link>
                  <Button variant="danger" size="sm">
                    Leave
                  </Button>
                </Box>
              </Box>
            ))}
        </Card>
      </Box>
    </>
  );
};
