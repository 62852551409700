import { Field } from "formik";
import { useCallback, useState, useEffect } from "react";
import { useOrganization } from "../../providers/OrganizationProvider";
import { useLazyGetMembersQuery } from "../../services/member";
import { Organization } from "../../services/organization";
import { Member } from "../../types/teams";
import { FormikSelectInput } from "@hyphen/hyphen-components";


interface SelectApiKeyMemberProps {
  isMulti?: boolean;
  portalTarget?: HTMLFormElement | null;
  label?: string;
  name?: string;
  error?: string | string[];
  helpText?: string;
}

export function createOptionsFromResults(members: Member[]) {
  return (
    members?.filter(m => (m.nickname || '').indexOf('(API Key)') > -1).map((member) => ({
      value: member.id,
      label: member.nickname && member.nickname !== ' ' ? `${member.nickname} (${member.email})` : member.email,
    })) || []
  );
}

// TODO: this should switch to use the api key endpoint when we have https://github.com/Hyphen/apix/issues/765

export function SelectApiKeyMember({
  label = 'API Key',
  name = 'ApiKey',
  helpText,
  isMulti = false,
  error,
  portalTarget
}: SelectApiKeyMemberProps) {
  const { organization = {} as Organization } = useOrganization();
  const [trigger] = useLazyGetMembersQuery();

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!inputValue) return [];

      const filteredMembers = await trigger({ organizationId: organization.id, search: inputValue, includeApiKeys: true }).unwrap();
      return createOptionsFromResults(filteredMembers?.data);
    },
    [organization.id, trigger],
  );

  const [defaultOptions, setDefaultOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      const membersResult = await trigger({ organizationId: organization.id, includeApiKeys: true }).unwrap();
      setDefaultOptions(createOptionsFromResults(membersResult?.data));
    };

    fetchMembers();
  }, [organization.id, trigger]);

  return (
    <Field
      label={label}
      helpText={helpText}
      name={name}
      id={name}
      options={loadOptions}
      component={FormikSelectInput}
      isMulti={isMulti}
      isCreatable={false}
      isAsync
      cacheOptions
      error={error}
      menuPortalTarget={portalTarget}
      defaultOptions={defaultOptions}
    />
  );
}
