import { PropsWithChildren, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useOrganizationsList } from './OrganizationsListProvider';
import { Organization } from '../services/organization';
import React from 'react';

interface IOrganizationContext {
  isLoading: boolean;
  organization: Organization | undefined;
  id: string;
}

const OrganizationContext = React.createContext<IOrganizationContext>({
  isLoading: true,
  organization: undefined,
  id: '',
});

export interface OrganizationProviderProps extends PropsWithChildren {}

export const OrganizationProvider = ({ children }: OrganizationProviderProps) => {
 const orgId = useLocation().state?.backOrgId || localStorage.getItem('orgId');
  const id = useParams<{ id: any }>()?.id || orgId;

  const { organizations, isLoading } = useOrganizationsList();
  const value = useMemo(() => {
    return {
      organization: organizations?.find((org) => org.id === id),
      isLoading,
      id,
    };
  }, [isLoading, id, organizations]);

  //TODO don't return provider until organization is set
  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
};

export const useOrganization = () => {
  return React.useContext(OrganizationContext);
};
