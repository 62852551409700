import { EntityType } from './executionContext';
import { IntegrationType } from './integrations';

export enum ConnectionType {
  DistributionList = 'DistributionList',
  Channel = 'Channel',
  PermissionGroup = 'PermissionGroup',
  Folder = 'Folder',
  Tag = 'Tag',
  CloudWorkspace = 'CloudWorkspace',
  CodeRepository = 'CodeRepository',
  ProjectBoard = 'ProjectBoard',
}

export type Connection = {
  id: string;
  type: ConnectionType;
  entity: {
    id: string;
    type: EntityType;
    name: string;
  };
  organizationIntegration: {
    id: string;
    type: IntegrationType;
  };
  status: 'Ready' | 'Pending' | 'Error';
  errors?: string[];
  config: Record<string, any>;
};

export type NewConnection = {
  type: ConnectionType;
  entity: {
    id: string;
  };
  config: Record<string, any>;
};
