import { NavLink, useLocation } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
} from '@hyphen/hyphen-components';
import MainNavItem from '../../components/MainNavItem';
import { useOrganization } from '../../providers/OrganizationProvider';

import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';

interface NavLinkItem {
  to: string;
  label: string;
  condition?: boolean;
}

export const OrgSettingsNav = () => {
  const { organization } = useOrganization();

  const location = useLocation();

  const ability = useOrganizationAbilityContext();

  // Ensure the component is only rendered on the org settings path
  const basePath = `/${organization?.id}/settings`;
  if (!location.pathname.startsWith(basePath)) {
    return null;
  }

  const links: NavLinkItem[] = [
    { to: `/${organization?.id}/settings`, label: 'Settings' },
    { to: `/${organization?.id}/settings/domains`, label: 'Domains' },
    { to: `/${organization?.id}/settings/environments`, label: 'Environments' },
    {
      to: `/${organization?.id}/settings/api-keys`,
      label: 'API Keys',
      condition: ability.can('read', EntityNames.ApiKey),
    },
    {
      to: `/${organization?.id}/settings/members`,
      label: 'Members',
      condition: ability.can('read', EntityNames.Member),
    },
    { to: `/${organization?.id}/settings/billing`, label: 'Billing' },
  ];

  // Filter visible links based on user permissions
  const visibleLinks = links.filter((link) => link.condition !== false);

  const currentLabel =
    visibleLinks
      .filter((link) => location.pathname.startsWith(link.to)) // Match paths
      .sort((a, b) => b.to.length - a.to.length)[0]?.label || 'Menu'; // Sort by specificity (longest first) // Take the most specific match

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="display-flex flex-direction-row align-items-center g-xs font-weight-semibold background-color-transparent bw-left-sm bw-0 p-v-xs p-h-xl font-size-sm border-color-default font-color-base">
        <span>{currentLabel}</span>
        <Icon name="caret-sm-down" color="secondary" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" side="bottom">
        {visibleLinks.map((link) => (
          <DropdownMenuItem asChild key={link.label}>
            <NavLink key={link.to} className="navlink" end={link.label === 'Settings'} to={link.to}>
              <MainNavItem>{link.label}</MainNavItem>
            </NavLink>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default OrgSettingsNav;
