import { Box, Icon, Popover, useBreakpoint, useOpenClose } from '@hyphen/hyphen-components';
import { Organization } from '../../services/organization';
import { useParams } from 'react-router-dom';
import { useOrganizationsList } from '../../providers/OrganizationsListProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OrganizationAvatar } from './OrganizationAvatar';

interface OrganizationPickerProps {
  handleSelection: (org: Organization | undefined) => void;
}

export const OrganizationPicker = ({ handleSelection }: OrganizationPickerProps) => {
  const { isPhone, isTablet } = useBreakpoint();
  const isMobile = isPhone || isTablet;

  const { id } = useParams<{ id: any }>();
  const { organizations = [], isLoading } = useOrganizationsList();
  const { isOpen, handleClose, handleToggle } = useOpenClose();

  const hasMultipleOrgs = useMemo(() => organizations.length > 1, [organizations]);

  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>();

  useEffect(() => {
    if (!isLoading && organizations.length > 0) {
      let org: Organization | undefined = organizations[0];
      if (id) {
        org = organizations.find((org) => org.id === id);
      }
      handleSelection(org);
      setSelectedOrg(org);
    }
  }, [handleSelection, id, isLoading, organizations]);

  const handleClickOutside = () => {
    handleClose();
  };

  const handleClick = useCallback(
    (org: Organization) => {
      setSelectedOrg(org);
      handleSelection(org);
      handleClose();
    },
    [handleClose, handleSelection],
  );

  const orgPopoverContent = (
    <Box>
      <Box
        display="grid"
        gap={{ base: 'xs', desktop: '0' }}
        padding={{ base: '0 lg lg lg', desktop: 'md' }}
        overflow="auto"
        maxHeight="8xl"
        className="scroll-bar-thin"
      >
        {organizations?.map((org) => (
          <Box
            padding={{ base: 'lg', desktop: 'md' }}
            hover={{ background: 'secondary' }}
            radius={{ base: 'md', desktop: 'xs' }}
            direction="row"
            justifyContent="space-between"
            fontWeight="medium"
            borderColor="default"
            cursor="pointer"
            onClick={() => {
              handleClick(org);
            }}
            key={org.id}
          >
            <Box direction="row" alignItems="center" gap="sm">
              <OrganizationAvatar name={org.name} logoUrl={org.logoUrl} />
              {org.name}
            </Box>
            {org.id === selectedOrg?.id && <Icon name="check" size="sm" />}
          </Box>
        ))}
      </Box>
    </Box>
  );

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="center" gap="sm">
        {hasMultipleOrgs ? (
          <Popover
            content={orgPopoverContent}
            isOpen={isOpen}
            placement="bottom-start"
            offsetFromTarget={4}
            contentContainerProps={{
              padding: '0',
              shadow: 'sm',
              radius: 'sm',
              color: 'base',
              fontSize: 'sm',
              maxWidth: isMobile ? '90' : '8xl',
              width: '100',
            }}
            onClickOutside={handleClickOutside}
            hasArrow={false}
          >
            <Box
              as="button"
              alignSelf="flex-start"
              background="transparent"
              borderWidth="0"
              onClick={handleToggle}
              fontWeight="medium"
              color="base"
              fontSize="md"
              gap="sm"
              direction="row"
              cursor="pointer"
              alignItems="center"
              padding="md"
              radius="sm"
              hover={{
                background: 'tertiary',
              }}
            >
              <OrganizationAvatar name={selectedOrg?.name || 'Unknown Organization'} />
              {selectedOrg?.name}
              <Icon name="caret-sm-down" size="sm" />
            </Box>
          </Popover>
        ) : (
          <Box
            as="button"
            alignSelf="flex-start"
            background="transparent"
            borderWidth="0"
            fontWeight="medium"
            color="base"
            fontSize="md"
            gap="sm"
            direction="row"
            alignItems="center"
            padding="md"
            radius="sm"
          >
            <OrganizationAvatar name={selectedOrg?.name || 'Unknown Organization'} />
            {selectedOrg?.name}
          </Box>
        )}
      </Box>
    </>
  );
};
