import { GithubIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';
import { INTEGRATION_NAME_MAP } from '../../../constants/integrations';

interface GithubDetailsProps {
  integration: GithubIntegration;
}

export const GithubDetails = ({ integration }: GithubDetailsProps) => {
  const ghManagementUrl = `https://github.com/organizations/${integration.config.githubOrgName}/settings/installations/${integration.config.installationId}`;

  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Organization ID', value: integration.config.githubOrgId },
        { label: 'Organization Name', value: integration.config.githubOrgName },
        { label: 'Installation ID', value: integration.config.installationId },
      ]}
      integration={integration}
      manage={{ manageUrl: ghManagementUrl, manageLabel: INTEGRATION_NAME_MAP.github }}
    />
  );
};

export default GithubDetails;
