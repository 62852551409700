import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { UserSidebarProvider } from '../providers/UserSidebarProvider';
import { ViewBillingUsage } from '../components/billingUsage/ViewBillingUsage';

export const BillingUsage = () => {
  return (
    <>
      <Helmet>
        <title>Billing Usage</title>
      </Helmet>
      <UserSidebarProvider>
        <Routes>
          <Route path="/" element={<ViewBillingUsage />} />
        </Routes>
      </UserSidebarProvider>
    </>
  );
}

