import { Box, Table, Spinner } from '@hyphen/hyphen-components';

import { Link } from 'react-router-dom';
import { useGetApiKeysQuery } from '../../services/apiKeys';
import DateTime from '../DateTime';
import EmptyList from '../common/EmptyList';
import { CreateApiKey } from './CreateApiKey';

interface ApiKeySecret {
  id: string;
  lastUsed: string;
  hint: string;
}

interface ApiKeyListProps {
  organizationId: string;
}

export default function ApiKeys({ organizationId }: ApiKeyListProps) {
  const { isLoading, data } = useGetApiKeysQuery(organizationId);

  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'name',
      render: (cell: any, row: any) => <Link to={`/${organizationId}/settings/api-keys/${row.id}`}>{cell}</Link>,
    },
    {
      heading: 'Created',
      dataKey: 'createdAt',
      render: (cell: any) => (
        <Box alignItems="flex-start" fontSize="sm">
          <DateTime value={cell} placement="top" />
        </Box>
      ),
    },
    {
      heading: 'Last Used',
      dataKey: 'secrets',
      render: (cell: any) => {
        const sorted = cell
          .filter((s: ApiKeySecret) => s.lastUsed)
          .sort(function (a: ApiKeySecret, b: ApiKeySecret) {
            return Date.parse(b.lastUsed) - Date.parse(a.lastUsed);
          });

        return sorted.length > 0 ? (
          <Box alignItems="flex-start" fontSize="sm">
            <DateTime value={sorted[0].lastUsed} />
          </Box>
        ) : (
          <Box fontSize="sm">never</Box>
        );
      },
    },
  ];

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <Box gap="4xl">
      <CreateApiKey organizationId={organizationId} />
      {data?.data.length === 0 ? (
        <EmptyList
          title="No API Keys"
          description="Use API keys to authenticate an external service with the Hyphen API"
          iconName="key"
        />
      ) : (
        <Table rowKey="id" columns={columnConfig} rows={data.data} isLoading={isLoading} />
      )}
    </Box>
  );
}
