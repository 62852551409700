import { Box, Button, Card, Icon, toast, useOpenClose } from '@hyphen/hyphen-components';
import { IntegrationInfoBox } from './IntegrationInfoBox';
import { Organization } from '../../../../services/organization';
import { useOrganization } from '../../../../providers/OrganizationProvider';
import { useNavigate } from 'react-router-dom';
import { useDeleteIntegrationMutation } from '../../../../services/integrations';
import { useCallback } from 'react';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { Integration } from '../../../../types/integrations';
import { INTEGRATION_NAME_MAP, INTEGRATION_REMOVAL_DESCRIPTION_MAP } from '../../../../constants/integrations';

interface IntegrationCardProps {
  integration: Integration;
  status: string;
  details: { label: string; value: string }[];
  manage?: {
    manageUrl: string;
    manageLabel: string;
  };
}

const setErrorMessage = (content: string) => ({ default: `Failed to remove ${content} integration.` });

export const IntegrationCard = ({ integration, status, details, manage }: IntegrationCardProps) => {
  const { organization = {} as Organization } = useOrganization();
  const navigate = useNavigate();
  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();
  const [deleteIntegration, { isLoading: isDeleting, error }] = useDeleteIntegrationMutation();

  const deleteHandler = useCallback(async () => {
    const result = await deleteIntegration({ orgId: organization.id, integrationId: integration.id });
    if (!result.error) {
      toast.success(`${INTEGRATION_NAME_MAP[integration.type]} integration removed!`);
      navigate(`/${organization.id}/integrations`);
      closeRemoveModal();
    }
  }, [closeRemoveModal, deleteIntegration, integration.id, integration.type, navigate, organization.id]);

  return (
    <Card>
      <ConfirmModal
        message={`Are you sure you wish to remove the ${INTEGRATION_NAME_MAP[integration.type]} integration?`}
        confirmButtonLabel="Remove Integration"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={deleteHandler}
        isLoading={isDeleting}
        title={`Confirm removal of ${INTEGRATION_NAME_MAP[integration.type]} integration`}
        description={INTEGRATION_REMOVAL_DESCRIPTION_MAP[integration.type]}
        error={error}
        customErrorMessages={setErrorMessage(INTEGRATION_NAME_MAP[integration.type])}
      />
      <Card.Section childGap="xl">
        <Box direction="row" justifyContent="space-between" alignItems="center">
          <Box direction="row" gap="xs" alignItems="center">
            <Icon name="circle-filled" color="success" /> <Box fontSize="sm">{status}</Box>
          </Box>
          <Box direction="row" gap="sm">
            {manage && (
              <Button
                size="sm"
                variant="secondary"
                iconSuffix="launch-app"
                as="a"
                href={manage.manageUrl}
                aria-label="Manage Integration"
              >
                {manage.manageLabel}
              </Button>
            )}
            <Button
              size="sm"
              variant="danger"
              isLoading={isDeleting}
              iconPrefix="c-remove"
              onClick={openRemoveModal}
              aria-label="Remove Integration"
            />
          </Box>
        </Box>
      </Card.Section>
      <Card.Section>
        {details.map((detail, index) => (
          <IntegrationInfoBox key={index} label={detail.label} value={detail.value} />
        ))}
      </Card.Section>
    </Card>
  );
};
