import { Box, Button, Icon, Modal, toast, useOpenClose } from '@hyphen/hyphen-components';
import { Organization, useUploadOrganizationLogoMutation } from '../../../services/organization';
import { ChangeEvent, useCallback, useState } from 'react';

export default function OrganizationAvatarUpload({ organization }: { organization: Organization }) {
  const { name, logoUrl } = organization;
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [file, setFile] = useState<File>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    while (e.target.firstChild) {
      e.target.removeChild(e.target.firstChild);
    }
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleCancel = useCallback(() => {
    setFile(undefined);
    closeModal();
  }, [closeModal]);

  const [uploadOrganizationLogo, { isLoading: isUploadingLogo }] = useUploadOrganizationLogoMutation();

  const handleUpload = useCallback(async () => {
    if (organization && file) {
      try {
        await uploadOrganizationLogo({ id: organization.id, file });
        toast.success('Logo saved');
        setFile(undefined);
        closeModal();
      } catch (e: any) {
        if (e.status === 413 || e.originalStatus === 413) {
          toast.error('File size too large. Please upload files smaller than 1MB.');
        } else {
          toast.error('Upload error');
        }
      }
    }
  }, [closeModal, file, organization, uploadOrganizationLogo]);

  return (
    <Box direction={{ base: 'column', tablet: 'row' }} gap={logoUrl && 'xl'}>
      <Box width={logoUrl ? '128px' : '100'} height="128px" background="secondary" radius="sm" overflow="hidden">
        <Box
          borderWidth="sm"
          borderColor="subtle"
          style={{ borderStyle: 'dashed' }}
          width="100"
          height="100"
          radius="sm"
          alignItems="center"
          justifyContent="center"
          onClick={openModal}
          cursor="pointer"
          color="secondary"
          hover={{ color: 'base', borderColor: 'default' }}
          as="button"
          background="secondary"
          fontSize="sm"
          gap="lg"
        >
          {logoUrl ? (
            <Box width="128px" maxHeight="128px">
              <img src={logoUrl} alt={`logo for the ${name} organization`} />
            </Box>
          ) : (
            <>
              <Icon color="tertiary" name="upload" size="3xl" />
              <Box>Upload a Logo</Box>
            </>
          )}
        </Box>
      </Box>
      {logoUrl && (
        <Button variant="secondary" onClick={openModal} iconPrefix="upload">
          Replace logo
        </Button>
      )}
      <Modal maxWidth="9xl" ariaLabel="Create API Key" isOpen={isModalOpen} onDismiss={handleCancel}>
        <Modal.Body>
          <Box gap="2xl">
            <Box as="p">Upload an organization logo</Box>
            <input type="file" onChange={handleFileChange} accept="image/*" />
            <Box>{file && `${file.name} - ${file.type}`}</Box>
            <Box as="p" fontSize="sm" color="secondary">
              256px x 256px recommended image size
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button isLoading={isUploadingLogo} variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button isLoading={isUploadingLogo} onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
}
