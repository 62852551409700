import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { ConfirmModal } from '../components/common/ConfirmModal';
import { EnvironmentModal, EnvironmentSchema } from './EnvironmentModal';

export type EnvironmentDataType = {
  name: string;
  alternateId: string;
  color: string;
  id?: string;
};

interface EnvironmentActionItemProps {
  defaultEnvironment: EnvironmentDataType;
  canManage: boolean;
  onEdit: (values: EnvironmentSchema, defaultEnvironment: EnvironmentDataType, closeEditModal: () => void) => void;
  editMessage?: string;
  onDelete: (defaultEnvironment: EnvironmentDataType) => void;
  deleteMessage: string;
  error?: any;
  isLoading: boolean;
  showAlternateId?: boolean;
}

export const EnvironmentActionItem = ({
  defaultEnvironment,
  canManage,
  onEdit,
  editMessage,
  onDelete,
  deleteMessage,
  error,
  isLoading,
  showAlternateId = true,
}: EnvironmentActionItemProps) => {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();

  return (
    <>
      <Box className="row-item" borderWidth="sm 0 0 0" borderColor="subtle">
        <Box
          direction="row"
          alignItems="center"
          padding={{ base: '2xl', desktop: '3xl' }}
          gap="2xl"
          justifyContent="space-between"
        >
          <Box gap="xs" flex="auto">
            <Box fontSize="md" fontWeight="bold">
              {defaultEnvironment.name}
            </Box>
            <Box fontSize="xs" fontFamily="monospace" color="secondary">
              {defaultEnvironment.alternateId}
            </Box>
          </Box>
          <Box width="120px" direction="row" gap="sm" alignItems="center" color="secondary">
            <input type="color" id={`${defaultEnvironment.name}-color`} value={defaultEnvironment.color} disabled />
            <Box fontSize="xs" fontFamily="monospace">
              {defaultEnvironment.color}
            </Box>
          </Box>
          {canManage && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="open menu" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" side="bottom">
                <DropdownMenuGroup>
                  <DropdownMenuItem onSelect={openEditModal}>
                    <Icon name="pencil" color="tertiary" /> <span>Edit</span>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                    <Icon name="c-remove" /> <span>Delete</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </Box>
      </Box>
      <ConfirmModal
        confirmButtonLabel="Delete"
        title={`Delete ${defaultEnvironment.name}`}
        message={deleteMessage}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={() => onDelete(defaultEnvironment)}
        isLoading={isLoading}
        error={error}
      />
      <EnvironmentModal
        title={`Update ${defaultEnvironment.name}`}
        message={editMessage}
        onSubmit={(values) => onEdit(values, defaultEnvironment, closeEditModal)}
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        data={defaultEnvironment}
        error={error}
        showAlternateId={showAlternateId}
      />
    </>
  );
};
