import { api } from './api';
import { EventsResponse, GetEventsBody } from '../types/events';
import { ListResponse } from './types';

export const eventsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<ListResponse<EventsResponse, 'Cursor'>, GetEventsBody>({
      query: ({ orgId, body }) => ({ url: `api/events/${orgId}/search`, method: 'POST', body }),
      providesTags: (result) => (result ? [{ type: 'Events', id: 'LIST' }] : []),
    }),
  }),
});

export const { useGetEventsQuery } = eventsApi;
