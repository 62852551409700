import { toast, useOpenClose } from '@hyphen/hyphen-components';
import { ConfirmModal } from '../common/ConfirmModal';
import { useCallback } from 'react';
import { useDeleteAppMutation } from '../../services/apps';
import { useNavigate } from 'react-router-dom';
import { DeleteItemCard } from '../common/DeleteItemCard';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';

export default function DeleteApp({ app }: { app: any }) {
  const [deleteApp, { isLoading: isDeleting, error }] = useDeleteAppMutation();
  const ability = useOrganizationAbilityContext();
  const canDeleteApp = ability.can('delete', app);

  const navigate = useNavigate();

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteApp({ appId: app.alternateId, organizationId: app.organization.id });
    if (!error) {
      toast.success('App deleted');
      closeDeleteModal();
      navigate(`/${app.organization.id}/${app.project.id}`, { replace: true });
    }
  }, [deleteApp, app.alternateId, app.organization.id, app.project.id, closeDeleteModal, navigate]);

  if (canDeleteApp)
    return (
      <>
        <DeleteItemCard itemLabel="App" onDelete={openDeleteModal} />
        <ConfirmModal
          message={`Are you sure you want to delete ${app.name}?`}
          confirmButtonLabel="Delete"
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDelete}
          isLoading={isDeleting}
          error={error}
          customErrorMessages={{ default: 'Delete app failed' }}
        />
      </>
    );

  return null;
}
