import { GetExecutionContextResponse } from "../types/executionContext";
import { api } from "./api";

export const executionContextApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExecutionContext: build.query<GetExecutionContextResponse, string>({
      query: (organizationId: string) => ({ url: `api/execution-context?organizationId=${organizationId}` }),
      providesTags: (result, _error, organizationId) => (result ? [{ type: 'OrganizationExecutionContext', id: organizationId }] : []),
    })
  })
});

export const {
  useGetExecutionContextQuery,
} = executionContextApi;