import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { Button } from '@hyphen/hyphen-components';

interface GithubSetupGuideProps {
  organizationId: string;
}

export const GithubSetupGuide = ({ organizationId }: GithubSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Add Hyphen to your Github organization">
        <Button
          as="a"
          target="_blank"
          // TODO: Update with production link when we have it
          href="https://github.com/apps/hyphen-development"
          variant="primary"
          iconSuffix="launch-app"
        >
          Install the App
        </Button>
      </GuideStep>
    </Guide>
  );
};
