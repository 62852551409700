import { toast, useOpenClose } from '@hyphen/hyphen-components';
import { ConfirmModal } from '../common/ConfirmModal';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project, useDeleteProjectMutation } from '../../services/projects';
import { DeleteItemCard } from '../common/DeleteItemCard';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';

export default function DeleteProject({ project }: { project: Project }) {
  const [deleteProject, { isLoading: isDeleting, error }] = useDeleteProjectMutation();
  const ability = useOrganizationAbilityContext();
  const canDeleteProject = ability.can('delete', project);

  const navigate = useNavigate();

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteProject({
      projectId: project.alternateId,
      organizationId: project.organization.id,
    });
    if (!error) {
      toast.success('Project deleted');
      closeDeleteModal();
      navigate(`/${project.organization.id}`, { replace: true });
    }
  }, [deleteProject, project.alternateId, project.organization.id, closeDeleteModal, navigate]);

  if (canDeleteProject)
    return (
      <>
        <DeleteItemCard itemLabel="Project" onDelete={openDeleteModal} />
        <ConfirmModal
          message={
            <>
              {(project.apps?.length ?? 0) > 0 && (
                <p>
                  <strong>{project.apps?.length} apps within this project will be deleted.</strong>
                </p>
              )}
              <p>Are you sure you want to delete {project.name}?</p>
            </>
          }
          confirmButtonLabel="Delete"
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDelete}
          isLoading={isDeleting}
          error={error}
          customErrorMessages={{ default: 'Delete failed' }}
        />
      </>
    );

  return null;
}
