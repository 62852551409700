import { Box, Button, FormikSelectInputInset, FormikTextInputInset, Icon } from '@hyphen/hyphen-components';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { InferType } from 'yup';
import * as yup from 'yup';
import { useAuth } from '../auth/authProvider';
import { ApiError } from '../ApiError';
import PageHeader from '../PageHeader';
import { stateList } from '../../utils/stateList';
import { phoneRegExp } from '../../utils/validations';

const createBillingAccountSchema = yup.object().shape({
  name: yup.string().required('Company name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').optional(),
  address: yup.object().shape({
    streetAddress: yup.string().required('Street Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zipCode: yup.string().required('Zip Code is required'),
  }),
});
export type CreateBillingAccountFormSchema = InferType<typeof createBillingAccountSchema>;

interface CreateBillingAccountFormProps {
  handleSubmit: (
    values: CreateBillingAccountFormSchema,
    options: FormikHelpers<CreateBillingAccountFormSchema>,
  ) => void;
  error?: any;
}

export const CreateBillingAccountForm = ({ handleSubmit, error }: CreateBillingAccountFormProps) => {
  const { user } = useAuth();

  return (
    <Box width="100" height="100" gap="4xl" alignItems="center" justifyContent="center">
      <Box width="100" maxWidth="9xl" padding={{ base: '4xl', desktop: '5xl' }} gap="3xl">
        <PageHeader title="Set up your organization" textAlign="center" />
        <Formik
          initialValues={{
            name: '',
            alias: '',
            email: user?.email || '',
            address: { streetAddress: '', city: '', state: '', zipCode: '' },
          }}
          validationSchema={createBillingAccountSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Box gap="xl">
                <Field
                  type="text"
                  label="Organization Name"
                  name="name"
                  id="name"
                  component={FormikTextInputInset}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
                <Field
                  type="text"
                  inputMask="phone"
                  label="Billing Email"
                  name="email"
                  id="email"
                  component={FormikTextInputInset}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
                <Field
                  type="phone"
                  label="Billing Phone"
                  name="phone"
                  id="phone"
                  component={FormikTextInputInset}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
                <Field
                  type="text"
                  label="Street Address"
                  name="address.streetAddress"
                  id="address.streetAddress"
                  component={FormikTextInputInset}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
                <Field
                  type="text"
                  label="City"
                  name="address.city"
                  id="address.city"
                  component={FormikTextInputInset}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
                <Box display="flex" direction="row" gap="xl">
                  <Field
                    label="State"
                    name="address.state"
                    id="address.state"
                    options={stateList}
                    component={FormikSelectInputInset}
                    isDisabled={isSubmitting}
                  />
                  <Field
                    type="text"
                    label="Zip Code"
                    name="address.zipCode"
                    id="address.zipCode"
                    component={FormikTextInputInset}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                  />
                </Box>
                {error ? <ApiError error={error} title="Error creating organization" /> : null}
                <Box margin="lg 0">
                  <Button fullWidth size="lg" variant="primary" type="submit" isLoading={isSubmitting}>
                    Continue
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Box direction="row" gap="sm" margin="4xl 0 0 0" alignSelf="center">
          <Icon name="circle-filled" color="base" size="2xs" aria-label="step 1 of 2" />
          <Icon name="circle-filled" color="disabled" size="2xs" aria-label="step 2 of 2" />
        </Box>
      </Box>
    </Box>
  );
};
