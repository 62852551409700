import { Box, Button, Icon } from '@hyphen/hyphen-components';
import PageHeader from '../PageHeader';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useCallback } from 'react';

export const CreatePaymentMethodForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.origin,
        },
      });
    },
    [stripe, elements],
  );
  return (
    <Box width="100" height="100" gap="4xl" alignItems="center" justifyContent="center">
      <Box width="100" maxWidth="9xl" padding={{ base: '4xl', desktop: '5xl' }} gap="3xl">
        <PageHeader title="Add a payment method" textAlign="center" />
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <Box margin="lg 0">
            <Button fullWidth size="lg" variant="primary" type="submit">
              Complete Setup
            </Button>
          </Box>
        </form>
        <Box direction="row" gap="sm" margin="4xl 0 0 0" alignSelf="center">
          <Icon name="circle-filled" color="disabled" size="2xs" aria-label="step 1 of 2" />
          <Icon name="circle-filled" color="base" size="2xs" aria-label="step 2 of 2" />
        </Box>
      </Box>
    </Box>
  );
};
