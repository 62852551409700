import { TeamDetails } from '../../types/teams';
import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Organization } from '../../services/organization';

interface TeamConnectionsProps {
  team: TeamDetails;
  organization: Organization;
}

export default function TeamConnections({ team, organization }: TeamConnectionsProps) {
  return (
    <ConnectionsList
      organizationId={organization.id}
      filter={{
        entity: {
          type: 'Team',
          id: team.id,
          name: team.name,
        },
        entityType: 'Team',
      }}
    ></ConnectionsList>
  );
}
