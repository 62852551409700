import { Badge, Box, Button, Heading, Spinner, Table, toast } from '@hyphen/hyphen-components';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetDnsConfigQuery, useVerifySslMutation } from '../../services/zelda/domains';
import { Domain } from '../../types/zelda';
import { Guide } from '../guide/Guide';
import { GuideStep } from '../guide/GuideStep';
import { ApiError } from '../ApiError';

interface DomainVerificationProps {
  domain: Domain;
  isBeingVerified?: boolean;
  setStep?: (step: string) => void;
}

const columnConfig = [
  {
    heading: 'type',
    dataKey: 'type',
  },
  {
    heading: 'hostname',
    dataKey: 'hostname',
  },
  {
    heading: 'points',
    dataKey: 'points',
  },
];

const errorMessage = {
  default: 'Failed to verify domain. Please try again later.'
}

export const DomainVerification = ({domain, isBeingVerified, setStep }: DomainVerificationProps) => {
  const { organization } = useOrganization();
  const organizationId = organization?.id;
  const {data: dns, isLoading: isDnsLoading } = useGetDnsConfigQuery(organizationId);
  const [verifySsl, { error }] = useVerifySslMutation();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const domainTableData = dns?.ipAddress?.map((ip: string, index: number) => {
    return {
      type: 'A',
      hostname: 'replace with your domain',
      points: ip,
      id: index.toString()
    }
  })

  const subDomainTableData = dns?.hosts?.map((host: string, index: number) => {
    return {
      type: 'CNAME',
      hostname: 'replace with your domain',
      points: host,
      id: index.toString()
    }
  })

  const handleDomainVerification = useCallback(async () => {
    setIsLoading(true);
    const { data } = await verifySsl({ domainId: domain?.id, organizationId: organization?.id }) || {};
    if(data?.verified) {
      toast.success('Domain verified successfully');
      navigate(`/${organization?.id}/settings/domains`)
    }
    setIsLoading(false);
  }, [domain.id, verifySsl, navigate, organization?.id])

  return (
    <>
      <Box as="p">
        Confirm you own the domain by setting up a DNS record with the following details with your domain name
        registrant.
      </Box>

      <Guide title="DNS Setup Guide">
        <GuideStep title="Sign in to your DNS">
          <Box as="span">Sign in to your DNS provider and navigate to the DNS entries for the domain you will be adding to Hyphen.</Box>
        </GuideStep>
        <GuideStep title="Add DNS records">
          <Box as="span">Use one of the following options to add the required DNS entries to verify your domain. Once your domain is verified the DNS entries must remain as long as you use the domain with Hyphen.</Box>
          <Box gap="4xl">
            <Box gap="sm">
              <Heading as="h4" size="md">
                Option 1 <Badge variant="blue" message="Recommended" />
              </Heading>
              <Box as="p">Add the following CNAME record. The hostname should be the domain you will be using with Hyphen.
                Use option 2 if you are trying to use a top level domain and your DNS provider doesn’t support CNAME
                records on a top level domain.</Box>
              <Box fontSize="sm">
                {isDnsLoading && (
                  <Box direction="row" alignItems="center" justifyContent="center">
                    <Spinner size="md" />
                  </Box>
                )}
                {subDomainTableData?.length && (
                  <Table rowKey="id" columns={columnConfig} rows={subDomainTableData} className="domain-verification-table" />
                )}
              </Box>
            </Box>
            <Box gap="sm">
              <Heading as="h4" size="md">
                Option 2
              </Heading>
              <Box as="p">Add the following A record. The hostname should be the domain you will be using with
                Hyphen.</Box>
              <Box fontSize="sm">
                {isDnsLoading && (
                  <Box direction="row" alignItems="center" justifyContent="center">
                    <Spinner size="md" />
                  </Box>
                )}
                {domainTableData?.length && <Table rowKey="id" columns={columnConfig} rows={domainTableData} className="domain-verification-table" />}
              </Box>
            </Box>
          </Box>
        </GuideStep>
        <GuideStep title="Verify your domain">
          <Box direction="row" justifyContent="space-between" gap="sm">
            {!isBeingVerified && setStep ? (
              <Button variant="secondary" fullWidth onClick={() => setStep('1')} isDisabled={isLoading}>
                Previous
              </Button>
            ) : null}
            <Button variant="primary" fullWidth={!isBeingVerified} onClick={handleDomainVerification} isLoading={isLoading}>
              Verify Domain
            </Button>
          </Box>
          {error ? <ApiError error={error} customMessages={errorMessage} /> : null}
        </GuideStep>
      </Guide>
    </>
  );
};