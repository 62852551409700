import { Field, FieldArray, FieldAttributes, useField, useFormikContext } from 'formik';
import {
  Box,
  Button,
  FormikRadioGroup,
  FormikSelectInputNative,
  FormikTextInput,
  Popover,
  useOpenClose,
} from '@hyphen/hyphen-components';
import {
  criteriaOptions,
  dynamicTeamOperatorOptions,
  propertyFieldOptions,
  propertyOperatorOptions,
  CreateTeamFormSchema,
} from '../../schema/teams';
import { DynamicTeamCriteriaCollection, DynamicTeamCriteriaProperty } from '../../types/teams';
import { Fragment, useEffect } from 'react';

type FormValue = CreateTeamFormSchema | Omit<CreateTeamFormSchema, 'teamType'>;

export const DynamicTeamsForm = ({ values, isSubmitting }: { values: FormValue; isSubmitting: boolean }) => {
  const selectedProperty = (label: string) => {
    const selectedValue = propertyFieldOptions.find((option) => option.value === label);
    return selectedValue?.label;
  };

  const { isOpen: isPopoverOpen, handleToggle: togglePopover, handleClose: closePopover } = useOpenClose();

  return (
    <>
      <FieldArray
        name="criteria"
        render={(arrayHelpers) => (
          <>
            {values.criteria?.map((_: any, index: number) => (
              <Fragment key={index}>
                {index > 0 && (
                  <Box fontSize="sm" fontFamily="monospace" color="secondary">
                    AND
                  </Box>
                )}
                <Box direction="row" gap="sm" alignItems="center">
                  <Box
                    flex="auto"
                    direction="row"
                    gap="lg"
                    background="secondary"
                    alignItems="flex-start"
                    padding="xl"
                    borderWidth="sm"
                    borderColor="subtle"
                    radius="md"
                  >
                    {values.criteria![index].type === 'property' ? (
                      <Box direction={{ base: 'column', tablet: 'row' }} gap="xl" width="100">
                        <Box minWidth="6xl" width={{ base: '100', tablet: '6xl' }}>
                          <Field
                            label="Field"
                            name={`criteria.${index}.field`}
                            id={`criteria.${index}.field`}
                            options={propertyFieldOptions}
                            component={FormikSelectInputNative}
                            isDisabled={isSubmitting}
                            hideLabel
                          />
                        </Box>
                        <Box width={{ base: '100', tablet: '6xl' }} minWidth="6xl">
                          <Field
                            label="Operator"
                            name={`criteria.${index}.operator`}
                            id={`criteria.${index}.operator`}
                            options={propertyOperatorOptions}
                            component={FormikSelectInputNative}
                            isDisabled={isSubmitting}
                            hideLabel
                          />
                        </Box>
                        <Box flex="auto">
                          <Field
                            label={selectedProperty((values.criteria![index] as DynamicTeamCriteriaProperty).field)}
                            hideLabel
                            name={`criteria.${index}.value`}
                            id={`criteria.${index}.value`}
                            component={FormikTextInput}
                            isDisabled={isSubmitting}
                            autoComplete="off"
                            placeholder={
                              (values.criteria![index] as DynamicTeamCriteriaProperty).operator === 'equals'
                                ? 'enter a value'
                                : 'enter a regex expression'
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box direction="row" gap="xl" width="100">
                        <Box
                          direction={{ base: 'column', tablet: 'row' }}
                          gap="xl"
                          width="100"
                          alignItems="flex-start"
                        >
                          <Box
                            display="block"
                            fontSize="sm"
                            padding={{ tablet: 'lg 0 0 0' }}
                            minWidth="6xl"
                            width="6xl"
                            fontWeight="semibold"
                          >
                            <span style={{ textTransform: 'capitalize' }}>
                              {(values.criteria![index] as DynamicTeamCriteriaCollection).type}
                            </span>
                          </Box>
                          <Box width="6xl" minWidth="6xl" padding={{ tablet: 'lg 0 0 0' }}>
                            <Field
                              label="Conjunction"
                              name={`criteria.${index}.conjunction`}
                              id={`criteria.${index}.conjunction`}
                              options={dynamicTeamOperatorOptions(`criteria.${index}.conjunction`)}
                              component={FormikRadioGroup}
                            />
                          </Box>
                          <FieldArray
                            name={`criteria.${index}.values`}
                            render={(arrayHelpers) => (
                              <Box flex="auto" gap="sm">
                                <Box flex="auto" gap="sm">
                                  {((values.criteria![index] as DynamicTeamCriteriaCollection)?.values).map(
                                    (_: any, i: number) => (
                                      <Box direction="row" gap="sm" key={`criteria.${i}`} alignItems="center">
                                        {i > 0 && (
                                          <Box fontSize="sm" fontFamily="monospace" width="32px">
                                            {values.criteria![index].conjunction === 'and' ? 'AND' : 'OR'}
                                          </Box>
                                        )}
                                        <Field
                                          label="Value"
                                          hideLabel
                                          placeholder="enter a value"
                                          autoComplete="off"
                                          name={`criteria.${index}.values[${i}]`}
                                          component={FormikTextInput}
                                          isDisabled={isSubmitting}
                                        />
                                        {i > 0 && (
                                          <Button
                                            variant="tertiary"
                                            size="md"
                                            onClick={() => arrayHelpers.remove(index)}
                                            iconPrefix="c-remove"
                                            aria-label="remove value"
                                          />
                                        )}
                                      </Box>
                                    ),
                                  )}
                                </Box>

                                <Box display="block">
                                  <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push('')}>
                                    add value
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {(values.criteria ?? []).length > 1 && (
                    <Button
                      variant="tertiary"
                      size="md"
                      onClick={() => arrayHelpers.remove(index)}
                      iconPrefix="c-remove"
                      aria-label="remove criteria"
                    />
                  )}
                </Box>
              </Fragment>
            ))}

            <Box direction="row">
              <Popover
                content={
                  <>
                    {criteriaOptions.map((option) => {
                      if (
                        values.criteria?.some(
                          (criteria) =>
                            criteria.type === option.value && (criteria.type === 'role' || criteria.type === 'team'),
                        )
                      ) {
                        return null;
                      }

                      const criteriaFields =
                        option.value === 'property'
                          ? { type: option.value, field: 'firstName', operator: 'equals', value: '' }
                          : { type: option.value, conjunction: 'and', values: [''] };
                      return (
                        <Box
                          key={option.value}
                          as="button"
                          type="button"
                          textAlign="left"
                          padding="sm"
                          borderWidth="0"
                          background="transparent"
                          color="base"
                          radius="sm"
                          onClick={() => {
                            arrayHelpers.push({ ...criteriaFields });
                            closePopover();
                          }}
                          cursor="pointer"
                          hover={{
                            background: 'secondary',
                          }}
                        >
                          {option.label}
                        </Box>
                      );
                    })}
                  </>
                }
                isOpen={isPopoverOpen}
                placement="bottom-start"
                hasArrow={false}
                offsetFromTarget={3}
                contentContainerProps={{
                  padding: 'sm',
                  gap: 'xs',
                  borderWidth: 'sm',
                  borderColor: 'default',
                  fontSize: 'sm',
                  width: '6xl',
                }}
                onClickOutside={closePopover}
              >
                <Button
                  variant="secondary"
                  size="sm"
                  iconSuffix="caret-down"
                  onClick={togglePopover}
                  aria-label="open menu"
                >
                  add criteria
                </Button>
              </Popover>
            </Box>
          </>
        )}
      />
    </>
  );
};

interface FormatFormikFieldProps extends FieldAttributes<any> {
  targetField: string;
  generateValue: (values: string) => string[];
  name: string;
}

export const FormatFormikField = ({ targetField, generateValue, ...props }: FormatFormikFieldProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [field] = useField(props);

  useEffect(() => {
    if (field.value.length > 2) {
      console.log(generateValue(field.value.trim()));
      // setFieldValue(targetField, generateValue(field.value.trim()));
    }
  }, [field.value, generateValue, setFieldValue, targetField, values]);

  return <Field {...props} {...field} />;
};
