import { Box, Button, Card, Heading } from '@hyphen/hyphen-components';

export const DeleteItemCard = ({
  itemLabel = 'item',
  description = 'Once deleted, it will be gone forever. Please be certain.',
  onDelete,
}: {
  itemLabel: string;
  description?: string;
  onDelete: () => void;
}) => (
  <Card borderColor="danger" style={{ flexShrink: '0' }}>
    <Card.Section>
      <Box direction="row" gap="2xl" justifyContent="space-between" alignItems="center">
        <Box gap="2xs">
          <Heading as="h4" size="sm">
            {`Delete ${itemLabel}`}
          </Heading>
          <Box color="secondary" fontSize="sm">
            {description}
          </Box>
        </Box>
        <Button variant="danger" onClick={onDelete}>
          Delete
        </Button>
      </Box>
    </Card.Section>
  </Card>
);
