import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { Link } from 'react-router-dom';
import { CreateProjectForm } from '../../../components/projects/CreateProjectForm';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Organization } from '../../../services/organization';
import { Spinner } from '@hyphen/hyphen-components';

export const CreateProject = () => {
  const { organization = {} as Organization, isLoading } = useOrganization();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>Create Project</title>
      </Helmet>
      <PageHeader eyebrow={<Link to={`/${organization?.id}`}>Projects</Link>} title="Create Project" />
      <CreateProjectForm organizationId={organization.id} />
    </ScrollableMainLayout>
  );
};

export default CreateProject;
