import { CreateMemberBody, GetMembersBody } from '../types/members';
import { Member } from '../types/teams';
import { api } from './api';
import { ListResponse } from './types';

export const MembersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMembers: builder.query<ListResponse<Member>, GetMembersBody>({
      query: ({ organizationId, pageSize = 50, pageNum = 1, search, includeApiKeys = false }) => ({
        url: `api/organizations/${organizationId}/members?pageNum=${pageNum}&pageSize=${pageSize}&includeApiKeys=${includeApiKeys}${
          search ? `&search=${search}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'Members' as const, id: id })), { type: 'Members', id: 'LIST' }]
          : [],
    }),
    getMember: builder.query<Member, { memberId: string; organizationId: string }>({
      query: ({ memberId, organizationId }) => ({
        url: `api/organizations/${organizationId}/members/${memberId}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Members', id: result.id }] : []),
    }),
    createMember: builder.mutation<Member, CreateMemberBody>({
      query: (member) => ({
        url: `api/organizations/${member.organizationId}/members`,
        method: 'POST',
        body: {
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
        },
      }),
      invalidatesTags: [{ type: 'Members', id: 'LIST' }],
    }),
    updateMember: builder.mutation<
      Member,
      { memberId: string; organizationId: string; data: Omit<CreateMemberBody, 'organizationId'> }
    >({
      query: ({ memberId, organizationId, data }) => ({
        url: `api/organizations/${organizationId}/members/${memberId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, { memberId }) => [{ type: 'Members', id: memberId }],
    }),
    deleteMember: builder.mutation<void, { memberId: string; organizationId: string }>({
      query: ({ memberId, organizationId }) => ({
        url: `api/organizations/${organizationId}/members/${memberId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { memberId }) => [
        { type: 'Members', id: memberId },
        { type: 'Members', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useCreateMemberMutation,
  useDeleteMemberMutation,
  useGetMemberQuery,
  useGetMembersQuery,
  useLazyGetMembersQuery,
  useUpdateMemberMutation,
} = MembersApi;
