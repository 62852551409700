import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CheckboxInput,
  DateInput,
  DateInputProps,
  Popover,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { EventType, GetEventsBody } from '../../types/events';

export interface ValuesFormProps {
  startDate: Date | null;
  endDate: Date | null;
  types?: string[];
}
interface EventsFormProps {
  isLoadingEvents: boolean;
  handleFilter: (values: ValuesFormProps) => void;
  handleRefresh: () => void;
  filters?: GetEventsBody['body'];
}

const filterEventSchema = yup.object().shape({
  startDate: yup.date().nullable(),
  endDate: yup
    .date()
    .nullable()
    .test('is-endDate-valid', 'End date must be later than start date', function (value) {
      const { startDate } = this.parent;
      if (startDate && value) {
        const startOfDay: Date = new Date(startDate);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(value);
        endOfDay.setHours(23, 59, 59, 999);

        return startOfDay <= endOfDay;
      }
      return true;
    }),
});

export const EventsFilter = ({ handleRefresh, isLoadingEvents, handleFilter, filters }: EventsFormProps) => {
  const formInputs: { name: 'startDate' | 'endDate'; placeholder: string }[] = [
    {
      name: 'startDate',
      placeholder: 'Start Date',
    },
    {
      name: 'endDate',
      placeholder: 'End Date',
    },
  ];

  const { isOpen: isTypeFilterOpen, handleClose: closeTypeFilter, handleOpen: openTypeFilter } = useOpenClose();
  const [types, setTypes] = useState(handleTransformTypes());

  const initialValues = {
    startDate: filters?.startDate ? new Date(filters.startDate) : null,
    endDate: filters?.endDate ? new Date(filters.endDate) : null,
  };

  useEffect(() => {
    if (filters?.types) {
      setTypes((prevTypes) =>
        prevTypes.map((type) => {
          if (filters.types?.includes(type.value)) {
            return { ...type, isChecked: true };
          }
          return type;
        }),
      );
    }
  }, [filters?.types]);

  const handleOnChangeTypes = (typeChanged: { value: string; isChecked: boolean }) => {
    const updatedEventsData = types.map((type) => {
      if (type.value === typeChanged.value) {
        const isSelected = typeChanged.isChecked;
        return { ...type, isChecked: !isSelected };
      }
      return type;
    });
    setTypes(updatedEventsData);
  };

  const handleSubmit = (values: ValuesFormProps) => {
    const flatSelectedTypes = types.filter((item) => item.isChecked).map((item) => item.value);

    const filters = {
      ...values,
      types: flatSelectedTypes,
    };
    handleFilter(filters);
  };

  const typeFilterContent = (submitForm: () => Promise<void>) => (
    <Box>
      {types.length > 0 && (
        <Box
          padding="md"
          gap="sm"
          overflow="auto"
          background="secondary"
          className="scroll-bar-thin"
          maxHeight="7xl"
        >
          {types.map((type) => (
            <CheckboxInput
              id={`type-${type.value}`}
              label={type.value}
              value={type.value}
              key={type.value}
              size="sm"
              isChecked={type.isChecked}
              onChange={() => {
                handleOnChangeTypes(type);
              }}
            />
          ))}
        </Box>
      )}
      <Box direction="row" justifyContent="space-between" borderWidth="sm 0 0 0" borderColor="default" padding="md">
        <Button
          variant="tertiary"
          size="sm"
          onClick={() => {
            setTypes(handleTransformTypes());
            submitForm();
          }}
        >
          Clear
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            closeTypeFilter();
            submitForm();
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box direction="row" gap="xl" justifyContent="space-between">
      <Formik
        initialValues={initialValues}
        validationSchema={filterEventSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, submitForm }) => (
          <Form noValidate>
            <Box direction="row" gap="md">
              <Popover
                content={typeFilterContent(submitForm)}
                isOpen={isTypeFilterOpen}
                placement="bottom-start"
                offsetFromTarget={4}
                onClickOutside={closeTypeFilter}
                hasArrow={false}
                contentContainerProps={{
                  padding: '0',
                  gap: 'xs',
                  borderWidth: 'sm',
                  borderColor: 'default',
                  width: '100',
                  maxWidth: '8xl',
                  overflow: 'hidden',
                }}
              >
                <Button
                  variant="secondary"
                  size="sm"
                  iconSuffix="caret-sm-down"
                  onClick={!isTypeFilterOpen ? openTypeFilter : closeTypeFilter}
                >
                  Types
                  {types.filter((type) => type.isChecked).length > 0 && (
                    <> ({types.filter((type) => type.isChecked).length})</>
                  )}
                </Button>
              </Popover>
              {formInputs.map(({ name, placeholder }) => (
                <Box width="7xl" key={name}>
                  <DateInput
                    datePickerProps={{
                      selected: values[name],
                      onChange: async (date: DateInputProps['onChange']) => {
                        await setFieldValue(name, date);
                        await submitForm();
                      },
                    }}
                    textInputProps={{
                      placeholder: placeholder,
                      id: name,
                      name: name,
                      label: '',
                      onClear: async () => {
                        await setFieldValue(name, null);
                        await submitForm();
                      },
                      error: errors[name],
                    }}
                    size="sm"
                    key={name}
                  />
                </Box>
              ))}
            </Box>
          </Form>
        )}
      </Formik>
      <Button
        size="sm"
        variant="secondary"
        iconPrefix="refresh"
        aria-label="Refresh access log"
        onClick={handleRefresh}
        isDisabled={isLoadingEvents}
      />
    </Box>
  );
};

const handleTransformTypes = () => {
  return Object.values(EventType).map((type) => ({ value: type, isChecked: false }));
};
