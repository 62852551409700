import { Assignment, Entity } from '../types/access';
import { Role } from '../types/roles';
import { api } from './api';

export const accessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    giveAccess: builder.mutation<
      void,
      { entity: Entity; assignment: Assignment; organizationId: string; roles: Role[] }
    >({
      query: ({ entity, organizationId, assignment, roles }) => ({
        url: `api/organizations/${organizationId}/access`,
        method: 'POST',
        body: {
          entity: entity,
          roles: roles,
          assignment: assignment,
        },
      }),
      invalidatesTags: (_result, _error, { entity }) => [{ type: `${entity.type}Access`, id: `${entity.id}-LIST` }],
    }),
    revokeAccess: builder.mutation<void, { organizationId: string; accessId: string; entity: Entity }>({
      query: ({ accessId, organizationId }) => ({
        url: `api/organizations/${organizationId}/access/${accessId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { accessId, entity }) => [
        { type: `${entity.type}Access`, id: accessId },
        { type: `${entity.type}Access`, id: `${entity.id}-LIST` },
      ],
    }),
  }),
});

export const { useGiveAccessMutation, useRevokeAccessMutation } = accessApi;
