import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { CreatePaymentMethodForm } from './CreatePaymentMethodForm';
import { Appearance } from '@stripe/stripe-js';
// @ts-ignore - Design tokens
import uiTokens from '@hyphen/hyphen-design-tokens/build/json/variables';
import { useTheme } from '@hyphen/hyphen-components';

export interface CreatePaymentMethodProps {
  clientSecret: string;
}

export function CreatePaymentMethod({ clientSecret }: CreatePaymentMethodProps) {
  const { isDarkMode } = useTheme();
  const stripeKey = process.env.REACT_APP_STRIPE_KEY || '';
  const stripePromise = loadStripe(stripeKey);

  const colorScheme = isDarkMode ? 'darkValue' : 'value';
  const borderColor = uiTokens.color.border.default[colorScheme];
  const fontColor = uiTokens.color.font.base[colorScheme];
  const backgroundColor = uiTokens.color.background.primary[colorScheme];
  const dangerColor = uiTokens.color.font.danger[colorScheme];

  const appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      borderRadius: uiTokens.size['border-radius'].sm.value,
      colorDanger: dangerColor,
      colorBackground: backgroundColor,
      colorText: fontColor,
      colorPrimary: fontColor,
    },
    rules: {
      '.Tab': {
        border: `1px solid ${borderColor}`,
        borderRadius: 'var(--borderRadius)',
      },
      '.Input': {
        border: `1px solid ${borderColor}`,
        borderRadius: 'var(--borderRadius)',
        padding: `${uiTokens.size.spacing.xs.value} ${uiTokens.size.spacing.lg.value}`,
      },
      '.Input:focus': {
        border: `1px solid ${uiTokens.color.border.active[colorScheme]}`,
        borderColor: uiTokens.color.border.hover[colorScheme],
      },
    }
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <CreatePaymentMethodForm />
    </Elements>
  );
}
