import { BarStack } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import { AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { LegendOrdinal } from '@visx/legend';
import { localPoint } from '@visx/event';
import tokens from '@hyphen/hyphen-design-tokens/build/json/variables.json';
import { Box, useTheme } from '@hyphen/hyphen-components';
import { formatDateInUTC } from '../utils/dateUtils';

type DataType = {
  date: string;
  total: number;
  unique: number;
};

type DataKey = 'total' | 'unique';

type TooltipData = {
  bar: SeriesPoint<DataType>;
  key: DataKey;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export type BarChartProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  data: DataType[];
};

const color1 = tokens.color.background['chart-1'].value;
const color2 = tokens.color.background['chart-2'].value;
const defaultMargin = { top: 16, right: 0, bottom: 0, left: 0 };

const keys: DataKey[] = ['total', 'unique'];

const parseDate = timeParse('%m/%d/%Y');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

const getDate = (d: DataType) => formatDateInUTC(d.date);

const colorScale = scaleOrdinal<DataKey, string>({
  domain: keys,
  range: [color1, color2],
});

let tooltipTimeout: number;

export const BarChart = ({ width, height, events = false, margin = defaultMargin, data }: BarChartProps) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();

  const { isDarkMode } = useTheme();

  const background = isDarkMode ? tokens.color.background.primary.darkValue : tokens.color.background.primary.value;
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
  };

  const totals = data.map((d) => d.total + d.unique);

  const dateScale = scaleBand<string>({
    domain: data.map(getDate),
    padding: 0.2,
  });

  const valueScale = scaleLinear<number>({
    domain: [0, Math.max(...totals)],
    nice: true,
  });

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  if (width < 10) return null;

  // bounds
  const xMax = width;
  const yMax = height - margin.top - 100;

  dateScale.rangeRound([0, xMax]);
  valueScale.range([yMax, 0]);

  return width < 10 ? null : (
    <Box style={{ position: 'relative' }}>
      <svg ref={containerRef} width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={valueScale}
          width={xMax}
          height={yMax}
          stroke={background}
          xOffset={dateScale.bandwidth() / 2}
        />
        <Group top={margin.top}>
          <BarStack<DataType, DataKey>
            data={data}
            keys={keys}
            x={getDate}
            xScale={dateScale}
            yScale={valueScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    onClick={() => {
                      if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                    }}
                    onMouseLeave={() => {
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 300);
                    }}
                    onMouseMove={(event) => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      const eventSvgCoords = localPoint(event);
                      const left = bar.x + bar.width / 2;
                      showTooltip({
                        tooltipData: bar,
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: left,
                      });
                    }}
                  />
                )),
              )
            }
          </BarStack>
        </Group>
        <AxisBottom
          top={yMax + margin.top}
          scale={dateScale}
          tickFormat={formatDate}
          stroke="var(--color-border-default)"
          tickStroke="var(--color-border-default)"
          tickLabelProps={{
            fill: 'var(--color-font-base)',
            fontSize: 'var(--size-font-size-xs)',
            textAnchor: 'middle',
          }}
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          bottom: margin.bottom + 10,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          fontSize: 'var(--size-font-size-sm)',
        }}
      >
        <LegendOrdinal className="capitalize" scale={colorScale} direction="row" labelMargin="0 15px 0 0" />
      </div>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]}</div>
          <div>
            <small>{formatDate(getDate(tooltipData.bar.data))}</small>
          </div>
        </TooltipInPortal>
      )}
    </Box>
  );
};

export default BarChart;
