import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { Helmet } from 'react-helmet';
import { Box } from '@hyphen/hyphen-components';
import { ProjectsList } from '../../components/projects/ProjectsList';
import GetStartedDashboard from '../../components/dashboard/GetStartedDashboard';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { EntityNames } from '../../types/executionContext';

export const Dashboard = () => {
  const { organization = {} as Organization, isLoading } = useOrganization();
  const ability = useOrganizationAbilityContext();
  const canManageOrganizationIntegrations = ability.can('manage', EntityNames.OrganizationIntegration);
  const projectListContent = !isLoading && <ProjectsList organizationId={organization.id} />;

  return (
    <Box width="100" height="100" direction={{ base: 'column', tablet: 'row' }}>
      <Box flex="auto" overflow="auto" className="scroll-bar-thin">
        <Box width="100" margin="0">
          <Helmet>
            <title>Dashboard</title>
          </Helmet>
          {canManageOrganizationIntegrations ? (
            <>
              <GetStartedDashboard organizationId={organization.id} />
              <Box padding={{ base: '3xl', tablet: '5xl' }}>{projectListContent}</Box>
            </>
          ) : (
            <ScrollableMainLayout fullWidth>{projectListContent}</ScrollableMainLayout>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
