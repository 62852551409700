import {
  OrganizationBillingAccount,
  useGetBillingAccountByIdQuery,
  useLazyGetBillingAccountUsageQuery,
} from '../../services/billingAccount';
import { useEffect, useRef, useState } from 'react';
import { Box, Heading, SelectInputNative, Spinner } from '@hyphen/hyphen-components';
import PageHeader from '../PageHeader';
import { formatDateInUTC } from '../../utils/dateUtils';
import { UsageMetric } from '../billing/ViewUsage';
import { OrgBillingCard } from './OrgBillingCard';
import { ApiError } from '../ApiError';
import { useParams } from 'react-router-dom';
import { useAuth } from '../auth/authProvider';

export const ViewBillingUsage = () => {
  const { billingAccountId } = useParams<{ billingAccountId: any }>();
  const { user } = useAuth();

  const { data: billingData, error: billingAccountError, isLoading: billingAccountLoading } = useGetBillingAccountByIdQuery(billingAccountId);
  const [triggerBillingUsage, { data: usageData, error: usageError, isLoading: usageLoading }] = useLazyGetBillingAccountUsageQuery();

  const currentCycleRef = useRef<number | null>(null);
  const [selectedCycle, setSelectedCycle] = useState(currentCycleRef.current ?? 1);

  const isLoading = billingAccountLoading || usageLoading;

  useEffect(() => {
    if(billingData) {
      const isOwner = billingData.owners.some((owner) => owner.userId === user?.userId);
      if (isOwner) {
        triggerBillingUsage({ id: billingData.id });
      }
    }
  }, [billingData, triggerBillingUsage, user?.userId]);

  useEffect(() => {
    if (usageData?.billingCycle) {
      if (currentCycleRef.current === null) {
        currentCycleRef.current = usageData.billingCycle;
      }
      setSelectedCycle(usageData.billingCycle);
    }
  }, [usageData]);

  const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    await triggerBillingUsage({ id: billingAccountId, billingCycle: Number(selectedValue) }).unwrap();
    setSelectedCycle(Number(selectedValue));
  };

  const CycleOptions = currentCycleRef.current
    ? Array.from({ length: currentCycleRef.current }, (_, i) => ({
      value: i + 1,
      label: `Billing Cycle ${i + 1} ${i + 1 === currentCycleRef.current ? ' [Current]' : ''}`,
    })).reverse()
    : [{ value: 1, label: 'Billing Cycle 1' }];

  const showingCurrentCycle = currentCycleRef.current === selectedCycle;

  if(isLoading) return <Spinner />;

  return (
    <Box gap="4xl">
      <PageHeader title="Billing Usage" />
      {usageData && (
        <>
          <Box gap="sx">
            <SelectInputNative
              id="SelectCycle"
              name="SelectCycle"
              label="Billing Cycle"
              hideLabel
              value={selectedCycle}
              options={CycleOptions}
              onChange={handleChange}
            />
            <Box as="p" color="secondary">
              Each billing cycle is 30 days long
            </Box>
          </Box>

          <Box gap="xl">
            <Box gap="md" direction="row" alignItems="baseline">
              {usageData.startDate && (
                <Heading as="h2" size="sm">
                  Total usage for {' '}
                  {formatDateInUTC(usageData.startDate)} - {' '}
                  {usageData.endDate?.split('T')[0] === new Date().toISOString().split('T')[0]
                    ? 'Today'
                    : formatDateInUTC(usageData.endDate)}
                </Heading>
              )}
              {showingCurrentCycle && (
                <Box color="secondary">
                  {`${30 - usageData.days} day${usageData.days > 1 ? 's' : ''} remain in current cycle`}
                </Box>
              )}
            </Box>
            <Box display="grid" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }} gap="xs">
              <UsageMetric value={usageData.total?.env?.pull || 0} label="Total ENV Pulls" />
              <UsageMetric value={usageData.total?.env?.push || 0} label="Total ENV Pushes" />
              <UsageMetric value={usageData.total?.link?.created || 0} label="Total Links Created" />
              <UsageMetric value={usageData.total?.netInfo?.requests || 0} label="Total Net.Info Requests" />
              <UsageMetric value={0} label="Toggle Evaluations" />
            </Box>
          </Box>

          <Box gap="xl" margin={"xl 0"}>
            <Heading as="h3" size="md">Organizations</Heading>
            {usageData.organizations?.map((org: OrganizationBillingAccount) => (
              <OrgBillingCard org={org} key={org.id} />
            ))}
          </Box>
        </>
      )}
      {(billingAccountError || usageError) && (
        <ApiError
          error={billingAccountError || usageError}
          customMessages={{ default: 'An unexpected error occurred' }}
        />
      )}
    </Box>
  );
}
