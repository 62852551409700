import { Box } from '@hyphen/hyphen-components';

import { Integration } from '../../types/integrations';
import { IntegrationCatalogItem } from './catalog/IntegrationCatalogItem';

interface ConnectedIntegrationsProps {
  organizationId: string;
  integrations: Integration[];
}

export const ConnectedIntegrations = ({ integrations, organizationId }: ConnectedIntegrationsProps) => {
  return (
    <Box gap="sm">
      <Box as="h2" fontSize="md" fontWeight="medium" color="base">
        Connected Integrations
      </Box>
      <Box
        display="grid"
        direction="row"
        wrap
        gap="xl"
        className="one-column two-column-tablet three-column-desktop"
      >
        {integrations.map(({ id, type }) => (
          <IntegrationCatalogItem
            key={id}
            organizationId={organizationId}
            type={type}
            id={id}
            badge={{ variant: 'green', message: 'Connected' }}
          />
        ))}
      </Box>
    </Box>
  );
};
