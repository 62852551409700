import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Icon,
  toast,
  useOpenClose,
} from '@hyphen/hyphen-components';

import { ListItem } from '../ListItem';
import { Link } from 'react-router-dom';
import { Member } from '../../types/teams';
import { useCallback } from 'react';
import { useDeleteMemberMutation } from '../../services/member';
import { Organization } from '../../services/organization';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import DeleteMemberConfirmationModal from './DeleteMemberConfirmationModal';

const OrgMemberItem = ({ member, organization }: { member: Member; organization: Organization }) => {
  const [deleteMember, { isLoading: isDeleting, error: deleteError }] = useDeleteMemberMutation();
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const ability = useOrganizationAbilityContext();
  const canDeleteMember = ability.can('delete', EntityNames.Member);

  const renderDropdownContent = (memberId: string) => {
    return (
      <DropdownMenuGroup>
        <DropdownMenuItem asChild>
          <Link to={memberId}>
            <Icon name="eye" color="tertiary" /> <span>View</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="font-color-danger" onSelect={openDeleteModal}>
          <Icon name="c-remove" /> <span>Delete Member</span>
        </DropdownMenuItem>
      </DropdownMenuGroup>
    );
  };

  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteMember({ memberId: member.id, organizationId: organization.id });
      if (!result.error) {
        toast.success(`Member removed`);
        closeDeleteModal();
      } else {
        console.error(`Remove member failed`, result.error);
        toast.error(`Remove member failed`);
      }
    } catch (e) {
      console.error(`Remove member failed`, e);
      toast.error(`Remove member failed`);
    }
  }, [deleteMember, member.id, organization.id, closeDeleteModal]);

  const itemLabel =
    member?.firstName || member?.lastName ? `${member.firstName} ${member.lastName}` : `${member.nickname || '—'}`;

  return (
    <>
      <ListItem
        key={member.id}
        title={{
          label: itemLabel,
          link: `${member.id}`,
        }}
        subtitle={
          <Link to={member.id} className="font-color-secondary">
            {member.email}
          </Link>
        }
        dropdown={canDeleteMember && renderDropdownContent(member.id)}
      />
      <DeleteMemberConfirmationModal
        memberEmail={member.email}
        organizationName={organization.name}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        error={deleteError}
      />
    </>
  );
};

export default OrgMemberItem;
