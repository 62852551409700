import { Box, Button, Card, Pagination, Spinner } from '@hyphen/hyphen-components';
import { useGetProjectsQuery } from '../../services/projects';
import EmptyList from '../common/EmptyList';
import ProjectListItem from './ProjectListItem';
import { useState } from 'react';
import PageHeader from '../PageHeader';
import { Link } from 'react-router-dom';

const PAGE_SIZE = 50;

export const ProjectsList = ({ organizationId }: { organizationId: string }) => {
  const [pageNum, setPageNumber] = useState<number>(1);
  const { data, isLoading } = useGetProjectsQuery({ pageSize: PAGE_SIZE, pageNum, organizationId: organizationId });

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <Box>
      {data?.data.length === 0 ? (
        <EmptyList
          title="No Apps Exist"
          description="Use apps to manage cloud resources, environment variables, and feature toggles"
          iconName="block"
        >
          {/* @ts-ignore */}
          <Button as={Link} to={`/${organizationId}/projects/create`} variant="primary">
            Create Project
          </Button>
        </EmptyList>
      ) : (
        <Box gap="xl">
          <PageHeader title="Projects" />
          <div>
            {/* @ts-ignore */}
            <Button as={Link} to={`/${organizationId}/projects/create`} variant="primary">
              Create Project
            </Button>
          </div>
          <Box
            display="grid"
            gap={{ base: 'xl', desktop: '2xl' }}
            direction="row"
            style={{
              gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
            }}
          >
            {data?.data.map((project) => (
              <Card key={project.id}>
                <ProjectListItem key={project.id} project={project} />
              </Card>
            ))}
            {data && data.total > PAGE_SIZE ? (
              <Pagination
                activePage={pageNum}
                itemsPerPage={PAGE_SIZE}
                onChange={setPageNumber}
                totalItemsCount={data?.total ?? 0}
                isCompact
                arePagesVisible
                numberOfPagesDisplayed={3}
              />
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  );
};
