export enum DynamicTeamCriteriaOperator {
  equals = 'equals',
  regex = 'regex',
}

export enum DynamicTeamCriteriaConjunction {
  and = 'and',
  or = 'or',
}

export interface DynamicTeamCriteriaProperty {
  type: 'property';
  field: 'firstName' | 'lastName' | 'email';
  value: string;
  operator: DynamicTeamCriteriaOperator;
}

export interface DynamicTeamCriteriaCollection {
  type: 'role' | 'team';
  values: Array<string>;
  conjunction: DynamicTeamCriteriaConjunction;
}

export type DynamicTeamCriteria = DynamicTeamCriteriaProperty | DynamicTeamCriteriaCollection;
export type TeamType = 'static' | 'dynamic';

export interface Team {
  id: string;
  name: string;
  type: TeamType;
  memberCount: number;
  criteria?: Array<DynamicTeamCriteria>;
}

export interface TeamDetails {
  id: string;
  name: string;
  type: TeamType;
  memberCount: number;
  distributionList?: { name: string; email: string };
  channels?: Array<{ id: string; type: 'slack' | 'teams' }>;
  criteria?: Array<any>;
}

export interface BaseConnectedAccount {
  type: string;
  identifier: string;
}

export type ConnectedAccount = BaseConnectedAccount & Record<string, unknown>;

export interface Member {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  connectedAccounts: Array<ConnectedAccount>;
  nickname?: string;
  organization: {
    id: string;
    name: string;
  };
}

export interface ReplaceMembers {
  memberIds: Array<string>;
}
