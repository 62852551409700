import { AzureIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface AzureDetailsProps {
  integration: AzureIntegration;
}

export const AzureDetails = ({ integration }: AzureDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Tenant Id', value: integration.config.azureTenantId },
        { label: 'Tenant Name', value: integration.config.name },
      ]}
      integration={integration}
    />
  );
};

export default AzureDetails;
