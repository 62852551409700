import { Box, Popover, useOpenClose } from '@hyphen/hyphen-components';
import { DateTime } from 'luxon';

interface DateTimeProps {
  /**
   * Additional classNames to add
   */
  className?: string;
  /**
   * Display local date time as the primary
   */
  displayLocalDateTime?: boolean;
  /**
   * Format used to display text in the input box
   * default is default YYYY-MM-DD or YYYY-MM-DD{timeFormat}
   * @see https://momentjs.com/docs/#/parsing/string-format/
   */
  dateFormat?: string;
  /**
   * show date only but no time
   */
  excludeTime?: boolean;
  /**
   * hide the time zone text
   */
  hideTimeZone?: boolean;
  /**
   * A label for the time that is displayed
   */
  label?: React.ReactNode;
  /**
   * Format used to display time in the time column in the popover
   */
  timeFormat?: string;
  /**
   * Show local time zone beneath the UTC time.
   * UTC time is displayed beneath the local time if `displayLocalDateTime` is true.
   *
   * When `false` a [Tooltip](/#/Components/Tooltip) will display the alternative date time on hover.
   */
  showAlternativeTimeZone?: boolean;
  /**
   * Changes the font size of the date time display value
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * The value a UTC ISO 8601 string (https://en.wikipedia.org/wiki/ISO_8601)
   */
  value: string;

  placement?: string;
}

const DateTimeDisplay = (props: DateTimeProps) => {
  const { className, dateFormat, excludeTime, timeFormat = 'HH:mm a', value, placement = 'auto' } = props;

  const format = dateFormat || (excludeTime ? 'yyyy-MM-dd' : `yyyy-MM-dd ${timeFormat}`);

  const UTC = DateTime.fromISO(value).toUTC().toFormat(format);
  const Local = DateTime.fromISO(value).toLocal().toFormat(format);

  const { isOpen: isPopoverOpen, handleClose: closePopover, handleOpen: openPopover } = useOpenClose();

  const popoverContent = (
    <>
      <Box direction="column" gap="sm">
        <Box direction="row" fontWeight="bold">
          <span style={{ width: '64px' }}>Local</span>{' '}
          <Box as="span" fontFamily="monospace">
            {Local}
          </Box>
        </Box>
        <Box direction="row">
          <span style={{ width: '64px' }}>UTC</span>{' '}
          <Box as="span" fontFamily="monospace">
            {UTC}
          </Box>
        </Box>
      </Box>
    </>
  );

  return (
    <Popover
      content={popoverContent}
      isOpen={isPopoverOpen}
      placement={placement as any}
      contentContainerProps={{ background: 'inverse', padding: 'md', color: 'inverse' }}
    >
      <Box fontFamily="monospace" onMouseOver={openPopover} onMouseOut={closePopover} className={className}>
        {Local}
      </Box>
    </Popover>
  );
};

export default DateTimeDisplay;
