import { Box, Button } from '@hyphen/hyphen-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { IntegrationType } from '../../types/integrations';
import { Organization } from '../../services/organization';
import PageHeader from '../../components/PageHeader';
import { useCreateIntegrationMutation } from '../../services/integrations';
import { OrganizationPicker } from '../../components/organization/OrganizationPicker';
import { ApiError } from '../../components/ApiError';

//https://hyphen.ai/integrations/setup/github?installation_id=51565605&setup_action=install

export const IntegrationSetup = () => {
  const [searchParams] = useSearchParams();
  const { integrationType } = useParams<{ integrationType: string }>();
  const [integrationName, setIntegrationName] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: If this validation fails where do we want to send them?
    if (!searchParams.has('installation_id') || !searchParams.has('setup_action')) {
      alert('Invalid URL parameters');
    }
    if (integrationType !== 'github') {
      alert(`Invalid integration type: ${integrationType}`);
    }
    if (searchParams.get('setup_action') !== 'install') {
      alert('Invalid setup action');
    }

    setIntegrationName('GitHub App');
  }, [integrationType, searchParams]);

  const [CreateIntegration, { error }] = useCreateIntegrationMutation();

  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>();

  const handleConnect = useCallback(async () => {
    setIsSubmitting(true);
    const installationId = searchParams.get('installation_id');
    // be extra careful event though we validate onLoad and disable the button
    if (!selectedOrg) {
      alert('Please select an organization');
      return;
    }
    if (!installationId) {
      alert('Invalid installation id');
      return;
    }

    const integration = {
      type: IntegrationType.github,
      config: {
        installationId: installationId,
      },
    };

    const { data, error } = (await CreateIntegration({
      orgId: selectedOrg.id,
      integration: integration as any,
    })) as any;
    if (!error) {
      navigate(`/${selectedOrg.id}/Integrations/${data.id}`);
    }
    setIsSubmitting(false);
  }, [CreateIntegration, navigate, searchParams, selectedOrg]);

  return (
    <>
      <Helmet>
        <title>Setup Integration</title>
      </Helmet>
      <Box width="100" height="100" gap="4xl" alignItems="center" justifyContent="center">
        <Box textAlign="center" width="100" maxWidth="9xl" padding={{ base: '4xl', desktop: '5xl' }} gap="3xl">
          <PageHeader title={`Connect the ${integrationName} to`} />
          <OrganizationPicker handleSelection={setSelectedOrg} />
          <Button
            variant="primary"
            fullWidth
            isDisabled={!selectedOrg && isSubmitting}
            isLoading={isSubmitting}
            onClick={handleConnect}
          >
            Connect
          </Button>
          {error ? <ApiError error={error} /> : null}
        </Box>
      </Box>
    </>
  );
};
