import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import OrgSettings from './OrgSettings';
import AddDomain from './AddDomain';
import Billing from './Billing';
import Domains from './Domains';
import Members from './Members';
import Security from './Security';
import ApiKeys from './ApiKeys';
import ApiKey from './ApiKey';
import Environments from './Environments';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';

export default function Settings() {
  const ability = useOrganizationAbilityContext();
  const canCreateDomain = ability.can('create', EntityNames.Domain);
  return (
    <>
      <Helmet>
        <title>Organization Settings</title>
      </Helmet>
      <ScrollableMainLayout fullWidth>
        <Routes>
          <Route path="/environments" element={<Environments />} />
          <Route path="/billing/*" element={<Billing />} />
          <Route path="/domains" element={<Domains />} />
          {canCreateDomain && <Route path="/domains/add" element={<AddDomain />} />}
          {canCreateDomain && <Route path="/domains/:id/add" element={<AddDomain isBeingVerified />} />}
          <Route path="/api-keys" element={<ApiKeys />} />
          <Route path="/api-keys/:id" element={<ApiKey />} />
          <Route path="/members/*" element={<Members />} />
          <Route path="/security" element={<Security />} />
          <Route path="/" element={<OrgSettings />} />
        </Routes>
      </ScrollableMainLayout>
    </>
  );
}
