import { Box } from '@hyphen/hyphen-components';
import PageHeader from '../PageHeader';
import FirstRunCard from '../FirstRunCard';
import {
  INTEGRATION_DESCRIPTION_MAP,
  INTEGRATION_NAME_MAP,
  INTEGRATION_SLUG_MAP,
} from '../../constants/integrations';
import { useCreateIntegrationInviteMutation, useGetIntegrationsQuery } from '../../services/integrations';
import { IntegrationType } from '../../types/integrations';

export default function GetStartedDashboard({ organizationId }: { organizationId: string }) {
  const [CreateIntegrationInvite] = useCreateIntegrationInviteMutation();
  const { data, isLoading } = useGetIntegrationsQuery(organizationId);

  const allDone =
    data?.integrationSetup.cloudProvider &&
    data?.integrationSetup.github &&
    data?.integrationSetup.productivitySuite &&
    (data?.integrationSetup.slack);

  if (isLoading || allDone) return null;

  const handleGetIntegrationInvite = async () => {
    const { data } = await CreateIntegrationInvite({
      organizationId: organizationId,
      integration: { type: IntegrationType.slack },
    });
    const url = data?.url;
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <Box gap={{ base: '2xl', tablet: '3xl' }} className="first-run-hero-bg">
      <Box gap={{ base: '2xl', tablet: '4xl' }} padding={{ base: '3xl', tablet: '5xl' }}>
        <PageHeader
          title="&#128075; Welcome to Hyphen"
          description="Get the most out of Hyphen by connecting to your organization's tools"
        />
        <Box
          direction={{ base: 'column', tablet: 'row', desktop: 'column', hd: 'row' }}
          gap={{ base: '2xl', tablet: '3xl', desktop: '3xl', hd: '4xl' }}
        >
          {!data?.integrationSetup.slack && (
            <FirstRunCard
              name="Hyphen Bot for Slack"
              description={INTEGRATION_DESCRIPTION_MAP.slack}
              options={['slack']}
              variant="hero"
              onClick={handleGetIntegrationInvite}
              organizationId={organizationId}
            />
          )}
          <Box
            direction={
              data?.integrationSetup.slack
                ? { base: 'column', desktop: 'row' }
                : { base: 'column', tablet: 'column', desktop: 'row', hd: 'column' }
            }
            gap={{ base: '2xl', hd: '3xl' }}
          >
            {!data?.integrationSetup.productivitySuite ? (
              <FirstRunCard
                name="Your Productivity Suite"
                options={['googleWorkspace', 'office365']}
                description="Keep team membership synced for up-to-date document access, calendaring and distribution lists"
                organizationId={organizationId}
              />
            ) : null}

            {!data?.integrationSetup.cloudProvider ? (
              <FirstRunCard
                name="Your Cloud Provider"
                description="Automate your team's infrastructure and ensure resource access is automatically kept in sync with team membership"
                options={['aws', 'azure', 'googleCloud']}
                organizationId={organizationId}
              />
            ) : null}

            {!data?.integrationSetup.github ? (
              <FirstRunCard
                name={INTEGRATION_NAME_MAP.github}
                description={INTEGRATION_DESCRIPTION_MAP.github}
                options={['github']}
                integrationSlug={INTEGRATION_SLUG_MAP.github}
                organizationId={organizationId}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
