import { Box, FormikTextInput, Button } from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

const editCidrAccessControlSchema = yup.object().shape({
  description: yup.string().required('Description is required'),
  cidr: yup
    .string()
    .trim()
    .matches(
      /^([0-9]{1,3}\.){3}[0-9]{1,3}\/[0-9]{1,2}$|^([0-9a-fA-F]{0,4}:){2,7}[0-9a-fA-F]{0,4}\/[0-9]{1,3}$/,
      'Invalid CIDR format',
    )
    .required('CIDR is required'),
});

export type EditCidrAccessControlSchema = yup.InferType<typeof editCidrAccessControlSchema>;

interface Props {
  isLoading: boolean;
  handleSave: (values: EditCidrAccessControlSchema) => void;
  handleCancel: () => void;
  initialValues: EditCidrAccessControlSchema & Partial<EditCidrAccessControlSchema>;
}

export default function EnvAccessControlForm({ isLoading, handleSave, handleCancel, initialValues }: Props) {
  return (
    <Box padding="2xl" background="secondary">
      <Formik
        initialValues={initialValues}
        validationSchema={editCidrAccessControlSchema}
        validateOnBlur={false}
        onSubmit={handleSave}
      >
        {({ errors }) => (
          <Form noValidate>
            <Box gap="2xl" maxWidth="9xl">
              <Field
                id="description"
                name="description"
                label="Label"
                helpText="A short description of the CIDR range (e.g. Office VPN, Alex's Home)"
                component={FormikTextInput}
                error={errors.description}
                autoFocus
              />
              <Field
                id="cidr"
                name="cidr"
                label="CIDR"
                component={FormikTextInput}
                error={errors.cidr}
                helpText="A range of IP addresses (e.g. 192.0.2.0/24)"
              />
              <Box direction="row" gap="sm">
                <Button size="sm" type="submit" key="save" isLoading={isLoading}>
                  Save
                </Button>
                <Button size="sm" type="button" onClick={handleCancel} variant="tertiary" isDisabled={isLoading}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
