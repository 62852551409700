import { GoogleCloudIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';
import { INTEGRATION_NAME_MAP } from '../../../constants/integrations';

interface GoogleCloudDetailsProps {
  integration: GoogleCloudIntegration;
}

export const GoogleCloudDetails = ({ integration }: GoogleCloudDetailsProps) => {
  const gcManagementUrl = `https://console.cloud.google.com/welcome?organizationId=${integration.config.googleCloudOrganizationId}`;
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Organization Id', value: integration.config.googleCloudOrganizationId },
        { label: 'Organization Name', value: integration.config.googleCloudOrganizationName },
      ]}
      integration={integration}
      manage={{ manageUrl: gcManagementUrl, manageLabel: INTEGRATION_NAME_MAP.googleCloud }}
    />
  );
};

export default GoogleCloudDetails;
