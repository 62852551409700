import { Box, Button } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { CodeBlock } from '../../../Codeblock';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { GoogleCloudSetupForm } from './GoogleCloudSetupForm';

interface GoogleCloudSetupGuideProps {
  organizationId: string;
}

const OrganizationPrincipal = process.env.REACT_APP_GCP_ORGANIZATION_PRINCIPAL || '';
const ServiceAccount = process.env.REACT_APP_GCP_SERVICE_ACCOUNT || '';

export const GoogleCloudSetupGuide = ({ organizationId }: GoogleCloudSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Create or sign into your Google Cloud account">
        <p>
          <Button as="a" href="https://console.cloud.google.com/" target="_blank" iconSuffix="launch-app">
            Sign in to Google Cloud console
          </Button>
        </p>
        <p>or</p>
        <p>
          <Button as="a" href="https://cloud.google.com/docs/get-started" target="_blank" iconSuffix="launch-app">
            Create a new Google Cloud account
          </Button>
        </p>
      </GuideStep>
      <GuideStep title="Create an Organization in your Google Cloud account">
        <p>
          <Link to="https://cloud.google.com/resource-manager/docs/creating-managing-organization" target="_blank">
            Create Organization
          </Link>{' '}
          if one does not already exist.
        </p>
        <p>A Google Cloud organization ID is required for the next steps.</p>
      </GuideStep>
      <GuideStep
        title={
          <>
            <p>Add the Hyphen domain to allowed domains on your Google Cloud Organization</p>
            <p>
              <Link
                to="https://cloud.google.com/resource-manager/docs/organization-policy/restricting-domains"
                target="_blank"
              >
                Update Restricted Domain Policy Documentation
              </Link>
            </p>
          </>
        }
      >
        <Box as="ol" childGap="2xl" padding="0 0 0 2xl">
          <li>
            <p>
              Make sure you have the <strong>Organization Policy Administrator</strong> role.
            </p>
            <p>In the Google Cloud console, go to the Organization policies page</p>
            <p>
              <Button
                as="a"
                href="https://console.cloud.google.com/iam-admin/orgpolicies"
                target="_blank"
                iconSuffix="launch-app"
              >
                Go to Organization policies
              </Button>
            </p>
          </li>
          <li>
            <p>
              From the project picker, select the organization you want to set the organization policy to add Hyphen.
            </p>
          </li>
          <li>
            <p>
              On the Organization policies page, select <strong>Domain Restricted Sharing</strong> from the list of
              constraints. You may need to go to the second page in the list of policies.
            </p>
          </li>
          <li>
            <p>
              On the <strong>Policy details</strong> page, click <strong>Manage policy.</strong>.
            </p>
          </li>
          <li>
            <p>
              Under <strong>Applies to</strong>, select <strong>Override parent's policy</strong>.
            </p>
          </li>
          <li>
            <p>
              Click <strong>Add a rule</strong>.
            </p>
          </li>
          <li>
            <p>
              Under <strong>Policy values</strong>, select <strong>custom</strong>.
            </p>
          </li>
          <li>
            <p>
              Under <strong>Policy type</strong>, select <strong>Allow</strong>.
            </p>
          </li>
          <li>
            <p>
              Under <strong>Custom values</strong>, enter the following into the field.
            </p>
            <CodeBlock>{OrganizationPrincipal}</CodeBlock>
          </li>
          <li>
            <p>
              Click <strong>Done</strong>.
            </p>
          </li>
        </Box>
      </GuideStep>
      <GuideStep
        title={
          <>
            <p>Grant Hyphen service account permissions to your Google Cloud Organization</p>
            <p>
              <Link to="https://cloud.google.com/iam/docs/grant-role-console" target="_blank">
                Granting an IAM role by using the Google Cloud console
              </Link>
            </p>
          </>
        }
      >
        <Box as="ol" childGap="2xl" padding="0 0 0 2xl">
          <li>
            <p>
              In the Google Cloud console, go the <strong>IAM</strong> page.
            </p>
            <p>
              <Button
                as="a"
                href="https://console.cloud.google.com/iam-admin/iam"
                target="_blank"
                iconSuffix="launch-app"
              >
                Go to Organization IAM
              </Button>
            </p>
          </li>
          <li>
            <p>From the project picker, select the organization you want to grant Hyphen access.</p>
          </li>
          <li>
            <p>
              Click <strong>Grant access</strong>
            </p>
          </li>
          <li>
            <p>Enter the following identifier for the principal. </p>
            <CodeBlock>{ServiceAccount}</CodeBlock>
          </li>
          <li>
            <p>Add the following roles to the Hyphen service account:</p>
            <Box as="ul" padding="0 0 0 3xl">
              <li>
                <strong>Folder Creator</strong>
              </li>
              <li>
                <strong>Organization Administrator</strong>
              </li>
              <li>
                <strong>Project Creator</strong>
              </li>
            </Box>
          </li>
          <li>
            Click <strong>Save</strong>
          </li>
        </Box>
      </GuideStep>
      <GuideStep title="Connect Google Cloud">
        <p>Submit your Google Cloud Organization ID to connect it to Hyphen</p>
        <GoogleCloudSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
