import { PropsWithChildren, useEffect, useState } from 'react';
import {
  Card,
  Icon,
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
  useIsMobile,
} from '@hyphen/hyphen-components';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MainNavItem from '../components/MainNavItem';
import { ScrollableMainLayout } from '../components/ScrollableMainLayout';
import { useOrganizationsList } from './OrganizationsListProvider';
import { useAuth } from '../components/auth/authProvider';
import { useGetBillingAccountByIdQuery } from '../services/billingAccount';

export const UserSidebarProvider = ({ children }: PropsWithChildren) => {
  const { organizations = [], isLoading: isOrgListLoading } = useOrganizationsList();

  const { user } = useAuth();

  const location = useLocation();
  const backOrgId = location.state?.backOrgId || localStorage.getItem('orgId');
  const billId = organizations && organizations.find(org => org.id === backOrgId)?.billingAccount?.id;

  const { data: billingData, error: billingError } = useGetBillingAccountByIdQuery(billId!);

  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (billingData) {
      const isOwner = billingData.owners.some((owner) => owner.userId === user?.userId);
      setIsOwner(isOwner);
    }
  }, [billingData, user?.userId]);

  useEffect(() => {
    if (billingError) {
      setIsOwner(false);
    }
  }, [billingError]);

  const isMobile = useIsMobile();

  return (
    <SidebarProvider>
      <Sidebar side="left" collapsible="offcanvas">
        <SidebarHeader>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <Link
                  to={`/${backOrgId || organizations[0]?.id}`}
                  className="display-flex align-items-center flex-direction-row g-md p-v-md"
                >
                  <Icon name="caret-left" aria-label="back to accounts" />
                  {(!isOrgListLoading && organizations.find((org) => org.id === backOrgId)?.name) ||
                    organizations[0]?.name}
                </Link>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <NavLink end className="navlink" to="/profile"  state={{ backOrgId }}>
                  <MainNavItem iconName="user">Profile</MainNavItem>
                </NavLink>
              </SidebarMenuItem>
              {isOwner &&
                <SidebarMenuItem>
                  <NavLink end className="navlink" to={`/billing/${billId}`} state={{ backOrgId }}>
                    <MainNavItem iconName="chart-bar">Billing</MainNavItem>
                  </NavLink>
                </SidebarMenuItem>
              }
            </SidebarMenu>
          </SidebarGroup>
        </SidebarContent>
      </Sidebar>
      <SidebarInset>
        {isMobile && <SidebarTrigger />}
        <Card height="100" radius={{ base: '0', tablet: 'md' }}>
          <ScrollableMainLayout>
            {children}
          </ScrollableMainLayout>
        </Card>
      </SidebarInset>
    </SidebarProvider>
  )
}
