import { useEffect, useRef, useState } from 'react';
import { Box, Heading, SelectInputNative, Spinner } from '@hyphen/hyphen-components';
import { Organization, useLazyGetOrganizationUsageQuery } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { formatDateInUTC } from '../../utils/dateUtils';
import { ApiError } from '../ApiError';
import { DimensionSize } from '@hyphen/hyphen-components/dist/types';

export const ViewUsage = () => {
  const { organization = {} as Organization } = useOrganization();
  const [trigger, { isLoading, data: usage, error }] = useLazyGetOrganizationUsageQuery();

  const firstCycleRef = useRef<number | null>(null);

  const [selectedCycle, setSelectedCycle] = useState(firstCycleRef.current ?? 1);

  useEffect(() => {
    trigger({ organizationId: organization.id });
  }, [organization.id, trigger]);

  useEffect(() => {
    if (usage?.billingCycle) {
      if (firstCycleRef.current === null) {
        firstCycleRef.current = usage.billingCycle;
      }
      setSelectedCycle(usage.billingCycle);
    }
  }, [usage]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    trigger({ organizationId: organization.id, billingCycle: Number(selectedValue) });
    setSelectedCycle(Number(selectedValue));
  };

  const CycleOptions = firstCycleRef.current
    ? Array.from({ length: firstCycleRef.current }, (_, i) => ({
        value: i + 1,
        label: `Billing Cycle ${i + 1} ${i + 1 === firstCycleRef.current ? ' [Current]' : ''}`,
      })).reverse()
    : [{ value: 1, label: 'Billing Cycle 1' }];

  const showingCurrentCycle = firstCycleRef.current === selectedCycle;

  if (isLoading && !usage) return <Spinner />;
  if (error && !isLoading) return <ApiError error={error} />;
  if (!usage) return null;

  return (
    <Box width="100" maxWidth="9xl" gap="4xl" fontSize="sm">
      <Box gap="xs">
        <SelectInputNative
          id="SelectCycle"
          name="SelectCycle"
          label="Billing Cycle"
          hideLabel
          value={selectedCycle}
          options={CycleOptions}
          onChange={handleChange}
        />
        <Box as="p" color="secondary">
          Each billing cycle is 30 days long
        </Box>
      </Box>
      <Box gap="md">
        <Box gap="md" direction="row" alignItems="baseline">
          <Heading as="h2" size="sm">
            {formatDateInUTC(usage.startDate)} - {' '}
            {usage.endDate.split('T')[0] === new Date().toISOString().split('T')[0]
              ? 'Today'
              : formatDateInUTC(usage.endDate)}
          </Heading>
          {showingCurrentCycle && (
            <Box color="secondary">{`${30 - usage.days} day${
              usage.days > 1 ? 's' : ''
            } remain in current cycle`}</Box>
          )}
        </Box>
        <Box display="grid" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }} gap="xs">
          <UsageMetric value={usage.env?.pull || 0} label="ENV Pulls" />
          <UsageMetric value={usage.env?.push || 0} label="ENV Pushes" />
          <UsageMetric value={usage.link?.created || 0} label="Links Created" />
          <UsageMetric value={usage.netInfo?.requests || 0} label="Net.Info Requests" />
          <UsageMetric value={0} label="Toggle Evaluations" />
        </Box>
      </Box>
    </Box>
  );
};

export const UsageMetric = ({ value, label }: { value: number; label: string; width?: DimensionSize }) => (
  <Box background="secondary" padding="xl">
    <Box fontSize="3xl" fontWeight="bold">
      {value.toLocaleString()}
    </Box>
    <Box fontSize="sm" color="secondary">
      {label}
    </Box>
  </Box>
);
