import React, { forwardRef } from 'react';
import { Box, Icon } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { FontColor, IconName } from '@hyphen/hyphen-components/dist/types';

const TaskBase = forwardRef<
  HTMLDivElement | HTMLButtonElement | HTMLAnchorElement,
  {
    name: string;
    iconName: IconName;
    iconColor: FontColor;
    variant?: 'div' | 'button' | 'link';
    onClick?: () => void;
    linkPath?: string;
    closePopover?: () => void;
    isCompleted?: boolean;
  }
>(({ name, iconName, iconColor, variant = 'div', onClick, linkPath, closePopover, isCompleted = false }, ref) => {
  const handleClick = () => {
    if (onClick) onClick();
    if (closePopover) closePopover();
  };

  const content = (
    <Box
      as={variant === 'link' ? undefined : variant}
      ref={ref}
      {...(variant === 'button' && onClick ? { onClick: handleClick, cursor: 'pointer' } : {})}
      radius="md"
      padding="lg"
      background={isCompleted ? 'secondary' : 'primary'}
      borderColor="subtle"
      borderWidth="sm"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      color={isCompleted ? 'tertiary' : 'base'}
      hover={!isCompleted ? { background: 'secondary', borderColor: 'default' } : undefined}
    >
      <span style={isCompleted ? { textDecoration: 'line-through' } : undefined}>{name}</span>
      <Icon name={iconName} color={iconColor} size="md" />
    </Box>
  );

  return variant === 'link' && linkPath ? (
    <Link to={linkPath} onClick={closePopover} style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  ) : (
    content
  );
});

export const CompletedTask = forwardRef<
  HTMLDivElement,
  { name: string; onClick?: () => void; closePopover?: () => void }
>(({ name, onClick, closePopover }, ref) => (
  <TaskBase
    ref={ref}
    name={name}
    iconName="c-check"
    iconColor="disabled"
    variant={onClick ? 'button' : 'div'}
    onClick={onClick}
    closePopover={closePopover}
    isCompleted
  />
));

export const UncompletedTaskButton = forwardRef<
  HTMLButtonElement,
  { name: string; onClick: () => void; closePopover: () => void }
>(({ name, onClick, closePopover }, ref) => (
  <TaskBase
    ref={ref}
    name={name}
    iconName="circle"
    iconColor="tertiary"
    variant="button"
    onClick={onClick}
    closePopover={closePopover}
  />
));

export const UncompletedTaskLink = forwardRef<
  HTMLAnchorElement,
  { name: string; linkPath: string; closePopover: () => void }
>(({ name, linkPath, closePopover }, ref) => (
  <TaskBase
    ref={ref}
    name={name}
    iconName="circle"
    iconColor="tertiary"
    variant="link"
    linkPath={linkPath}
    closePopover={closePopover}
  />
));
