import { Box, Button, Heading, Icon, Modal, useOpenClose } from '@hyphen/hyphen-components';
import { formatDateWithTime, timeAgo } from '../../utils/dateUtils';
import { EventsResponse, EventType } from '../../types/events';
import { EventsTableContent } from './EventsTableContent';
import { Link } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import ClickToCopy from '../common/ClickToCopy';
import { EventTypeLabel } from '@hyphen/nucleus/dist/types';
import { INTEGRATION_NAME_MAP } from '../../constants/integrations';
import { ListResponse } from '../../services/types';

interface EventsTableProps {
  events?: ListResponse<EventsResponse, 'Cursor'>;
  setNextPageCursor: (pageCursor: string) => void;
  isLoading: boolean;
  eventsContent: EventsResponse[];
}

function GetSubjectLabel(type: string, event: EventsResponse) {
  const { organization = {} as Organization } = useOrganization();

  switch (type) {
    case EventType.CreateOrganizationIntegrationConnection:
    case EventType.DeleteOrganizationIntegrationConnection:
      return INTEGRATION_NAME_MAP[event.data.integrationType];
    case EventType.AddMember:
    case EventType.DeleteMember:
      if (event.data.lastName.toLowerCase().includes('api key')) {
        return `${event.data.firstName} (API key)`;
      }
      return event.data.email;
    case EventType.CreateLinkDomain:
    case EventType.UpdateLinkDomain:
      return <Link to={`/${organization.id}/settings/domains`}>{event.data.domain}</Link>;
    case EventType.DeleteLinkDomain:
      return event.data.domain;
    case EventType.CreateLinkCode:
    case EventType.UpdateLinkCode:
      return <Link to={`/${organization.id}/link/${event.data.id}`}>/{event.data.code}</Link>;
    case EventType.DeleteLinkCode:
      return <>/{event.data.code}</>;
    case EventType.DeleteApp:
      return (
        <>
          {event.data.name || ''} in project{' '}
          <Link to={`/${organization.id}/${event.data.project?.alternateId}`}>{event.data.project?.name || ''}</Link>
        </>
      );
    case EventType.CreateApp:
    case EventType.UpdateApp:
      return (
        <>
          {event.data.project && (
            <>
              <Link to={`/${organization.id}/${event.data.project.alternateId}/app/${event.data.id}`}>
                {event.data.name || ''}
              </Link>{' '}
              in project{' '}
              <Link to={`/${organization.id}/${event.data.project.alternateId}`}>{event.data.project.name}</Link>
            </>
          )}
        </>
      );
    case EventType.CreateEnvData:
    case EventType.GetEnvData:
      return event.data.version ? (
        <>
          v{event.data.version} for {event.data.app}{' '}
          <span className="font-size-xs font-family-monospace">
            [{event.data.environment === '*' ? 'default (all)' : event.data.environment}]
          </span>
        </>
      ) : (
        ''
      );
    case EventType.GetEnvDataDeny:
      return (
        <>
          {event.data.app}{' '}
          <span className="font-size-xs font-family-monospace">
            [{event.data.environment === '*' ? 'default (all)' : event.data.environment}]
          </span>
        </>
      );
    case EventType.CreateProject:
      return <Link to={`/${organization.id}/${event.data.alternateId}`}>{event.data.name || ''}</Link>;
    default:
      return event.data.name || '';
  }
}

const GetEventIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'ENV.GET.DENY':
      return (
        <Box width="16px" height="16px">
          <Icon name="c-remove" color="danger" size="md" />
        </Box>
      );
    default:
      return null;
  }
};

export const EventsTable = ({ events, setNextPageCursor, isLoading, eventsContent }: EventsTableProps) => {
  const columnConfig = [
    {
      heading: 'Date',
      dataKey: 'timestamp',
      render: (_cell: any, row: any) => (
        <Box direction={{ base: 'column', tablet: 'row' }} gap={{ base: 'xs', tablet: 'lg', desktop: 'xl' }}>
          <Box>{timeAgo(row.timestamp)}</Box>
          <Box color="secondary">{formatDateWithTime(row.timestamp.toString())}</Box>
        </Box>
      ),
    },
    {
      heading: 'Event',
      dataKey: 'type',
      render: (_cell: any, row: any) => (
        <Box alignItems="center" direction="row" gap="sm">
          {GetEventIcon(row)}
          <Box display="block">
            {EventTypeLabel[row.type as keyof typeof EventTypeLabel] || row.type}{' '}
            {row.data && GetSubjectLabel(row.type, row)}
          </Box>
        </Box>
      ),
    },
    {
      heading: 'User',
      dataKey: 'user',
    },
    {
      heading: 'IP',
      dataKey: 'ipAddress',
    },
    {
      heading: '',
      dataKey: 'references',
      width: 88,
    },
  ];

  const tableData = eventsContent.map((event, index) => ({
    type: event?.type,
    user: event.member?.name
      ? event.member.name
      : event.actor?.name
      ? event.actor.name
      : event.user?.name
      ? event.user.name
      : 'unknown',
    timestamp: event?.timestamp,
    references: event?.references?.length || event?.data?.entity ? <EventDetailModal event={event} /> : '',
    data: event?.data,
    member: event?.member,
    requestId: event?.requestId,
    ipAddress: event?.data?.userIpAddress || '-',
    key: index,
  }));

  return (
    <EventsTableContent
      events={events}
      tableData={tableData}
      columnConfig={columnConfig}
      setNextPageCursor={setNextPageCursor}
      isLoading={isLoading}
    />
  );
};

const EventDetailModal = ({ event }: { event: EventsResponse }) => {
  const { isOpen, handleOpen, handleClose } = useOpenClose();

  const { references, data } = event;

  return (
    <>
      <Modal maxWidth="9xl" isOpen={isOpen} onDismiss={handleClose}>
        <Modal.Body gap="2xl">
          <Box gap="2xl">
            <Heading as="h1" size="md">
              Event
            </Heading>
            <Box gap="2xs">
              <Box display="block" as="p">
                {event.member?.name || event.user?.name || event.actor?.name || 'unknown'}{' '}
                <span style={{ textTransform: 'lowercase' }}>
                  {EventTypeLabel[event.type as keyof typeof EventTypeLabel] || event.type}
                </span>{' '}
                {event.data && GetSubjectLabel(event.type, event)}
              </Box>
              <Box color="secondary" fontSize="sm" direction="row" gap="sm">
                <Box>{timeAgo(event.timestamp)}</Box>-<Box>{formatDateWithTime(event.timestamp.toString())}</Box>
              </Box>
            </Box>
            <Box gap="sm">
              {data?.entity && (
                <Box direction="row" alignItems="baseline" gap="md" width="100">
                  <Box flex="auto" fontSize="sm">
                    {data.entity.type}
                  </Box>
                  <ClickToCopy key={data.entity.id} text={data.entity.id} />
                </Box>
              )}
              {references.map((reference: any) => {
                const { id: referenceId, type } = reference;

                return (
                  <Box key={referenceId} direction="row" alignItems="baseline" gap="md" width="100">
                    <Box flex="auto" fontSize="sm">
                      {type}
                    </Box>
                    <ClickToCopy key={referenceId} text={referenceId} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Box
        as="button"
        style={{ textDecoration: 'underline' }}
        onClick={handleOpen}
        borderWidth="0"
        padding="0"
        background="transparent"
        color="secondary"
        hover={{ color: 'base' }}
        cursor="pointer"
      >
        details
      </Box>
    </>
  );
};
