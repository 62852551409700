import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { useGetIntegrationsQuery } from '../../../services/integrations';
import { Integration } from '../../../types/integrations';
import { EntityType } from '../../../types/executionContext';
import { useCallback, useMemo, useState } from 'react';
import { INTEGRATION_NAME_MAP } from '../../../constants/integrations';
import { ConnectionModal } from './ConnectionModal';
import { useNavigate } from 'react-router-dom';

interface ConnectionAddButtonProps {
  organizationId: string;
  entity?: {
    type: EntityType;
    id: string;
    name: string;
  };
}

export const ConnectionAddButton = ({ organizationId, entity }: ConnectionAddButtonProps) => {
  const { isOpen: isCreateModalOpen, handleOpen: openCreateModal, handleClose: closeCreateModal } = useOpenClose();
  const { data: integrations, isLoading } = useGetIntegrationsQuery(organizationId);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null);
  const navigate = useNavigate();

  const applicableIntegrations: Integration[] = useMemo(() => {
    if (!integrations) {
      return [];
    }
    return entity?.type
      ? integrations.data.filter((integration) => {
          return integration.provides.some((provides) => provides.for.includes(entity.type));
        })
      : integrations.data;
  }, [entity?.type, integrations]);

  const onSelect = useCallback(
    (integration: Integration) => {
      setSelectedIntegration(integration);
      openCreateModal();
    },
    [openCreateModal],
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="primary" size="md" iconSuffix="caret-down" isLoading={isLoading}>
            Create a Connection
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" side="bottom">
          <DropdownMenuGroup>
            {applicableIntegrations.map((integration) => (
              <DropdownMenuItem key={integration.id} onSelect={() => onSelect(integration)}>
                {INTEGRATION_NAME_MAP[integration.type]}
              </DropdownMenuItem>
            ))}
            {applicableIntegrations.length > 0 && <DropdownMenuSeparator />}
            <DropdownMenuItem key={'add_integration'} onSelect={() => navigate(`/${organizationId}/integrations`)}>
              <Icon name="add" /> Add New Integration
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      {selectedIntegration && (
        <ConnectionModal
          organizationId={organizationId}
          entity={entity}
          integration={selectedIntegration}
          isOpen={isCreateModalOpen}
          onClose={closeCreateModal}
        />
      )}
    </>
  );
};
