import { Box } from '@hyphen/hyphen-components';
import { PropsWithChildren, ReactNode } from 'react';

export interface GuideStepProps extends PropsWithChildren {
  title: ReactNode;
}

export const GuideStep = ({ title, children }: GuideStepProps) => (
  <Box as="li" direction={{ base: 'column', tablet: 'row' }} gap={{ base: 'lg', tablet: '5xl' }} width="100">
    <Box
      minWidth={{ base: '100', tablet: '7xl' }}
      width={{ base: '100', tablet: '7xl' }}
      style={{ lineHeight: 'var(--size-line-height-text)' }}
    >
      {title}
    </Box>
    <Box flex="auto" style={{ lineHeight: 'var(--size-line-height-text)' }}>
      <Box display="block" childGap="2xl">
        {children}
      </Box>
    </Box>
  </Box>
);
