import { Box } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';

export default function Security() {
  return (
    <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Security</title>
      </Helmet>
      <PageHeader title="Security" description="Mange your organization's security and how members authenticate" />
    </Box>
  );
}
