import { DateTime } from 'luxon';

export const formatDateWithTime = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  return date.toFormat('MM/dd/yyyy hh:mm a');
};

export const formatDateInUTC = (dateString: string) => {
  const date = DateTime.fromISO(dateString, { zone: 'utc' });
  return date.toFormat('MM/dd/yyyy');
};

export const timeAgo = (date: string) => {
  const dateTime = DateTime.fromISO(date);
  const now = DateTime.now();
  return dateTime.toRelative({ base: now }) || undefined;
};

export const last24Hours = new Date(Date.now() - 24 * 60 * 60 * 1000);
export const last48Hours = new Date(Date.now() - 48 * 60 * 60 * 1000);
export const last7Days = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
export const last14Days = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
export const last30Days = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

export const formatDateToUTC = (date: Date): string => {
  return DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC().toISO() as string;
};
