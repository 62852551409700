import { Box } from '@hyphen/hyphen-components';
import { ReactNode } from 'react';

export const ScrollableMainLayout = ({
  fullWidth = false,
  children,
}: {
  fullWidth?: boolean;
  children: ReactNode;
}) => {
  return (
    <Box width="100" height="100" overflow="auto" className="scroll-bar-thin">
      <Box
        gap={{ base: '2xl', tablet: '4xl' }}
        maxWidth={fullWidth ? '100%' : '10xl'}
        width="100"
        padding={{ base: '3xl', tablet: '5xl' }}
        margin="0 auto"
      >
        {children}
      </Box>
    </Box>
  );
};
