import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Button, FormikTextInput, Modal } from '@hyphen/hyphen-components';
import { ModalProps } from '../components/types/modal';
import { ApiError } from '../components/ApiError';
import { InferType } from 'yup';
import { DependentFormikField } from '../components/common/form/DependentFormikField';
import { generateSlug } from '../utils/generateSlug';
import { forbiddenWords } from '../utils/validations';

const environmentSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Alternate ID')
    .notOneOf(forbiddenWords, `Can not contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
  color: yup
    .string()
    .matches(/^#[0-9A-F]{6}$/i, 'Invalid color format')
    .required('Color is required'),
});

export type EnvironmentSchema = InferType<typeof environmentSchema>;

interface EnvironmentModalProps extends ModalProps {
  title: string;
  message?: string;
  onSubmit: (values: EnvironmentSchema) => void;
  error?: any;
  showAlternateId?: boolean;
}

export const EnvironmentModal = ({
  isOpen,
  onClose,
  title,
  message,
  onSubmit,
  data,
  error,
  showAlternateId = true,
}: EnvironmentModalProps) => {
  const initialValues = {
    name: data?.name || '',
    alternateId: data?.alternateId || '',
    color: data?.color || '#ffc800',
  };

  return (
    <Modal ariaLabelledBy="environmentModal" maxWidth="9xl" isOpen={isOpen} onDismiss={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={environmentSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, isSubmitting, touched }) => {
          return (
            <Form noValidate>
              <Box gap={{ base: '2xl', tablet: '4xl' }}>
                <Modal.Header id="environmentModal" title={title} onDismiss={onClose} />
                <Modal.Body gap="2xl">
                  {message && (
                    <Box as="p" color="secondary" fontSize="sm">
                      {message}
                    </Box>
                  )}
                  <Field
                    label="Name"
                    name="name"
                    id="name"
                    autoComplete="off"
                    component={FormikTextInput}
                    error={errors.name}
                    isRequired
                    isDisabled={isSubmitting}
                  />
                  {showAlternateId && (
                    <DependentFormikField
                      label="Alternate ID"
                      name="alternateId"
                      id="alternateId"
                      autoComplete="off"
                      component={FormikTextInput}
                      error={errors.alternateId}
                      dependentField="name"
                      targetField="alternateId"
                      generateValue={generateSlug}
                      isDisabled={isSubmitting}
                      isRequired
                    />
                  )}
                  <Box>
                    <Box as="label" htmlFor="color" margin="0 0 xs" fontSize="sm">
                      Pick a color *
                    </Box>
                    <Field type="color" name="color" id="color" disabled={isSubmitting} />
                    {errors.color && touched.color && (
                      <Box color="danger" fontSize="sm" margin="sm 0 0 0">
                        {typeof errors.color === 'string' && errors.color}
                      </Box>
                    )}
                  </Box>
                  {error && <ApiError error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={onClose} isDisabled={isSubmitting} shadow="sm">
                    Cancel
                  </Button>
                  <Button variant="primary" isLoading={isSubmitting} type="submit" shadow="sm">
                    Save
                  </Button>
                </Modal.Footer>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
