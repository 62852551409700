import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Icon,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
  useSidebar,
} from '@hyphen/hyphen-components';
import { NavLink, useLocation } from 'react-router-dom';

// Components
import ComingSoon from './ComingSoon';
import GetStarted from './GetStarted';
import MainNavItem from './MainNavItem';

// Hooks and Utilities
import { useAuth } from './auth/authProvider';
import { useOrganizationsList } from '../providers/OrganizationsListProvider';
import { useOrganization } from '../providers/OrganizationProvider';
import { Organization } from '../services/organization';
import { useOrganizationAbilityContext } from './auth/OrganizationAbilityProvider';
import { EntityNames } from '../types/executionContext';
import OrganizationSwitcher from './OrganizationSwitcher';
import UserMenu from './UserMenu';
import { IconName } from '@hyphen/hyphen-components/dist/types';

const AppSidebar: React.FC = () => {
  const { organization = {} as Organization } = useOrganization();
  const { organizations = [] } = useOrganizationsList();
  const ability = useOrganizationAbilityContext();
  const canManageOrganizationIntegrations = ability.can('manage', EntityNames.OrganizationIntegration);
  const canReadEvents = ability.can('read', EntityNames.Event);
  const canViewApiKeys = ability.can('read', EntityNames.ApiKey);
  const canViewMembers = ability.can('read', EntityNames.Member);

  const { isMobile, setOpenMobile } = useSidebar();

  const { logout, user } = useAuth();

  const SIDEBAR_ITEMS = [
    { label: 'Dashboard', icon: 'dashboard', link: `/${organization.id}`, disabled: false, end: true },
    { label: 'Teams', icon: 'users', link: `/${organization.id}/teams`, disabled: false },
    { label: 'Link', icon: 'logo-link', link: `/${organization.id}/link`, disabled: false },
    { label: 'ENV', icon: 'logo-env', link: `/${organization.id}/env`, disabled: false },
    { label: 'Toggle', icon: 'logo-toggle', link: '', disabled: true },
    ...(canManageOrganizationIntegrations
      ? [{ label: 'Integrations', icon: 'stack', link: `/${organization.id}/integrations`, disabled: false }]
      : []),
    ...(canReadEvents
      ? [{ label: 'Events', icon: 'document', link: `/${organization.id}/events`, disabled: false }]
      : []),
    {
      label: 'Settings',
      icon: 'settings',
      disabled: false,
      link: `/${organization.id}/settings`,
      items: [
        { label: 'General', link: `/${organization.id}/settings`, end: true },
        { label: 'Domains', link: `/${organization.id}/settings/domains` },
        { label: 'Environments', link: `/${organization.id}/settings/environments` },
        ...(canViewApiKeys ? [{ label: 'API Keys', link: `/${organization.id}/settings/api-keys` }] : []),
        ...(canViewMembers ? [{ label: 'Members', link: `/${organization.id}/settings/members` }] : []),
        { label: 'Billing', link: `/${organization.id}/settings/billing` },
      ],
    },
  ];

  const handleMenuItemClick = () => {
    if (isMobile) {
      setOpenMobile(false);
    }
  };

  return (
    <Sidebar side="left" collapsible="offcanvas">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <OrganizationSwitcher
              organizations={organizations}
              currentOrg={organization}
              onOrgSelect={handleMenuItemClick}
            />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {SIDEBAR_ITEMS.map(({ icon, label, ...restProps }) => (
              <SidebarItem
                key={label}
                label={label}
                icon={icon as IconName}
                onClick={handleMenuItemClick}
                {...restProps}
              />
            ))}
          </SidebarMenu>
        </SidebarGroup>
        {canManageOrganizationIntegrations && (
          <SidebarGroup>
            <Box padding="0 lg">
              <GetStarted organizationId={organization?.id} />
            </Box>
          </SidebarGroup>
        )}
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarMenuItem>
            {user && (
              <UserMenu
                user={user}
                organizations={organizations}
                organizationId={organization?.id}
                logout={logout}
                onSelect={handleMenuItemClick}
              />
            )}
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
      {!isMobile && <SidebarRail />}
    </Sidebar>
  );
};

export default AppSidebar;

interface SidebarItemProps {
  label: string;
  icon: IconName;
  link?: string;
  end?: boolean;
  disabled?: boolean;
  items?: { label: string; link: string; end?: boolean }[];
  onClick: () => void;
}

const SidebarItem = ({ label, icon, link, end, disabled, items = [], onClick }: SidebarItemProps) => {
  const location = useLocation();

  const isNestedActive = items.some((item) => location.pathname.includes(item.link));

  return items.length > 0 ? (
    <Collapsible key={label} asChild defaultOpen={isNestedActive}>
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton>
            <Box width="24px" height="24px" justifyContent="center" alignItems="center">
              <Icon name={icon as IconName} color="disabled" size="lg" />
            </Box>
            {label}
            <Icon name="caret-sm-right" className="m-left-auto transform data-[state=open]:rotate-90" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            {items.map((subItem) => (
              <SidebarMenuSubItem key={subItem.label}>
                <SidebarMenuSubButton asChild>
                  <NavLink to={subItem.link} end={subItem.end} className="navlink" onClick={onClick}>
                    {subItem.label}
                  </NavLink>
                </SidebarMenuSubButton>
              </SidebarMenuSubItem>
            ))}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  ) : (
    <SidebarMenuItem>
      {disabled ? (
        <MainNavItem iconName={icon as IconName} isDisabled>
          {label}
          <ComingSoon className="m-left-sm" />
        </MainNavItem>
      ) : (
        <NavLink to={link || ''} end={end} className="navlink" onClick={onClick}>
          <MainNavItem iconName={icon as IconName}>{label}</MainNavItem>
        </NavLink>
      )}
    </SidebarMenuItem>
  );
};
