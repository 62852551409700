import { Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { ApiKeySecret, useDeleteApiKeySecretMutation } from '../../services/apiKeys';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';

export default function DeleteApiKeySecret({
  organizationId,
  apiKeyId,
  secret,
}: {
  organizationId: string;
  apiKeyId: string;
  secret: ApiKeySecret;
}) {
  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();

  const [deleteApiKeySecret, { isLoading: isDeleting }] = useDeleteApiKeySecretMutation();

  const handleRemove = useCallback(async () => {
    try {
      const result = await deleteApiKeySecret({ orgId: organizationId, apiKeyId, apiKeyIdSecret: secret.id });
      if (!result.error) {
        toast.success(`Secret '${secret.hint}' removed!`);
        closeRemoveModal();
      } else {
        console.error(`Failed to remove secret '${secret.hint}'`, result.error);
        toast.error(`Failed to remove secret '${secret.hint}'`);
      }
    } catch (e) {
      console.error(`Failed to remove secret '${secret.hint}'`, e);
      toast.error(`Failed to remove secret '${secret.hint}'`);
    }
  }, [apiKeyId, closeRemoveModal, deleteApiKeySecret, organizationId, secret.hint, secret.id]);

  return (
    <>
      <ConfirmModal
        message={`Are you sure you want to delete this secret '${secret.hint}'?`}
        confirmButtonLabel="Delete Secret"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={handleRemove}
        isLoading={isDeleting}
        title={`Delete secret '${secret.hint}'`}
        description="You will no longer be able to use this secret to authenticate with our API."
      />
      <Button variant="tertiary" iconPrefix="c-remove" aria-label="delete secret" onClick={openRemoveModal} />
    </>
  );
}
