import { Button } from '@hyphen/hyphen-components';
import { Connection, ConnectionType } from '../../../types/connections';
import { IntegrationType } from '../../../types/integrations';

type ConnectionUrl = { label: string; href: string } | undefined;

const buildAwsUrl = (connection: Connection): ConnectionUrl => {
  return undefined;
};

const buildAzureUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.groupName,
        href: `https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/${connection.config.groupId}/menuId/`,
      };
    default:
      return undefined;
  }
};

const buildGoogleWorkspaceUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.DistributionList:
      return {
        label: connection.config.email,
        href: `https://admin.google.com/u/2/ac/groups/${connection.config.distributionListId}`,
      };
    default:
      return undefined;
  }
};

const buildGoogleCloudUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.PermissionGroup:
      return {
        label: connection.config.groupName,
        href: `https://admin.google.com/u/2/ac/groups/${connection.config.groupId}`,
      };
    default:
      return undefined;
  }
};

const buildGithubUrl = (connection: Connection): ConnectionUrl => {
  return undefined;
};

const buildSlackUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.type) {
    case ConnectionType.Channel:
      return {
        label: connection.config.name,
        href: `https://app.slack.com/client/${connection.config.workspaceId}/${connection.config.id}`,
      };
    default:
      return undefined;
  }
};

export const buildConnectionUrl = (connection: Connection): ConnectionUrl => {
  switch (connection.organizationIntegration.type) {
    case IntegrationType.aws:
      return buildAwsUrl(connection);
    case IntegrationType.azure:
      return buildAzureUrl(connection);
    case IntegrationType.googleWorkspace:
      return buildGoogleWorkspaceUrl(connection);
    case IntegrationType.googleCloud:
      return buildGoogleCloudUrl(connection);
    case IntegrationType.github:
      return buildGithubUrl(connection);
    case IntegrationType.slack:
      return buildSlackUrl(connection);
    default:
      return undefined;
  }
};

export interface ConnectionItemProps {
  connection: Connection;
}

export const ConnectionLink = ({ connection }: ConnectionItemProps) => {
  if (connection.status !== 'Ready') {
    return null;
  }

  const connectionUrl = buildConnectionUrl(connection);

  if (!connectionUrl) {
    return null;
  }

  return (
    <Button
      size="sm"
      variant="secondary"
      iconSuffix="launch-app"
      as="a"
      href={connectionUrl.href}
      aria-label="Manage Connection"
    >
      {connectionUrl.label}
    </Button>
  );
};
