import { Box, Button, Card, Spinner, toast, useOpenClose } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import EmptyList from '../../components/common/EmptyList';
import { EnvironmentActionItem, EnvironmentDataType } from '../../environments/EnvironmentActionItem';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization, useUpdateOrganizationMutation } from '../../services/organization';
import { EnvironmentModal, EnvironmentSchema } from '../../environments/EnvironmentModal';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';

const Environments = () => {
  const { isOpen: isCreateModalOpen, handleOpen: openCreateModal, handleClose: closeCreateModal } = useOpenClose();

  const { organization, isLoading = {} as Organization } = useOrganization();
  const [updateOrganization, { error, isLoading: isUpdating }] = useUpdateOrganizationMutation();
  const environments = organization?.defaultEnvironments || [];
  const ability = useOrganizationAbilityContext();
  const canManageOrg = ability.can('manage', EntityNames.Organization);

  const handleCreate = async (values: EnvironmentSchema) => {
    if (!organization) return;

    const updatedEnvironments: EnvironmentDataType[] = [...environments, values];
    const { data, error } = await updateOrganization({
      id: organization.id,
      data: {
        name: organization.name,
        defaultEnvironments: updatedEnvironments,
      },
    });
    if (!error && data) {
      closeCreateModal();
      toast.success('Environment created successfully');
    }
  };

  const handleEdit = async (values: EnvironmentSchema, defaultEnvironment: EnvironmentDataType, onClose: () => void) => {
    if (!organization) return;

    const envToUpdate = environments.find((env) => env.alternateId === defaultEnvironment.alternateId);
    if (!envToUpdate) return;

    const envUpdated: EnvironmentDataType = {
      ...envToUpdate,
      name: values.name,
      alternateId: values.alternateId,
      color: values.color,
    };

    const envs = environments.map((env) => (env.alternateId === defaultEnvironment.alternateId ? envUpdated : env));

    const { data, error } = await updateOrganization({
      id: organization.id,
      data: {
        name: organization.name,
        defaultEnvironments: envs,
      },
    });

    if (!error && data) {
      onClose();
      toast.success('Environment updated successfully');
    }
  };

  const handleDelete = async (defaultEnvironment: EnvironmentDataType) => {
    if (!organization) return;

    const envsFiltered: EnvironmentDataType[] = environments.filter(
      (env) => env.alternateId !== defaultEnvironment.alternateId,
    );
    const { data, error } = await updateOrganization({
      id: organization.id,
      data: {
        name: organization.name,
        defaultEnvironments: envsFiltered,
      },
    });

    if (!error && data) {
      toast.success('Environment deleted successfully');
    }
  };

  return (
    <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Environments</title>
      </Helmet>
      {isLoading && <Spinner />}
      <PageHeader
        title="Default Environments"
        description="Environments that get created when a new project is set up"
      />
      {canManageOrg && (
        <Button variant="primary" onClick={openCreateModal}>
          Add Default Environment
        </Button>
      )}
      {environments.length > 0 ? (
        <Card>
          {environments.map((env) => (
            <EnvironmentActionItem
              key={env.alternateId}
              defaultEnvironment={env}
              canManage={canManageOrg}
              onEdit={handleEdit}
              editMessage="Changes made here will be applied to projects created going forward"
              deleteMessage={`No longer create a ${env.name} environment for new projects?`}
              onDelete={handleDelete}
              isLoading={isUpdating}
              error={error}
            />
          ))}
        </Card>
      ) : (
        <EmptyList title="No Environments" description="Create an environment to get started" />
      )}

      <EnvironmentModal
        title="Add Default Environment"
        message="All future projects will create this environment."
        onSubmit={handleCreate}
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        error={error}
      />
    </Box>
  );
};

export default Environments;
