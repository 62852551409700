import { Box, Card, Heading, Pagination } from '@hyphen/hyphen-components';
import { AppItem } from './AppItem';
import { App } from '../../services/apps';
import { ListResponse } from '../../services/types';
import { Link } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';

interface EnvAppsListProps {
  apps: ListResponse<App>;
  pageNum?: number;
  setPageNumber?: (pageNum: number) => void;
  pageSize?: number;
}

export const AppsList = ({ apps, pageNum, pageSize, setPageNumber }: EnvAppsListProps) => {
  const appsData = apps?.data || [];

  const { organization = {} as Organization } = useOrganization();

  const groupedAppsByProject = Object.values(
    appsData.reduce((acc, app) => {
      const { name: projectName, alternateId: projectAlternateId } = app.project;

      if (!acc[projectName]) {
        acc[projectName] = {
          projectName,
          projectAlternateId,
          apps: [],
        };
      }

      acc[projectName].apps.push(app);
      return acc;
    }, {} as Record<string, { projectName: string; projectAlternateId: string; apps: App[] }>),
  ).sort((a, b) => a.projectName.localeCompare(b.projectName));

  return (
    <>
      <Box gap={{ base: '2xl', tablet: '5xl' }}>
        {groupedAppsByProject.map(({ projectName, projectAlternateId, apps: projectApps }) => (
          <Box gap="lg" direction={{ base: 'column', desktop: 'row' }} key={projectName}>
            {/* Project name with link to project detail page */}
            <Box width="7xl" padding="sm 0 0 0">
              <Heading as="h2" size="sm">
                <Link to={`/${organization.id}/${projectAlternateId}`}>{projectName}</Link>
              </Heading>
            </Box>
            <Box flex="auto" gap="2xl">
              {projectApps.map((app) => (
                <Card key={app.alternateId}>
                  <AppItem app={app} />
                </Card>
              ))}
            </Box>
          </Box>
        ))}
      </Box>

      {pageNum && pageSize && setPageNumber && appsData.length > 0 && (apps?.total ?? 0) > pageSize && (
        <Pagination
          activePage={pageNum}
          itemsPerPage={pageSize}
          onChange={setPageNumber}
          totalItemsCount={apps?.total ?? 0}
          isCompact
          arePagesVisible
          numberOfPagesDisplayed={3}
        />
      )}
    </>
  );
};
