import { SlackIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface SlackDetailsProps {
  integration: SlackIntegration;
}

export const SlackDetails = ({ integration }: SlackDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Workspace', value: integration.config.workspaceName },
      ]}
      integration={integration}
    />
  );
};

export default SlackDetails;
