import { Box, Button, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { Member } from '../../types/teams';
import { Field, Form, Formik } from 'formik';
import { InferType } from 'yup';
import * as yup from 'yup';
import { useUpdateMemberMutation } from '../../services/member';
import { ApiError } from '../ApiError';
import { useCallback } from 'react';

const editEditMemberForm = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  firstName: yup.string(),
  lastName: yup.string(),
});

export type EditAppSchema = InferType<typeof editEditMemberForm>;

const EditOrgMemberForm = ({ member }: { member: Member }) => {
  const initialValues = {
    email: member.email,
    firstName: member.firstName,
    lastName: member.lastName,
  };

  const [updateMember, { error }] = useUpdateMemberMutation();

  const handleOnSubmit = useCallback(
    async (values: EditAppSchema) => {
      const body = { email: values.email, firstName: values.firstName, lastName: values.lastName };

      try {
        const { error, data } = await updateMember({
          organizationId: member.organization.id,
          memberId: member.id,
          data: body,
        });
        if (!error && data) {
          toast.success('Member updated');
        }
      } catch (e) {
        toast.error(`Update member failed`);
      }
    },
    [member.id, member.organization.id, updateMember],
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={editEditMemberForm}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ errors, isSubmitting }) => (
        <Form noValidate>
          <Box gap="2xl">
            <Field
              type="text"
              label="Email"
              name="email"
              id="email"
              autoComplete="off"
              component={FormikTextInput}
              error={errors.email}
              isDisabled={isSubmitting}
            />
            <Box direction={{ base: 'column', tablet: 'row' }} gap="2xl">
              <Field
                type="text"
                label="First name"
                name="firstName"
                id="firstName"
                autoComplete="off"
                component={FormikTextInput}
                error={errors.firstName}
                isDisabled={isSubmitting}
              />
              <Field
                type="text"
                label="Last Name"
                name="lastName"
                id="lastName"
                autoComplete="off"
                component={FormikTextInput}
                error={errors.lastName}
                isDisabled={isSubmitting}
              />
            </Box>
            {error && <ApiError error={error} title="Error updating member" />}
            <Box display="block">
              <Button size="sm" variant="primary" type="submit" isLoading={isSubmitting}>
                Save
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default EditOrgMemberForm;
