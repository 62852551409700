import { cleanRequest } from '../utils/requestUtils';
import { api } from './api';
import { Environment } from './environments';
import { ListResponse, QueryParams } from './types';

export interface App {
  name: string;
  alternateId: string;
  id: string;
  project: {
    alternateId: string;
    name: string;
    id: string;
  };
  environments?: Environment[];
  organization: {
    id: string;
    name: string;
  };
  settings?: AppSettings;
}

export interface AppParams {
  appId: string;
  organizationId: string;
}

export interface CidrAllowDenyEntry {
  type: 'cidr';
  cidr: string;
  description: string;
}

export interface EnvAccessControls {
  allow?: Array<CidrAllowDenyEntry>;
  deny?: Array<CidrAllowDenyEntry>;
  environments?: {
    [projectEnvironmentAlternateId: string]: {
      allow?: Array<CidrAllowDenyEntry>;
      deny?: Array<CidrAllowDenyEntry>;
    };
  };
}

export interface AppSettings {
  env?: {
    accessControls?: EnvAccessControls;
  };
}

interface CreateAppRequestBody {
  name: string;
  alternateId: string;
  settings?: AppSettings;
}
interface AppEnvParams {
  organizationId: string;
  appId: string;
  envId: string;
}

export const appsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApps: builder.query<
      ListResponse<App>,
      { organizationId: string } & Pick<QueryParams, 'pageSize' | 'pageNum' | 'projectIds'>
    >({
      query: ({ pageSize, pageNum, organizationId, projectIds }) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (projectIds) {
          params.append('projectIds', projectIds.toString());
        }
        return {
          url: `api/organizations/${organizationId}/apps/?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ alternateId: appId }) => ({ type: 'Apps' as const, id: appId })),
              { type: 'Apps', id: 'APP-LIST' },
            ]
          : [],
    }),
    getApp: builder.query<App, AppParams>({
      query: ({ appId, organizationId }) => ({
        url: `api/organizations/${organizationId}/apps/${appId}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Apps', id: result.alternateId }] : []),
    }),
    getAppEnvironment: builder.query<Environment, AppEnvParams>({
      query: ({ appId, organizationId, envId }) => ({
        url: `api/organizations/${organizationId}/apps/${appId}/environments/${envId}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Environments', id: result.id }] : []),
    }),
    createApp: builder.mutation<App, { organizationId: string; projectId: string; body: CreateAppRequestBody }>({
      query: ({ organizationId, projectId, body }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/apps/`,
        method: 'POST',
        body: cleanRequest(body),
      }),
      invalidatesTags: (_result, _error) => ([{ type: 'Apps', id: 'APP-LIST' }]),
    }),
    deleteApp: builder.mutation<void, { appId: string; organizationId: string }>({
      query: ({ appId, organizationId }) => ({
        url: `api/organizations/${organizationId}/apps/${appId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { appId }) => [
        { type: 'Apps', id: appId },
        { type: 'Apps', id: 'APP-LIST' },
      ],
    }),
    updateAppName: builder.mutation<
      App,
      { organizationId: string; appId: string; body: Pick<CreateAppRequestBody, 'name'> }
    >({
      query: ({ organizationId, appId, body }) => ({
        url: `api/organizations/${organizationId}/apps/${appId}`,
        method: 'PATCH',
        body: cleanRequest(body),
      }),
      invalidatesTags: (_result, _error, { appId }) => [
        { type: 'Apps', id: appId },
        { type: 'Apps' as const, appId },
      ],
    }),
    updateAppSettings: builder.mutation<
      App,
      { organizationId: string; appId: string; body: Partial<Pick<App, 'settings'>> }
    >({
      query: ({ organizationId, appId, body }) => ({
        url: `api/organizations/${organizationId}/apps/${appId}`,
        method: 'PATCH',
        body: cleanRequest(body),
      }),
      invalidatesTags: (_result, _error, { appId }) => [
        { type: 'Apps', id: appId },
        { type: 'Apps' as const, appId },
      ],
    }),
  }),
});

export const {
  useGetAppsQuery,
  useGetAppQuery,
  useGetAppEnvironmentQuery,
  useCreateAppMutation,
  useUpdateAppNameMutation,
  useUpdateAppSettingsMutation,
  useDeleteAppMutation,
} = appsApi;
