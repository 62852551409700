import { BadgeProps, useOpenClose, Popover, Box, Badge } from '@hyphen/hyphen-components';

export interface BadgePopoverProps extends BadgeProps {
  popoverMessage: string;
}

export default function BadgePopover({ popoverMessage, ...badgeProps }: BadgePopoverProps) {
  const { isOpen: isPopoverOpen, handleClose: closePopover, handleOpen: openPopover } = useOpenClose();

  return (
    <Popover
      isOpen={isPopoverOpen}
      content={popoverMessage}
      contentContainerProps={{
        background: 'inverse',
        color: 'inverse',
        fontSize: 'xs',
        fontWeight: 'medium',
        padding: 'md',
        maxWidth: '9xl',
      }}
      placement="top"
      onClickOutside={closePopover}
    >
      <Box
        as="button"
        type="button"
        borderWidth="0"
        padding="0"
        onMouseOver={openPopover}
        onMouseOut={closePopover}
        onFocus={openPopover}
        onBlur={closePopover}
        background="transparent"
        tabIndex="-1"
      >
        <Badge {...badgeProps} />
      </Box>
    </Popover>
  );
}
