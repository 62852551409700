import { Helmet } from 'react-helmet';
import { EditAppForm } from '../../../components/app/EditApp';
import DeleteApp from '../../../components/app/DeleteApp';
import { App } from '../../../services/apps';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';

export const AppSettings = ({ app }: { app: App }) => {
  const ability = useOrganizationAbilityContext();
  const canUpdateApp = ability.can('update', app);

  if (canUpdateApp)
    return (
      <>
        <Helmet title={`${app?.name} Settings`} />
        <EditAppForm app={app} />
        <DeleteApp app={app} />
      </>
    );

  return (
    <>
      <Helmet title={`${app?.name} Settings`} />
      <p>You do not have permission</p>
    </>
  );
};

export default AppSettings;
