import { Box } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Project } from '../../services/projects';
import ClickToCopy from '../common/ClickToCopy';

export type ProjectListItemProps = {
  project: Project;
};

const ProjectListItem = ({ project }: ProjectListItemProps) => {
  const { name, alternateId } = project;

  const { organization } = useOrganization();

  return (
    <Box className="row-item" borderWidth="sm 0 0 0" borderColor="default">
      <Box direction="row" gap="5xl" padding="3xl" alignItems="center" justifyContent="space-between">
        <Box gap="sm" flex="auto" alignItems="flex-start">
          <Box fontSize="md" fontWeight="bold" display="block">
            <Link to={`/${organization?.id}/${alternateId}`}>{name}</Link>
          </Box>
          <ClickToCopy text={alternateId} fontSize="xs" />
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectListItem;
