import {
  BadgeVariant,
  DropdownMenuGroup,
  DropdownMenuItem,
  Icon,
  toast,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { ConfirmModal } from '../../common/ConfirmModal';
import { useCallback } from 'react';
import { Organization } from '../../../services/organization';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../../auth/OrganizationAbilityProvider';
import { Connection } from '../../../types/connections';
import { useDeleteConnectionMutation } from '../../../services/connections';
import { CONNECTION_NAME_MAP, INTEGRATION_NAME_MAP } from '../../../constants/integrations';
import { ConnectionLink } from './ConnectionLink';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { ListItem } from '../../ListItem';

interface ConnectionItemProps {
  connection: Connection;
}

export const ConnectionItem = ({ connection }: ConnectionItemProps) => {
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const [deleteConnection, { isLoading: isDeleting, error }] = useDeleteConnectionMutation();
  const { organization = {} as Organization } = useOrganization();

  const handleDelete = useCallback(async () => {
    const { error } = await deleteConnection({
      organizationId: organization.id,
      integrationId: connection.organizationIntegration.id,
      connectionId: connection.id,
    });
    if (!error) {
      toast.success('Connection removed!');
      closeDeleteModal();
    }
  }, [deleteConnection, organization.id, connection.organizationIntegration.id, connection.id, closeDeleteModal]);

  const ability = useOrganizationAbilityContext();
  const canManageThisConnection = ability.can('manage', connection);
  const canManageAnyConnection = ability.can('manage', EntityNames.IntegrationConnection);

  const badgeVariant: BadgeVariant =
    connection.status === 'Ready' ? 'green' : connection.status === 'Pending' ? 'yellow' : 'red';
  const badgeMessage =
    connection.status === 'Ready' ? 'Connected' : connection.status === 'Pending' ? 'Pending' : 'Error';

  return (
    <>
      <ListItem
        title={{
          label: INTEGRATION_NAME_MAP[connection.organizationIntegration.type],
          link: `/${organization.id}/integrations/${connection.organizationIntegration.id}`,
        }}
        subtitle={CONNECTION_NAME_MAP[connection.type]}
        infoText={<ConnectionLink connection={connection} />}
        badge={{ variant: badgeVariant, popoverMessage: badgeMessage, message: badgeMessage, size: 'sm' }}
        dropdown={
          canManageThisConnection ? (
            <DropdownMenuGroup>
              <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                <Icon name="c-remove" /> <span>Delete</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          ) : null
        }
        reserveDropdownBtnSpace={canManageAnyConnection}
      />
      <ConfirmModal
        message={`Are you sure you want to delete this connection?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete Connection`}
        error={error}
      />
    </>
  );
};
