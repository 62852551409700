import { Box, Card, Pagination, Spinner } from '@hyphen/hyphen-components';
import { useGetMembersQuery } from '../../services/member';
import { useCallback } from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import useQueryStringState from '../../hooks/useQueryStringState';
import OrgMemberItem from './OrgMemberItem';
import { ApiError } from '../ApiError';
import EmptyList from '../common/EmptyList';
import { Outlet } from 'react-router-dom';

export const MemberList = () => {
  const { organization = {} as Organization } = useOrganization();
  const [qsState, setQsState] = useQueryStringState({ pageNum: 1, pageSize: 50 });

  const {
    data: members,
    error,
    isLoading,
  } = useGetMembersQuery({
    organizationId: organization.id,
    pageNum: qsState.pageNum,
    pageSize: qsState.pageSize,
  });

  const handlePageNumberChange = useCallback(
    (pageNum: number) => {
      setQsState({ pageNum, pageSize: qsState.pageSize });
    },
    [qsState.pageSize, setQsState],
  );

  const orgMembers = members?.data || [];
  const hasPagination = members?.total && members.total > qsState.pageSize;

  if (isLoading) {
    return <Spinner />;
  }

  if (!isLoading && error) return <ApiError error={error} />;

  return (
    <Box width="100" maxWidth={{ desktop: '9xl' }} gap="lg">
      {orgMembers.length ? (
        <Card>
          {orgMembers.map((member) => (
            <OrgMemberItem key={member.id} member={member} organization={organization} />
          ))}
          {hasPagination && (
            <Box padding="2xl" borderWidth="sm 0 0 0">
              <Pagination
                activePage={qsState.pageNum}
                itemsPerPage={qsState.pageSize}
                onChange={handlePageNumberChange}
                totalItemsCount={members?.total ?? 0}
                numberOfPagesDisplayed={3}
                isCompact
                arePagesVisible
              />
            </Box>
          )}
        </Card>
      ) : (
        <EmptyList title="No members" iconName="users" />
      )}
      <Outlet />
    </Box>
  );
};
