import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { useOrganization } from '../../providers/OrganizationProvider';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { CreateAppForm } from '../../components/env/CreateAppForm';
import { Organization } from '../../services/organization';

export const CreateApp = () => {
  const { organization = {} as Organization } = useOrganization();

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>Create App</title>
      </Helmet>
      <PageHeader title="Create App" />
      <CreateAppForm organizationId={organization.id} />
    </ScrollableMainLayout>
  );
};

export default CreateApp;
