import React from 'react';
import { Box } from '@hyphen/hyphen-components';

function getInitials(name: string) {
  var initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g) || ['T', 'A'];
  return initials.slice(0, 2).join('');
}

export const OrganizationAvatar = ({ name, logoUrl }: { name: string, logoUrl?: string }) => {
  return logoUrl ?
    (<Box
      width="24px"
      minWidth="24px"
      height="24px"
      alignItems="center"
      justifyContent="center"
      color="inverse"
      fontSize="xs"
      fontWeight="bold"
      style={{ lineHeight: '1', textTransform: 'uppercase' }}
    >
      <img src={logoUrl} alt={`logo for the ${name} organization`} />
    </Box>)
    :
    (<Box
      width="24px"
      minWidth="24px"
      height="24px"
      radius="sm"
      background="inverse"
      alignItems="center"
      justifyContent="center"
      color="inverse"
      fontSize="xs"
      fontWeight="bold"
      style={{ lineHeight: '1', textTransform: 'uppercase' }}
    >
      {getInitials(name)}
    </Box>)
};
