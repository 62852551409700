import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
  SidebarMenuButton,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { OrganizationAvatar } from '../components/organization/OrganizationAvatar';
import { Organization } from '../services/organization';
import { routes } from '..';

interface OrganizationSwitcherProps {
  organizations: Organization[];
  currentOrg: Organization;
  onOrgSelect: () => void;
}

const OrganizationSwitcher: React.FC<OrganizationSwitcherProps> = ({ organizations, currentOrg, onOrgSelect }) => {
  const hasMultipleOrgs = organizations?.length > 1;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SidebarMenuButton>
          <Box flex="auto" direction="row" gap="sm" alignItems="center">
            <OrganizationAvatar name={currentOrg.name || 'Unknown Organization'} logoUrl={currentOrg.logoUrl} />
            <span className="font-weight-semibold">{currentOrg.name}</span>
          </Box>
          <Icon name="caret-up-down" />
        </SidebarMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" side="bottom" sideOffset={4}>
        <DropdownMenuLabel>Organizations</DropdownMenuLabel>
        {hasMultipleOrgs && (
          <Box overflow="auto" maxHeight="8xl" className="scroll-bar-thin">
            {organizations.map((org) => (
              <DropdownMenuItem asChild key={org.id} onSelect={onOrgSelect}>
                <Link to={`/${org.id}`} className="navlink">
                  <OrganizationAvatar name={org.name} logoUrl={org.logoUrl} />
                  <span>{org.name}</span>
                  {org.id === currentOrg.id && <Icon name="check" size="sm" />}
                </Link>
              </DropdownMenuItem>
            ))}
          </Box>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link to={`/${routes.getStarted}`} className="navlink">
            <Icon name="add" />
            <span>Create Organization</span>
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default OrganizationSwitcher;
