import { AwsIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface AwsDetailsProps {
  integration: AwsIntegration;
}

export const AwsDetails = ({ integration }: AwsDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Management Account Id', value: integration.config.managementAccountId },
        { label: 'Management Account Name', value: integration.config.name },
        { label: 'Default Region', value: integration.config.region },
      ]}
      integration={integration}
    />
  );
};

export default AwsDetails;
