import { Button, Modal, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback, useState } from 'react';
import { ApiKeySecret, useCreateApiKeySecretMutation } from '../../services/apiKeys';
import { DisplaySecretKey } from './DisplaySecretKey';

export default function CreateApiKeySecret({
  organizationId,
  apiKeyId,
}: {
  organizationId: string;
  apiKeyId: string;
}) {
  const { isOpen: isCreateModalOpen, handleOpen: openCreateModal, handleClose: closeCreateModal } = useOpenClose();

  const closeModal = useCallback(() => {
    closeCreateModal();
    setSecret(undefined);
  }, [closeCreateModal]);

  const [secret, setSecret] = useState<ApiKeySecret>();

  const [createApiKeySecret, { isLoading: isCreating }] = useCreateApiKeySecretMutation();

  const handleCreate = useCallback(async () => {
    try {
      const result = await createApiKeySecret({ orgId: organizationId, apiKeyId });
      if (!result.error) {
        result.data && setSecret(result.data);
        openCreateModal();
      } else {
        console.error(`Failed to create secret`, result.error);
        toast.error(`Failed to create secret`);
      }
    } catch (e) {
      console.error(`Failed to create secret`, e);
      toast.error(`Failed to create secret`);
    }
  }, [createApiKeySecret, organizationId, apiKeyId, openCreateModal]);

  return (
    <div>
      <Button variant="primary" onClick={handleCreate} isLoading={isCreating}>
        Create Secret
      </Button>
      <Modal ariaLabel="Show Secret" isOpen={isCreateModalOpen} onDismiss={closeModal} maxWidth="9xl">
        <Modal.Body gap="2xl">
          <DisplaySecretKey secret={secret?.value || ''} />
        </Modal.Body>
        <Modal.Footer>
          <Button fullWidth variant="secondary" onClick={closeModal}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
