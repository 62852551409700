import { Box, Button } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { CodeBlock } from '../../../Codeblock';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { GoogleWorkspaceSetupForm } from './GoogleWorkspaceSetupForm';

interface GoogleCloudSetupGuideProps {
  organizationId: string;
}

const ServiceAccountId = process.env.REACT_APP_GCP_SERVICE_ACCOUNT_ID || '';

export const GoogleWorkspaceSetupGuide = ({ organizationId }: GoogleCloudSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Create or sign into your Google Workspace account">
        <p>
          <Button as="a" href="https://admin.google.com" target="_blank" iconSuffix="launch-app">
            Sign in to Google Admin Console
          </Button>
        </p>
        <p>or</p>
        <p>
          <Button as="a" href="https://workspace.google.com/" target="_blank" iconSuffix="launch-app">
            Create a new Google Workspace
          </Button>
        </p>
      </GuideStep>
      <GuideStep title="Authorize a service account for Hyphen to your Google Workspace">
        <Box as="ol" childGap="2xl" padding="0 0 0 2xl">
          <li>
            <p>
              In the Admin console, go to <strong>Menu</strong> &gt; <strong>Security</strong> &gt;{' '}
              <strong>Access and data control</strong> &gt;{' '}
              <strong>API controls &gt; Manage Domain Wide Delegation</strong>.
            </p>
            <p>
              <em>You must be signed in as a super administrator for this task</em>.
            </p>
          </li>
          <li>
            Click <strong>Add new</strong>.
          </li>
          <li>
            <p>Enter the service account ID for either the service account or the OAuth2 client.</p>

            <CodeBlock>{ServiceAccountId}</CodeBlock>
          </li>
          <li>
            <p>
              In OAuth Scopes, add the following OAuth scopes and click <strong>Authorize</strong>.
            </p>
            <p>
              <CodeBlock>
                https://www.googleapis.com/auth/admin.directory.group,https://www.googleapis.com/auth/admin.directory.group.member,https://www.googleapis.com/auth/admin.directory.user.alias.readonly,https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly,https://www.googleapis.com/auth/admin.directory.group.member.readonly,https://www.googleapis.com/auth/admin.directory.group.readonly,https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.domain.readonly,https://www.googleapis.com/auth/admin.reports.audit.readonly,https://www.googleapis.com/auth/apps.groups.settings
              </CodeBlock>
            </p>
          </li>
          <li>
            <p>
              Point to the new client ID, click View details, and make sure that every scope is listed. If a scope is
              not listed, click Edit, enter the missing scope, and click Authorize. You can't edit the client ID.
            </p>
          </li>
        </Box>
        <p>
          More detailed information can be found at the Google Workspace documentation for{' '}
          <Link
            to="https://support.google.com/a/answer/162106?hl=en#zippy=%2Cset-up-domain-wide-delegation-for-a-client"
            target="_blank"
          >
            Setting up domain-wide delegation for a client
          </Link>
          .
        </p>
        <p>Changes can take up to 24 hours but typically happen more quickly.</p>
      </GuideStep>
      <GuideStep title="Connect Workspace">
        <p>Enter your workspace domain and admin user email to connect your Google Workspace account to Hyphen.</p>
        <GoogleWorkspaceSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
