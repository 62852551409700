import { useMemo } from 'react';
import { Box } from '@hyphen/hyphen-components';
import { App } from '../../services/apps';
import EnvAccessControlsList from './EnvAccessControlsList';

interface Props {
  app: App;
  isDefault: boolean;
  environmentAlternateId?: string;
}

function EnvironmentEnvAccessControls({ app, isDefault, environmentAlternateId }: Props) {
  const defaultAllowDenyRules = useMemo(() => {
    if (app.settings?.env?.accessControls) {
      return {
        allow: app.settings.env.accessControls.allow || [],
        deny: app.settings.env.accessControls.deny || [],
      };
    }

    return { allow: [], deny: [] };
  }, [app]);

  const allowDenyRules = useMemo(() => {
    if (isDefault) {
      return defaultAllowDenyRules;
    } else {
      if (!environmentAlternateId) {
        return { allow: [], deny: [] };
      }
      return app.settings?.env?.accessControls?.environments?.[environmentAlternateId] || { allow: [], deny: [] };
    }
  }, [isDefault, defaultAllowDenyRules, environmentAlternateId, app]);

  return (
    <Box gap={{ base: '2xl', tablet: '4xl' }}>
      <Box as="p" color="secondary">
        Control which IP addresses can access the environment secrets
      </Box>

      <EnvAccessControlsList
        app={app}
        isDefault={isDefault}
        environmentAlternateId={environmentAlternateId}
        type="allow"
        accessControls={allowDenyRules.allow || []}
        defaultRules={defaultAllowDenyRules.allow || []}
      />

      <EnvAccessControlsList
        app={app}
        isDefault={isDefault}
        environmentAlternateId={environmentAlternateId}
        type="deny"
        accessControls={allowDenyRules.deny || []}
        defaultRules={defaultAllowDenyRules.deny || []}
      />
    </Box>
  );
}

export default EnvironmentEnvAccessControls;
