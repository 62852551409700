import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { GoogleCloudIntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface GoogleCloudSetupFormProps {
  organizationId: string;
}

const googleCloudSetupFormSchema = yup.object().shape({
  googleCloudOrganizationId: yup.string().required('Required'),
});

const googleCloudFormConfig = [
  { id: 'googleCloudOrganizationId', type: 'text', label: 'Google Cloud Organization ID', initialValue: '' },
];

export const GoogleCloudSetupForm = ({ organizationId }: GoogleCloudSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={googleCloudSetupFormSchema}
    formConfig={googleCloudFormConfig}
    configMapper={(values: GoogleCloudIntegrationConfig) => ({
      googleCloudOrganizationId: values.googleCloudOrganizationId,
    })}
    integrationType={IntegrationType.googleCloud}
  />
);
