import { Box, Button, Card, Heading } from '@hyphen/hyphen-components';
import { Outlet } from 'react-router-dom';

import { useOrganization } from '../../providers/OrganizationProvider';
import { TeamMembersList } from '../../components/teams/TeamMembersList';
import { Organization } from '../../services/organization';
import DynamicTeamCriteria from '../../components/teams/DynamicTeamCriteria';
import { TeamDetails } from '../../types/teams';
import { Link } from 'react-router-dom';

export const TeamDetail = ({ team }: { team: TeamDetails }) => {
  const { organization = {} as Organization } = useOrganization();

  return (
    <>
      {team.channels?.length! > 0 && (
        <Box gap="2xl">
          <Heading as="h2" size="sm">
            Channels <span className="font-size-md font-color-secondary">({team.channels!.length})</span>
          </Heading>
          <div>
            {team.channels?.map((channel) => (
              <span key={channel.id}>
                {channel.type} - {channel.id},{' '}
              </span>
            ))}
          </div>
        </Box>
      )}

      <Card fontSize="sm">
        <Card.Section>
          <Box direction="row" gap="2xl" justifyContent="space-between" alignItems="center">
            {team.type === 'dynamic' ? (
              <DynamicTeamCriteria criteria={team.criteria || []} />
            ) : (
              <Box gap="2xs">
                <Heading as="h4" size="sm">
                  Team Members
                </Heading>
              </Box>
            )}

            {team?.type === 'static' && (
              // @ts-ignore
              <Button as={Link} to="add" size="sm">
                Add member
              </Button>
            )}
          </Box>
        </Card.Section>
        <Card.Section padding="0">
          <TeamMembersList organizationId={organization?.id || ''} teamId={team.id} teamType={team.type} />
        </Card.Section>
      </Card>

      <Outlet />
    </>
  );
};

export default TeamDetail;
