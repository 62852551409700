import { useState, useCallback, useEffect } from 'react';
import { toast } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import {
  CreateBillingAccountFormSchema,
  CreateBillingAccountForm,
} from '../../components/billing/CreateBillingAccountForm';
import { useNavigate } from 'react-router-dom';
import { routes } from '../..';
import { useCreateOrganizationAndBillingAccountMutation } from '../../services/organization';

export function AccountCreate() {
  const [billingAccountId, setBillingAccountId] = useState<string | undefined>(undefined);
  const [createOrganization, { error }] = useCreateOrganizationAndBillingAccountMutation();
  const navigate = useNavigate();

  const handleCompanySubmit = useCallback(
    async (values: CreateBillingAccountFormSchema, { setSubmitting }: any) => {
      const { data, error } = (await createOrganization({
        name: values.name,
        billingAccount: {
          name: values.name,
          email: values.email,
          phone: values.phone,
          address: {
            streetAddress: values.address.streetAddress,
            locality: values.address.city,
            region: values.address.state,
            postalCode: values.address.zipCode,
            country: 'US',
          },
        },
      })) as any;
      if (!error && data) {
        setBillingAccountId(data.billingAccount.id);
        toast.success(`${values.name} created`, { position: 'bottom-center' });
      }
      setSubmitting(false);
    },
    [createOrganization],
  );

  useEffect(() => {
    if (billingAccountId) {
      navigate(`/${routes.getStarted}/${billingAccountId}`);
    }
  }, [billingAccountId, navigate]);

  return (
    <>
      <Helmet>
        <title>Set up your organization</title>
      </Helmet>
      <CreateBillingAccountForm handleSubmit={handleCompanySubmit} error={error} />
    </>
  );
}
