import { Box, Button, FormikTextInput, Modal, toast } from '@hyphen/hyphen-components';
import { Formik, Field, Form } from 'formik';
import { ApiError } from '../ApiError';
import { useCallback, useRef } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useCreateMemberMutation } from '../../services/member';
import { useNavigate } from 'react-router-dom';
import { Organization } from '../../services/organization';

const inviteMemberSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

type InviteMemberSchema = InferType<typeof inviteMemberSchema>;

const errorMessages = {
  default: 'Failed to invite member',
};

export const InviteMemberModal = ({ organization }: { organization: Organization }) => {
  const [createMember, { error }] = useCreateMemberMutation();
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleDismiss = useCallback(() => {
    navigate(`/${organization.id}/settings/members`);
  }, [navigate, organization.id]);

  const handleOnSubmit = useCallback(
    async (values: InviteMemberSchema) => {
      const { error, data: responseData } = await createMember({
        email: values.email,
        organizationId: organization.id,
      });

      if (!error && responseData) {
        toast.success('Member Invited', { duration: 5000 });
        handleDismiss();
      } else if (error) {
        toast.error('Invite member failed');
      }
    },
    [createMember, organization.id, handleDismiss],
  );

  return (
    <Modal
      ariaLabelledBy="inviteMemberModal"
      isOpen
      onDismiss={handleDismiss}
      maxWidth="9xl"
      containerRef={containerRef}
    >
      <Formik initialValues={{ email: '' }} validationSchema={inviteMemberSchema} onSubmit={handleOnSubmit}>
        {({ isSubmitting, errors }) => (
          <Form noValidate>
            <Box gap={{ base: '2xl', tablet: '4xl' }}>
              <Modal.Header id="inviteMemberModal" title="Invite Member" onDismiss={handleDismiss} />
              <Modal.Body gap="xl">
                <Field
                  id="email"
                  name="email"
                  label="Invite by email"
                  component={FormikTextInput}
                  error={errors.email}
                />
                {error && <ApiError error={error} customMessages={errorMessages} />}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDismiss} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Invite
                </Button>
              </Modal.Footer>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
