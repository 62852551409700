import { Box } from '@hyphen/hyphen-components';

export interface LabelValueProps {
  label: string;
  value: React.ReactNode;
}

export const LabelValue = ({ label, value }: LabelValueProps) => (
  <Box direction={{ base: 'column', tablet: 'row' }} gap={{ base: 'xs', tablet: 'lg' }}>
    <Box color="secondary" width={{ tablet: '7xl' }}>
      {label}
    </Box>
    <Box>{value}</Box>
  </Box>
);
