import { Box, Card, Spinner } from '@hyphen/hyphen-components';
import { ConnectionType } from '../../../types/connections';
import { EntityType } from '../../../types/executionContext';
import { useGetConnectionsQuery } from '../../../services/connections';
import EmptyList from '../../common/EmptyList';
import { ConnectionItem } from './ConnectionItem';
import { ConnectionAddButton } from './ConnectionAddButton';
import { useEffect, useState } from 'react';

interface ConnectionsListProps {
  organizationId: string;
  filter?: {
    type?: ConnectionType;
    entityType?: EntityType;
    entity?: {
      type: EntityType;
      id: string;
      name: string;
    };
    project?: {
      id: string;
      name: string;
    };
  };
}

export const ConnectionsList = ({ organizationId, filter }: ConnectionsListProps) => {
  const [pollingInterval, setPollingInterval] = useState(0);

  const { data: connections, isLoading: isConnectionsLoading } = useGetConnectionsQuery(
    {
      organizationId,
      entityIds: filter?.entity?.id ? [filter?.entity?.id] : undefined,
    },
    { pollingInterval },
  );

  useEffect(() => {
    if (
      connections &&
      connections.data.length &&
      connections.data.some((connection) => connection.status === 'Pending')
    ) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(0);
    }
  }, [setPollingInterval, connections]);

  if (isConnectionsLoading || !connections) {
    return <Spinner />;
  }

  return (
    <>
      <Box gap="md" direction="row" alignItems="center">
        <ConnectionAddButton organizationId={organizationId} entity={filter?.entity} />
      </Box>
      {connections.data.length > 0 ? (
        <Card>
          {connections.data.map((connection) => (
            <ConnectionItem key={connection.id} connection={connection} />
          ))}
        </Card>
      ) : (
        <EmptyList title="No Connections" description="Create a connection to get started" />
      )}
    </>
  );
};
