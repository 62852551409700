import { Button, toast, useOpenClose } from '@hyphen/hyphen-components';
import { useCallback } from 'react';
import { ConfirmModal } from '../common/ConfirmModal';
import { useRevokeAccessMutation } from '../../services/access';

export default function RevokeAccess({
  organizationId,
  accessId,
  projectId,
  name,
}: {
  organizationId: string;
  accessId: string;
  projectId: string;
  name: string;
}) {
  const { isOpen: isRemoveModalOpen, handleOpen: openRemoveModal, handleClose: closeRemoveModal } = useOpenClose();

  const [revokeAccess, { isLoading: isRevoking }] = useRevokeAccessMutation();

  const handleRemove = useCallback(
    async (accessId: string) => {
      const { error } = await revokeAccess({
        organizationId: organizationId,
        accessId: accessId,
        entity: {
          type: 'Project',
          id: projectId,
        },
      });
      if (error) {
        toast.error('unable to revoke Access', { duration: 5000 });
      } else {
        toast.success('access revoked', { duration: 2000 });
        closeRemoveModal();
      }
    },
    [organizationId, projectId, closeRemoveModal, revokeAccess],
  );

  return (
    <>
      <ConfirmModal
        message={
          <>
            Revoke <strong>{name}&rsquo;s</strong> access?
          </>
        }
        confirmButtonLabel="Revoke Access"
        isOpen={isRemoveModalOpen}
        onClose={closeRemoveModal}
        onConfirm={() => handleRemove(accessId)}
        isLoading={isRevoking}
      />
      <Button variant="tertiary" iconPrefix="c-remove" aria-label="delete secret" onClick={openRemoveModal} />
    </>
  );
}
