import { Field, FieldAttributes, useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

interface DependentFormikFieldProps extends FieldAttributes<any> {
  dependentField: string;
  targetField: string;
  generateValue: (dependentValue: string) => string;
  name: string;
}

export const DependentFormikField = ({
  dependentField,
  targetField,
  generateValue,
  ...props
}: DependentFormikFieldProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [field] = useField(props);
  const dependentValue = values[dependentField];

  useEffect(() => {
      if (dependentValue && values[targetField] !== generateValue(dependentValue)) {
        setFieldValue(targetField, generateValue(dependentValue.trim()));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependentValue, setFieldValue]);

  return (
    <Field {...props} {...field} />
  )
}