import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { Button } from '@hyphen/hyphen-components';
import { IntegrationType } from '../../../../types/integrations';
import { ApiError } from '../../../ApiError';
import { useCreateIntegrationInviteMutation } from '../../../../services/integrations';

interface SlackSetupGuideProps {
  organizationId: string;
}

export const SlackSetupGuide = ({ organizationId }: SlackSetupGuideProps) => {
  const [CreateIntegrationInvite, { error, isLoading }] = useCreateIntegrationInviteMutation();

  const handleGetIntegrationInvite = async () => {
    const { data } = await CreateIntegrationInvite({ organizationId, integration: { type: IntegrationType.slack } });
    const url = data?.url;
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <Guide title="Setup Guide">
      {error ? <ApiError error={error} title="Error fetching invite link" /> : null}
      <GuideStep title="Add Hyphen to your Slack workspace">
        <Button isLoading={isLoading} variant="primary" onClick={handleGetIntegrationInvite} iconSuffix="launch-app">
          Install the App
        </Button>
      </GuideStep>
    </Guide>
  );
};
