import { Box } from '@hyphen/hyphen-components';

const ComingSoon = ({ className }: { className?: string }) => (
  <Box
    background="brand-yellow"
    padding="xs md"
    radius="lg"
    fontSize="2xs"
    fontFamily="monospace"
    color="grey"
    display="inline-block"
    className={className}
  >
    COMING SOON
  </Box>
);

export default ComingSoon;
