export enum ProjectRoles {
  ProjectOwner = 'ProjectOwner',
  ProjectCollaborator = 'ProjectCollaborator',
  ProjectViewer = 'ProjectViewer',
}

export enum LinkCodeRoles {
  LinkCodeOwner = 'LinkCodeOwner',
}

export enum TeamRoles {
  TeamOwner = 'TeamOwner',
  TeamMember = 'TeamMember',
}

export enum OrganizationRoles {
  OrganizationAdmin = 'OrganizationAdmin',
  OrganizationMember = 'OrganizationMember',
}

export type Role = ProjectRoles | LinkCodeRoles | TeamRoles | OrganizationRoles;
