import { Box } from '@hyphen/hyphen-components';

export default function DynamicTeamCriteria({ criteria }: { criteria: any[] }) {
  const content = criteria.map((criteria, index: number) => (
    <Box key={index} fontSize="sm" direction="row" gap="xl" alignItems="center">
      {index > 0 && (
        <Box width="4xl" padding="0 lg" fontSize="sm" fontFamily="monospace" color="secondary">
          AND
        </Box>
      )}
      <Box
        padding={index === 0 && criteria!.length > 1 ? 'md md md 5xl' : 'md xl'}
        direction={{ base: 'column', tablet: 'row' }}
        flex="auto"
        gap="md"
        background="secondary"
        radius="md"
        fontFamily="monospace"
      >
        <Box>{criteria.type === 'property' ? criteria.field : criteria.type}</Box>
        <Box fontSize="sm" fontFamily="monospace" style={{ textTransform: 'uppercase' }}>
          {criteria.type === 'property'
            ? criteria.operator
            : criteria.conjunction === 'and'
            ? 'includes'
            : 'is either'}
        </Box>
        <Box flex="auto">{criteria.type === 'property' ? criteria.value : criteria.values.join(', ')}</Box>
      </Box>
    </Box>
  ));

  return (
    <Box flex="auto" gap="xl">
      <Box gap="2xs">
        <Box as="h4" fontSize={{ base: 'sm', tablet: 'md' }} fontWeight="medium">
          Team Membership Criteria
        </Box>
        <Box as="p" color="secondary" fontSize={{ base: 'xs', tablet: 'sm' }}>
          Membership for this team is automatically organized based on:
        </Box>
      </Box>
      <Box gap="sm">{content}</Box>
    </Box>
  );
}
