import { Box, Icon } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { EventList } from '../../components/events/EventList';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

const Events = () => {
  const ability = useOrganizationAbilityContext();
  const canReadEvents = ability.can('read', EntityNames.Event);

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>Event History</title>
      </Helmet>

      <PageHeader title="Events" description="All events across the organization" />

      {canReadEvents ? (
        <Box fontSize="sm">
          <EventList />
        </Box>
      ) : (
        <Box direction="row" gap="xs" alignItems="center">
          <Icon name="t-warning" color="warn" />
          You are not authorized to view organization wide events
        </Box>
      )}
    </ScrollableMainLayout>
  );
};

export default Events;
