import { Box, Button, Modal, useOpenClose, toast, FormikTextInput } from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { ApiKey, useCreateApiKeyMutation } from '../../services/apiKeys';
import { useCallback, useState } from 'react';
import { DisplaySecretKey } from './DisplaySecretKey';

const createApiKeySchema = yup.object().shape({
  name: yup.string().required('Name is required'),
});

type CreateApiKeySchema = InferType<typeof createApiKeySchema>;

const initialValues = {
  name: '',
};

export const CreateApiKey = ({ organizationId }: { organizationId: string }) => {
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [createApiKey] = useCreateApiKeyMutation();

  const [data, setData] = useState<ApiKey>();

  const handleCreateApiKey = useCallback(
    async (values: CreateApiKeySchema) => {
      try {
        const body = {
          name: values.name,
          orgId: organizationId,
        };
        const { error, data } = await createApiKey(body);
        if (!error) {
          setData(data);
        } else {
          console.error('Failed to create api key', error);
          toast.error('Failed to create api key');
        }
      } catch (e) {
        console.error(`Failed to create api key`, e);
        toast.error('Failed to create api key');
      }
    },
    [createApiKey, organizationId],
  );

  const resetAndCloseModal = useCallback(() => {
    closeModal();
    setData(undefined);
  }, [closeModal]);

  return (
    <div>
      <Modal maxWidth="9xl" ariaLabelledBy="createApiKeyHeader" isOpen={isModalOpen} onDismiss={closeModal}>
        <Formik initialValues={initialValues} validationSchema={createApiKeySchema} onSubmit={handleCreateApiKey}>
          {({ errors, isSubmitting }) => (
            <Form noValidate>
              <Box gap={{ base: '2xl', tablet: '4xl' }}>
                <Modal.Header
                  id="createApiKeyHeader"
                  title={data ? 'Save Your Secret Key' : 'Create API Key'}
                  onDismiss={closeModal}
                />
                <Modal.Body>
                  {data ? (
                    <Box gap="2xl">
                      <DisplaySecretKey secret={data?.secrets[0].value || ''} />
                    </Box>
                  ) : (
                    <Box gap="2xl">
                      <Field
                        id="name"
                        name="name"
                        label="Name"
                        component={FormikTextInput}
                        error={errors.name}
                        isRequired
                        autoFocus
                      />
                    </Box>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  {data ? (
                    <Button fullWidth variant="secondary" onClick={resetAndCloseModal}>
                      Done
                    </Button>
                  ) : (
                    <>
                      <Button variant="secondary" onClick={closeModal} isDisabled={isSubmitting}>
                        Cancel
                      </Button>
                      <Button variant="primary" isLoading={isSubmitting} type="submit">
                        Create
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
      <Button onClick={openModal}>Create API Key</Button>
    </div>
  );
};
