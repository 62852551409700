import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { GoogleWorkspaceIntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface GoogleWorkspaceSetupFormProps {
  organizationId: string;
}

const googleWorkspaceSetupFormSchema = yup.object().shape({
  domain: yup.string().required('Required'),
  adminUserEmail: yup.string().email().required('Required'),
});

const googleWorkspaceFormConfig = [
  { id: 'domain', type: 'text', label: 'Domain', initialValue: '' },
  { id: 'adminUserEmail', type: 'email', label: 'Admin User Email', initialValue: '' },
];

export const GoogleWorkspaceSetupForm = ({ organizationId }: GoogleWorkspaceSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={googleWorkspaceSetupFormSchema}
    formConfig={googleWorkspaceFormConfig}
    configMapper={(values: GoogleWorkspaceIntegrationConfig) => ({
      domain: values.domain,
      adminUserEmail: values.adminUserEmail,
    })}
    integrationType={IntegrationType.googleWorkspace}
  />
);
