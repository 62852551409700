import { Box, Heading } from '@hyphen/hyphen-components';
import { ReactNode } from 'react';
import { ReactSVG } from 'react-svg';

interface PageHeaderProps {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  className?: string;
  eyebrow?: ReactNode;
  iconUrl?: string;
  [key: string]: any;
}

const PageHeader = ({ title, description, children, className, eyebrow, iconUrl, ...rest }: PageHeaderProps) => {
  return (
    <Box className={className} color="base" gap="xl" {...rest}>
      {eyebrow && (
        <Box display="block" fontSize="sm">
          {eyebrow}
        </Box>
      )}

      <Box direction="row" gap="xl">
        {iconUrl && (
          <Box
            background="primary"
            width="56px"
            minWidth="56px"
            height="56px"
            borderWidth="sm"
            borderColor="subtle"
            alignItems="center"
            justifyContent="center"
            shadow="xs"
            radius="md"
            padding="sm"
          >
            <ReactSVG
              src={iconUrl}
              beforeInjection={(svg) => svg.setAttribute('style', 'width: 100%; height: 100%')}
            />
          </Box>
        )}
        <Box alignItems="flex-start" gap="xs">
          {title && (
            <Heading as="h1" size={{ base: 'md', tablet: 'lg', desktop: 'xl' }}>
              {title}
            </Heading>
          )}
          {description && (
            <Box
              fontSize={{
                base: 'sm',
              }}
              color="secondary"
            >
              {description}
            </Box>
          )}
        </Box>
      </Box>

      {children && <Box display="block">{children}</Box>}
    </Box>
  );
};

export default PageHeader;
