import { Box, Heading } from '@hyphen/hyphen-components';
import { PropsWithChildren } from 'react';
import { GuideStepProps } from './GuideStep';

interface GuideProps extends PropsWithChildren<GuideStepProps> {
  title: string;
}

export const Guide = ({ title, children }: GuideProps) => (
  <Box gap="3xl" textAlign="left" fontSize="sm">
    <Heading as="h2" size="md">
      {title}
    </Heading>
    <Box as="ol" gap="5xl" className="steps-list">
      {children}
    </Box>
  </Box>
);
