import { api } from './api';

export interface PublicKey {
  id: string;
  key: string;
  name: string;
  organization: {
    id: string;
    name: string;
  };
  project: {
    id: string;
    name: string;
  };
}

export interface GetPublicKeysBody {
  organizationId?: string;
  projectId?: string;
}

export interface CreatePublicKeyBody {
  publicKey: {
    name: string;
    organizationId: string;
    projectId: string;
  };
}

export const publicKeysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublicKeys: builder.query<PublicKey[], GetPublicKeysBody>({
      query: ({ organizationId, projectId }) => {
        const params = new URLSearchParams();
        if (organizationId) params.append('organizationId', organizationId);
        if (projectId) params.append('projectId', projectId);
        return {
          url: `api/public-keys?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (data = []) => [
        ...data.map(({ id }) => ({ type: 'PublicKeys' as const, id })),
        { type: 'PublicKeys', id: 'LIST' },
      ],
    }),
    createPublicKey: builder.mutation<PublicKey, CreatePublicKeyBody>({
      query: ({ publicKey }) => ({
        url: `api/public-keys`,
        method: 'POST',
        body: publicKey,
      }),
      invalidatesTags: [{ type: 'PublicKeys', id: 'LIST' }],
    }),
    deletePublicKey: builder.mutation<void, { publicKeyId: string }>({
      query: ({ publicKeyId }) => ({
        url: `api/public-keys/${publicKeyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'PublicKeys', id: 'LIST' }],
    }),
    getPublicKey: builder.query<PublicKey, { publicKeyId: string }>({
      query: ({ publicKeyId }) => ({
        url: `api/public-keys/${publicKeyId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { publicKeyId }) => [{ type: 'PublicKeys', id: publicKeyId }],
    }),
  }),
});

export const {
  useGetPublicKeysQuery,
  useCreatePublicKeyMutation,
  useDeletePublicKeyMutation,
  useGetPublicKeyQuery,
} = publicKeysApi;
