import { Box, Table } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { useGetApiKeyQuery } from '../../services/apiKeys';
import { Link, useParams } from 'react-router-dom';
import DateTime from '../DateTime';
import DeleteApiKeySecret from './DeleteApiKeySecret';
import CreateApiKeySecret from './CreateApiKeySecret';
import DeleteApiKey from './DeleteApiKey';
import { DateCreated } from '../DateCreated';
import Error from '../../Error';
import EmptyList from '../common/EmptyList';

export default function ApiKeyDetails({ organizationId }: { organizationId: string }) {
  const { id = '' } = useParams();
  const { isLoading, error, data } = useGetApiKeyQuery({ orgId: organizationId, apiKeyId: id });

  const columnConfig = [
    {
      heading: 'Secret (last 6 chars)',
      dataKey: 'hint',
      render: (cell: any) => <code>{cell}</code>,
    },
    {
      heading: 'Last Used',
      dataKey: 'lastUsed',
      render: (cell: any) =>
        cell ? (
          <Box alignItems="flex-start" fontSize="sm">
            <DateTime value={cell} />
          </Box>
        ) : (
          <Box fontSize="sm">never</Box>
        ),
    },
    {
      heading: '',
      dataKey: 'id',
      render: (_cell: any, row: any) => (
        <Box alignItems="flex-end">
          <DeleteApiKeySecret apiKeyId={id} secret={row} organizationId={organizationId} />
        </Box>
      ),
    },
  ];

  if (error) return <Error />;

  return (
    <Box width="100" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>{`${data?.name} Api Key`}</title>
      </Helmet>
      <Box
        width="100"
        direction={{ base: 'column', tablet: 'row' }}
        gap={{ base: '2xl', tablet: '5xl' }}
        alignItems={{ tablet: 'center' }}
      >
        <PageHeader
          flex="auto"
          eyebrow={<Link to={`/${organizationId}/settings/api-keys`}>API Keys</Link>}
          title={data?.name}
        />

        <Box gap="md" direction="row" alignItems="center">
          <CreateApiKeySecret organizationId={organizationId} apiKeyId={id} />
          <DeleteApiKey organizationId={organizationId} apiKeyId={id} />
        </Box>
      </Box>
      {data?.createdAt && <DateCreated dateCreated={data?.createdAt} />}
      {data?.secrets.length ? (
        <Box width="100">
          <Table
            rowKey="id"
            columns={columnConfig}
            rows={data?.secrets || []}
            isLoading={isLoading}
            isScrollable={{
              x: true,
              y: false,
            }}
          />
        </Box>
      ) : (
        <EmptyList title="No secrets exist for this API key" />
      )}
    </Box>
  );
}
