import { Project } from '../../../services/projects';
import { Helmet } from 'react-helmet';
import ProjectAccessList from '../../../components/projects/ProjectAccessList';
import PublicKeyList from '../../../components/publicKeys/PublicKeyList';

export default function ProjectAccess({ project }: { project: Project }) {
  return (
    <>
      <Helmet title={`${project?.name} Access`} />
      <ProjectAccessList project={project} />
      <PublicKeyList projectId={project.id} projectName={project.name} organizationId={project.organization.id} />
    </>
  );
}
