import React, { PropsWithChildren, useMemo } from 'react';
import { Organization, useListOrganizationsQuery } from '../services/organization';
import { ErrorPage } from '../pages/Error';

export interface IOrganizationsListContext {
  isLoading: boolean;
  organizations: Organization[] | undefined;
  error?: any;
}

const OrganizationsListContext = React.createContext<IOrganizationsListContext>({
  isLoading: true,
  organizations: [],
  error: undefined,
});

export interface OrganizationsListProviderProps extends PropsWithChildren {}

export const OrganizationsListProvider = ({ children }: OrganizationsListProviderProps) => {
  const { isLoading, data, error } = useListOrganizationsQuery();

  const value = useMemo(() => {
    return { isLoading, organizations: data?.data, error };
  }, [isLoading, data, error]);

  if(!isLoading && error) {
    return <ErrorPage />;
  }

  //TODO don't return provider until organization list is set
  return <OrganizationsListContext.Provider value={value}>{children}</OrganizationsListContext.Provider>;
};

export const useOrganizationsList = () => {
  return React.useContext(OrganizationsListContext);
};
