import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { AzureIntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface AzureSetupFormProps {
  organizationId: string;
}

const azureSetupFormSchema = yup.object().shape({
  azureTenantId: yup.string().required('Required'),
});

const azureFormConfig = [
  { id: 'azureTenantId', type: 'text', label: 'Azure Tenant ID', initialValue: '' },
];

export const AzureSetupForm = ({ organizationId }: AzureSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={azureSetupFormSchema}
    formConfig={azureFormConfig}
    configMapper={(values: AzureIntegrationConfig) => ({
      azureTenantId: values.azureTenantId,
    })}
    integrationType={IntegrationType.azure}
  />
);
