import { FormikSelectInput } from "@hyphen/hyphen-components";
import { Field } from "formik";
import { useCallback, useState, useEffect } from "react";
import { useOrganization } from "../../providers/OrganizationProvider";
import { Organization } from "../../services/organization";
import { useLazyGetTeamsQuery } from "../../services/teams";
import { Team } from "../../types/teams";

interface SelectApiKeyMemberProps {
  isMulti?: boolean;
  portalTarget?: HTMLFormElement | null;
  label?: string;
  name?: string;
  error?: string | string[];
  helpText?: string;
}

export function createOptionsFromResults(teams: Team[]) {
  return (
    teams?.map((team) => ({
      value: team.id,
      label: `${team.name} (${team.memberCount})`,
    })) || []
  );
}

export function SelectTeam({
  label = 'Team',
  name = 'team',
  helpText,
  isMulti = false,
  error,
  portalTarget
}: SelectApiKeyMemberProps) {
  const { organization = {} as Organization } = useOrganization();

  const [trigger] = useLazyGetTeamsQuery();

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!inputValue) return [];

      const filteredTeams = await trigger({ organizationId: organization.id, name: inputValue }).unwrap();
      return createOptionsFromResults(filteredTeams?.data);
    },
    [organization.id, trigger],
  );

  const [defaultOptions, setDefaultOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      const teamsResult = await trigger({ organizationId: organization.id }).unwrap();
      setDefaultOptions(createOptionsFromResults(teamsResult?.data));
    };

    fetchMembers();
  }, [organization.id, trigger]);

  return (
    <Field
      label={label}
      helpText={helpText}
      name={name}
      id={name}
      options={loadOptions}
      component={FormikSelectInput}
      isMulti={isMulti}
      isCreatable={false}
      isAsync
      cacheOptions
      error={error}
      menuPortalTarget={portalTarget}
      defaultOptions={defaultOptions}
    />
  );
}
