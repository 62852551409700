import React, { ReactNode } from 'react';
import { Box, Icon } from '@hyphen/hyphen-components';
import { IconName } from '@hyphen/hyphen-components/dist/types';

const MainNavItem = ({
  iconName,
  children,
  isDisabled,
}: {
  iconName?: IconName;
  children: ReactNode;
  isDisabled?: boolean;
}) => {
  return (
    <Box
      direction="row"
      gap="md"
      alignItems="center"
      padding="sm"
      fontWeight="medium"
      {...(isDisabled ? {} : { hover: { background: 'tertiary' } })}
      radius="sm"
    >
      {iconName && (
        <Box width="24px" height="24px" justifyContent="center" alignItems="center">
          <Icon name={iconName} size="lg" color="disabled" />
        </Box>
      )}
      <span>{children}</span>
    </Box>
  );
};

export default MainNavItem;
