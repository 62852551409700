import { Box } from '@hyphen/hyphen-components';
import { ApiError } from '../ApiError';
import { ConfirmModal } from '../common/ConfirmModal';

const DeleteMemberConfirmationModal = ({
  memberEmail,
  organizationName,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  error,
}: {
  memberEmail: string;
  organizationName: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  error?: any;
}) => {
  return (
    <ConfirmModal
      message={
        <Box gap="lg">
          {memberEmail} will no longer have access to {organizationName} and its resources.
          {error && <ApiError error={error} />}
        </Box>
      }
      confirmButtonLabel="Delete Member"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
      title={`Delete Member ${memberEmail}?`}
    />
  );
};

export default DeleteMemberConfirmationModal;
