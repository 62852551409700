import { Box } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { ViewBillingAccount } from '../../components/billing/ViewBillingAccount';
import { ViewUsage } from '../../components/billing/ViewUsage';
import { TabNav, TabNavLink } from '../../components/TabNav';
import { Route, Routes } from 'react-router-dom';

export const Billing = () => {

  return (
    <Box width="100" maxWidth="9xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <PageHeader title="Billing" description="Manage your billing information and usage" />

      <TabNav>
        <TabNavLink to="">Overview</TabNavLink>
        <TabNavLink to="usage">Usage</TabNavLink>
      </TabNav>

      <Routes>
        <Route path="" element={<ViewBillingAccount />} />
        <Route path="usage" element={<ViewUsage />} />
      </Routes>

    </Box>
  );
};

export default Billing;
