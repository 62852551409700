import { cleanRequest, createFormData } from '../../utils/requestUtils';
import { api } from "../api";
import { ListResponse } from "../types";
import {
  Code,
  CodeStats,
  CreateCodeRequestBody,
  GetCodesQueryParams,
  QRCode,
  QRCodeRequestBody,
  UpdateCodeRequestBody,
} from '../../types/zelda';
import { addQueryParams } from "../../utils/addQueryParams";

export const codeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCode: builder.mutation<Code, { codeBody: CreateCodeRequestBody, organizationId: string }>({
      query: ({ codeBody, organizationId }) => ({
        url: `api/organizations/${organizationId}/link/codes`,
        method: 'POST',
        body: cleanRequest(codeBody),
      }),
      invalidatesTags: (_result, _error, { organizationId}) => [{ type: 'Codes', id: 'LIST' }, {type: 'OrganizationExecutionContext', id: organizationId}],
    }),
    getCodes: builder.query<ListResponse<Code>, GetCodesQueryParams>({
      query: ({ pageSize, pageNum, tags, search, organizationId } = {}) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());
        if (tags && tags.length) params.append('tags', tags.join(','));
        if (search && search.trim().length) params.append('search', search.trim());

        return {
          url: `api/organizations/${organizationId}/link/codes?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result?.data
          ? [...result.data.map(({ id }) => ({ type: 'Codes' as const, id })), { type: 'Codes', id: 'LIST' }]
          : [],
    }),
    getCode: builder.query<Code, {id: string, organizationId?: string}>({
      query: ({id, organizationId}) => `api/organizations/${organizationId}/link/codes/${id}`,
      providesTags: (result) => (result ? [{ type: 'Codes', id: result.id }] : []),
    }),
    getCodeStats: builder.query<CodeStats, {id: string, organizationId?: string, startDate?: string, endDate?: string}>({
      query: ({id, organizationId, startDate, endDate}) => addQueryParams(`/api/organizations/${organizationId}/link/codes/${id}/stats`, {startDate, endDate}),
      providesTags: (result, _error, id) => (result ? [{ type: 'Codes', id: `${id}-STATS-LIST` }] : []),
    }),
    deleteCode: builder.mutation<void, { codeId: string, organizationId?: string }>({
      query: ({ codeId, organizationId }) => ({
        url: `api/organizations/${organizationId}/link/codes/${codeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error) => [{ type: 'Codes', id: 'LIST' }],
    }),
    updateCode: builder.mutation<Code, { codeId: string; updateData: UpdateCodeRequestBody, organizationId: string }>({
      query: ({ codeId, updateData, organizationId }) => ({
        url: `api/organizations/${organizationId}/link/codes/${codeId}`,
        method: 'PATCH',
        body: cleanRequest(updateData),
      }),
      invalidatesTags: (_result, _error, { codeId }) => [
        { type: 'Codes', id: codeId },
        { type: 'Codes', id: 'TAGS-LIST' },
        { type: 'Codes', id: 'LIST' },
      ],
    }),
    getTags: builder.query<string[], string | undefined>({
      query: (organizationId) => `/api/organizations/${organizationId}/link/codes/tags`,
      providesTags: (result) => (result ? [{ type: 'Codes', id: 'TAGS-LIST' }] : []),
    }),
    getQrsCode: builder.query<ListResponse<QRCode>, {codeId: string, organizationId?: string}>({
      query: ({codeId, organizationId}) => `/api/organizations/${organizationId}/link/codes/${codeId}/qrs`,
      providesTags: (result, _error, id) => (result ? [{ type: 'Codes', id: id.codeId }] : []),
    }),
    createQrCode: builder.mutation<QRCode, { codeId: string, data: QRCodeRequestBody }>({
      query: ({ codeId, data }) => ({
          url: `/api/organizations/${data?.organizationId}/link/codes/${codeId}/qrs`,
          method: 'POST',
          body: createFormData(data)
      }),
      invalidatesTags: (_result, _error, { codeId }) => [
        { type: 'Codes', id: codeId },
      ],
    }),
    deleteQrCode: builder.mutation<void, { codeId: string, qrId: string, organizationId?: string }>({
      query: ({ codeId, qrId, organizationId }) => ({
        url: `/api/organizations/${organizationId}/link/codes/${codeId}/qrs/${qrId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { codeId }) => [{ type: 'Codes', id: codeId }],
    })
  }),
});

export const {
  useCreateCodeMutation,
  useDeleteCodeMutation,
  useGetCodesQuery,
  useGetCodeQuery,
  useGetCodeStatsQuery,
  useGetTagsQuery,
  useUpdateCodeMutation,
  useGetQrsCodeQuery,
  useCreateQrCodeMutation,
  useDeleteQrCodeMutation
} = codeApi;
