import { Box, BoxProps } from '@hyphen/hyphen-components';

export const PercentageBar = ({
  label,
  count,
  totalCount,
  color = 'chart-1',
}: {
  label: string;
  count: number;
  totalCount: number;
  color: BoxProps['background'];
}) => {
  const percentage = Math.round((count / totalCount) * 100);

  return (
    <Box gap="sm">
      <Box direction="row" gap="lg" justifyContent="space-between">
        <Box flex="auto">{label}</Box>
        <Box>{count}</Box>
        <Box color="secondary" minWidth="4xl" textAlign="right">
          {percentage}%
        </Box>
      </Box>
      <Box background="secondary" width="100" height="sm" radius="md" overflow="hidden">
        <Box background={color} width={`${percentage}%`} height="100" radius="md" />
      </Box>
    </Box>
  );
};
