import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';

export function Notifications() {
  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <PageHeader title="Notifications" description="Manage where to be notified" />
    </>
  );
}
