import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Spinner, toast } from '@hyphen/hyphen-components';
import { EventsFilter, ValuesFormProps } from './EventsFilter';
import { EventsTable } from './EventsTable';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useEffect, useState } from 'react';
import { useGetEventsQuery } from '../../services/events';
import { GetEventsBody } from '../../types/events';

const PAGE_SIZE = 50;

const initialFilters = {
  pageSize: PAGE_SIZE,
};

export const EventList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const types = searchParams.get('types')?.split(',');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  const newFilters: GetEventsBody['body'] = {
    ...initialFilters,
  };

  if (types) {
    newFilters.types = types;
  }
  if (startDate) {
    newFilters.startDate = startDate;
  }
  if (endDate) {
    newFilters.endDate = endDate;
  }

  const { organization = {} as Organization } = useOrganization();

  const [nextPageCursor, setNextPageCursor] = useState<string | undefined>(undefined);
  const [filters, setFilters] = useState<GetEventsBody['body']>(newFilters);

  const {
    data: events,
    isLoading,
    isFetching,
    refetch,
  } = useGetEventsQuery({
    orgId: organization?.id || '',
    body: { ...filters, pageCursor: nextPageCursor },
  });

  const [eventsContent, setEventsContent] = useState(events?.data || []);

  useEffect(() => {
    setEventsContent((prevEvents) => {
      if (events?.data) {
        if (prevEvents !== events?.data) {
          return [...prevEvents, ...events.data];
        }
      }
      return prevEvents;
    });
  }, [events?.data]);

  const updateURL = (newFilters: GetEventsBody['body']) => {
    const searchParams = new URLSearchParams();

    Object.entries(newFilters).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          searchParams.set(key, value.join(','));
        } else {
          searchParams.set(key, value as string);
        }
      } else {
        searchParams.delete(key);
      }
    });

    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleFilter = (values: ValuesFormProps) => {
    const { types, startDate, endDate } = values || {};

    const startOfDay = startDate && new Date(startDate);
    startOfDay?.setHours(0, 0, 0, 0);

    const endOfDay = endDate && new Date(endDate);
    endOfDay?.setHours(23, 59, 59, 999);

    const newFilters = {
      ...filters,
      types: types?.length ? types : undefined,
      startDate: startOfDay?.toISOString(),
      endDate: endOfDay?.toISOString(),
    };

    setFilters(newFilters);
    setEventsContent([]);
    setNextPageCursor(undefined);
    updateURL(newFilters);
  };

  const handleRefresh = async () => {
    try {
      const { error } = await refetch();
      if (!error) {
        toast.success('Event log refreshed', { duration: 2000 });
      } else {
        toast.error('Failed to refresh');
      }
    } catch (e) {
      toast.error('Failed to refresh');
    }
  };

  return (
    <Box gap="xl">
      <EventsFilter
        isLoadingEvents={isLoading}
        handleRefresh={handleRefresh}
        handleFilter={handleFilter}
        filters={filters}
      />

      {isLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner />
          Loading...
        </Box>
      ) : (
        <EventsTable
          events={events}
          eventsContent={eventsContent}
          setNextPageCursor={setNextPageCursor}
          isLoading={isLoading || isFetching}
        />
      )}
    </Box>
  );
};
