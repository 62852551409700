import {
  Box,
  Card,
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
  Spinner,
  useIsMobile,
} from '@hyphen/hyphen-components';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// Components
import { CreateTeam } from './Organization/CreateTeam';
import AppContainer from './Organization/AppContainer';
import CreateApp from './Organization/CreateApp';
import CreateLink from './Organization/CreateLink';
import CreateProject from './Organization/Projects/CreateProject';
import Dashboard from './Organization/Dashboard';
import EnvDashboard from './Organization/EnvDashboard';
import Events from './Organization/Events';
import IntegrationDetails from './Organization/Integrations/IntegrationDetails';
import { IntegrationSetup } from './Organization/Integrations/IntegrationSetup';
import IntegrationsOverview from './Organization/Integrations/IntegrationsOverview';
import ProjectContainer from './Organization/ProjectContainer';
import Settings from './Organization/Settings';
import ShortLinkDashboard from './Organization/ShortLinkDashboard';
import ShortLinkDetail from './Organization/ShortLinkDetail';
import AppSidebar from '../components/AppSidebar';
import TeamContainer from './Organization/TeamContainer';
import Teams from './Organization/Teams';

// Hooks and Utilities
import { useOrganizationsList } from '../providers/OrganizationsListProvider';
import { useEffect } from 'react';
import { OrganizationProvider, useOrganization } from '../providers/OrganizationProvider';
import { Organization, useGetOrganizationBillingAccountQuery } from '../services/organization';
import { OrganizationAbilityProvider } from '../components/auth/OrganizationAbilityProvider';
import { routes } from '..';
import OrgSettingsNav from './Organization/OrgSettingsNav';

export const OrganizationBase = () => {
  const { isLoading } = useOrganizationsList();

  const isMobile = useIsMobile();

  return (
    <OrganizationProvider>
      <OrganizationAbilityProvider>
        <Box
          direction={{ base: 'column', desktop: 'row' }}
          width="100"
          height="100"
          minHeight="100"
          margin="0"
          alignItems="stretch"
        >
          <SidebarProvider>
            <AppSidebar />
            <SidebarInset>
              {isMobile && (
                <Box direction="row" alignItems="center" gap="sm">
                  <SidebarTrigger />
                  <OrgSettingsNav />
                </Box>
              )}
              <Card height="100" radius={{ base: '0', tablet: 'md' }}>
                {isLoading ? <Spinner size="xl" /> : <MainContent />}
              </Card>
            </SidebarInset>
          </SidebarProvider>
        </Box>
      </OrganizationAbilityProvider>
    </OrganizationProvider>
  );
};

const MainContent = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  const { organization = {} as Organization } = useOrganization();
  const { isLoading, data } = useGetOrganizationBillingAccountQuery(organization.id);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (data?.paymentMethods.length === 0) {
        navigate(`/${routes.getStarted}/${data.id}`);
      }
    }
  }, [isLoading, data, navigate]);

  if (isLoading) return <Spinner size="xl" />;

  return (
    <Routes location={background || location}>
      <Route path="/" element={<Dashboard />} />
      <Route path="/projects/create" element={<CreateProject />} />
      <Route path="/link" element={<ShortLinkDashboard />} />
      <Route path="/link/create" element={<CreateLink />} />
      <Route path="/link/:id" element={<ShortLinkDetail />} />
      <Route path="/env" element={<EnvDashboard />} />
      <Route path="/integrations" element={<IntegrationsOverview />} />
      <Route path="/integrations/setup/:integrationSlug" element={<IntegrationSetup />} />
      <Route path="/integrations/:id" element={<IntegrationDetails />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="/teams" element={<Teams />} />
      <Route path="/teams/create" element={<CreateTeam />} />
      <Route path="/teams/:id/*" element={<TeamContainer />} />
      <Route path="/events/*" element={<Events />} />
      <Route path="/app/create" element={<CreateApp />} />
      <Route path="/:projectId/*" element={<ProjectContainer />} />
      <Route path="/:projectId/app/:appId/*" element={<AppContainer />} />
    </Routes>
  );
};
