import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { EventsResponse } from '../../types/events';

const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';
const ZOOM_LEVEL = 5;

interface EventMapProps {
  events: EventsResponse[];
}

type CityLocation = {
  city?: string;
  region?: string;
  position: { lat: number; lng: number };
  pushes: number;
  pulls: number;
}

export const EnvEventMap = ({ events }: EventMapProps) => {
  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  const cityLocations = Object.values(events.reduce((acc, event) => {
    const { location, type } = event;
    const { city, region, lat, lng } = location || {};

    if (city && region && lat && lng) {
      const cityRegionKey = `${city},${region}`;
      if (!acc[cityRegionKey]) {
        acc[cityRegionKey] = { city, region, position: { lat, lng }, pushes: 0, pulls: 0 };
      }
      if (type === 'ENV.CREATE') {
        acc[cityRegionKey].pushes += 1;
      } else if (type === 'ENV.GET') {
        acc[cityRegionKey].pulls += 1;
      }
    }
    return acc;
  }, {} as Record<string, CityLocation>))

  if (!cityLocations.length) {
    return null;
  }

  const center = {
    lat: cityLocations.reduce((sum, loc) => sum + loc.position.lat, 0) / cityLocations.length,
    lng: cityLocations.reduce((sum, loc) => sum + loc.position.lng, 0) / cityLocations.length,
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={ZOOM_LEVEL}
      >
        {cityLocations.map((location, index) => (
          <Marker
            key={index}
            position={location.position}
            title={`City: ${location.city}, ${location.region}\nPushed secrets: ${location.pushes}\nPulled secrets: ${location.pulls}`}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};
