import { Box, BoxProps, Button } from '@hyphen/hyphen-components';
import { PercentageBar } from './PercentageBar';
import { useEffect, useState } from 'react';

type UsageTableProps = {
  tableData: { name: string; total: number; }[];
  margin?: BoxProps['margin'];
  color?: BoxProps['background'];
};

export const VerticalBarChart = (props: UsageTableProps) => {
  const { color, tableData, margin } = props;

  const [showAll, setShowAll] = useState(true);

  const total = tableData.reduce((acc, item) => acc + item.total, 0);

  const numberItems = tableData.length;

  useEffect(() => {
    if (numberItems > 5) {
      setShowAll(false);
    }
  }, [numberItems]);

  return (
    <Box gap="2xl" fontSize="sm" margin={margin}>
      {tableData.map((item, i) => {
        if (showAll || i < 5) {
          return <PercentageBar color={color} key={item.name} label={item.name} count={item.total} totalCount={total} />;
        }
        return null;
      })}
      {numberItems > 5 && (
        <Box display="block" margin="lg 0 0 0">
          <Button variant="secondary" size="sm" onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show less' : 'Show all'}
          </Button>
        </Box>
      )}
    </Box>
  );
};
