import { Box, Button, FormikTextInput, Modal, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import { useCallback } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Code, UpdateCodeRequestBody } from '../../types/zelda';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useUpdateCodeMutation } from '../../services/zelda/codes';
import { Organization } from '../../services/organization';
import { ApiError } from '../ApiError';

const newLabelSchema = (shortDomain: string) => {
  return yup.object().shape({
    newLabel: yup.string(),
  });
};

type SchemaReturnType<T extends (...args: any[]) => any> = ReturnType<T> extends infer U
  ? U extends object
    ? // @ts-ignore
      InferType<U>
    : never
  : never;

type newLabelFormSchema = SchemaReturnType<typeof newLabelSchema>;

interface EditTitleModalProps {
  isOpen: boolean;
  closeTitle: () => void;
  toggleTitle: () => void;
  codeData?: Code;
}

export const EditTitleModal = ({ codeData, isOpen, closeTitle, toggleTitle }: EditTitleModalProps) => {
  const [updateShortLink, { error }] = useUpdateCodeMutation();
  const { organization = {} as Organization } = useOrganization();

  const handleApplyTitle = useCallback(
    async (values: newLabelFormSchema) => {
      if (!codeData) {
        return;
      }

      const body: UpdateCodeRequestBody = {
        title: values.newLabel,
      };

      const { error, data } = await updateShortLink({
        codeId: codeData.id,
        updateData: body,
        organizationId: organization.id,
      });
      if (!error && data) {
        toast.success('Label saved');
        closeTitle();
      }
    },
    [codeData, closeTitle, updateShortLink, organization.id],
  );

  return (
    <>
      <Modal ariaLabelledBy="titleModal" isOpen={isOpen} onDismiss={closeTitle} maxWidth="9xl">
        <Formik
          initialValues={{ newLabel: codeData?.title }}
          validationSchema={newLabelSchema}
          onSubmit={handleApplyTitle}
        >
          {({ isSubmitting, errors }) => (
            <Form noValidate>
              <Box gap={{ base: '2xl', tablet: '4xl' }}>
                <Modal.Header id="titleModal" title="Edit Label" onDismiss={closeTitle} />
                <Modal.Body gap="2xl" overflow="visible">
                  <Field
                    type="text"
                    label="Label"
                    name="newLabel"
                    id="newLabel"
                    component={FormikTextInput}
                    error={errors.newLabel}
                  />
                  {error ? <ApiError error={error} /> : null}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeTitle} isDisabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Modal.Footer>
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
