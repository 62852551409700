import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MyProfile } from '../components/profile/MyProfile';
import { Notifications } from '../components/profile/Notifications';
import { UserSidebarProvider } from '../providers/UserSidebarProvider';

export function Profile() {
  return (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <UserSidebarProvider>
        <Routes>
          <Route path="/" element={<MyProfile />} />
          <Route path="/notifications" element={<Notifications />} />
        </Routes>
      </UserSidebarProvider>
    </>
  );
}
