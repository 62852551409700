import { Box, Card } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { useOrganization } from '../../providers/OrganizationProvider';
import OrganizationAvatarUpload from './Settings/OrganizationAvatarUpload';
import { Organization } from '../../services/organization';
import { OrganizationInfoForm } from '../../components/organization/OrganizationInfoForm';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';

export default function OrgSettings() {
  const { organization = {} as Organization } = useOrganization();

  const ability = useOrganizationAbilityContext();
  const canManageOrg = ability.can('manage', EntityNames.Organization);

  return (
    <Box width="100" maxWidth="9xl" gap="4xl">
      <Helmet>
        <title>Organization Settings</title>
      </Helmet>

      {canManageOrg ? (
        <>
          <PageHeader title="Organization Settings" description="Manage your organization's settings" />
          <Card>
            <Card.Section>
              <OrganizationAvatarUpload organization={organization} />
            </Card.Section>
          </Card>
          <OrganizationInfoForm organization={organization} />
        </>
      ) : (
        <Box fontSize="sm" color="secondary">
          You do not have access to manage this organization's settings
        </Box>
      )}
    </Box>
  );
}
