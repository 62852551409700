import { Box } from '@hyphen/hyphen-components';

interface IntegrationInfoBoxProps {
  label: string;
  value: string;
}

export const IntegrationInfoBox = ({ label, value }: IntegrationInfoBoxProps) => (
  <Box
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    borderWidth="0 0 xs 0"
    borderColor="default"
    padding="0 0 xs 0"
  >
    <Box fontWeight="medium">{label}</Box>
    <Box>{value}</Box>
  </Box>
);
