import { Box, Button, Heading, Table } from '@hyphen/hyphen-components';
import { Column, UnknownPropertiesObjType } from '@hyphen/hyphen-components/dist/types';
import { ListResponse } from '../../services/types';
import { EventsResponse } from '../../types/events';

interface EventsTableContentProps {
  events?: ListResponse<EventsResponse, 'Cursor'>;
  tableData: UnknownPropertiesObjType[];
  columnConfig: Column[];
  setNextPageCursor: (pageCursor: string) => void;
  isLoading: boolean;
}

export const EventsTableContent = ({
  events,
  tableData,
  columnConfig,
  setNextPageCursor,
  isLoading,
}: EventsTableContentProps) => {
  const eventsData = events?.data || [];

  const handleLoadMore = () => {
    if (events?.nextPageCursor) {
      setNextPageCursor(events.nextPageCursor);
    }
  };

  if (eventsData.length === 0 && tableData.length === 0 && !isLoading)
    return (
      <Box width="100" height="7xl" alignItems="center" justifyContent="center" gap="md" textAlign="center">
        <Heading as="h2" size={{ base: 'sm', tablet: 'md' }}>
          No events have been logged
        </Heading>
        <Box as="p" fontSize="sm" color="secondary">
          You'll see events here when members create integrations or manage resources
        </Box>
      </Box>
    );

  return (
    <Box gap="xl">
      {eventsData.length === 0 && tableData.length === 0 && !isLoading ? (
        <Box width="100" height="7xl" alignItems="center" justifyContent="center" gap="md" textAlign="center">
          <Heading as="h2" size={{ base: 'sm', tablet: 'md' }}>
            No events have been logged
          </Heading>
          <Box as="p" fontSize="sm" color="secondary">
            You'll see events here when members create integrations or manage resources
          </Box>
        </Box>
      ) : (
        <>
          <Table
            isScrollable={{
              x: true,
              y: false,
            }}
            rowKey="key"
            isLoading={isLoading}
            columns={columnConfig}
            rows={tableData}
          />
          {events?.nextPageCursor ? (
            <Box alignItems="flex-start">
              <Button onClick={handleLoadMore} size="sm" variant="secondary">
                Load more
              </Button>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};
