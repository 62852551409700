import {
  Box,
  Button,
  FormikToggle,
  FormikTextInput,
  Card,
  FormikSelectInput,
  toast,
} from '@hyphen/hyphen-components';
import { Link, useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { ApiError } from '../../components/ApiError';
import { DynamicTeamsForm } from '../../components/teams/DynamicTeamsForm';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { useCreateTeamMutation, useReplaceTeamMembersMutation } from '../../services/teams';
import { createTeamFormSchema, CreateTeamFormSchema, typeOptions } from '../../schema/teams';
import { Team, TeamType } from '../../types/teams';
import { useCreateMemberMutation, useGetMembersQuery, useLazyGetMembersQuery } from '../../services/member';
import { CreateMemberData, MemberSelect } from '../../components/types/member';

const initialValues = {
  name: '',
  teamType: typeOptions[0].value as TeamType,
  criteria: [{ type: 'property', field: 'firstName', operator: 'equals', value: '' }],
};


export const CreateTeam = () => {
  const { organization = {} as Organization } = useOrganization();

  const organizationId = organization.id;
  const navigate = useNavigate();

  const [CreateTeam, { error }] = useCreateTeamMutation();
  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [replaceTeamMembers, { error: replaceError }] = useReplaceTeamMembersMutation();

  const queryParams = ({ pageNum: 1, pageSize: 50 });
  const { data: initialMembers, isLoading: initialLoading } = useGetMembersQuery({ organizationId, ...queryParams });
  const [trigger] = useLazyGetMembersQuery();


  const [membersListData, setMembersListData] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (!initialLoading && initialMembers) {
      const membersList = initialMembers.data.map((member) => ({
        value: member.id,
        label: member.email,
      }));
      setMembersListData(membersList);
    }
  }, [initialMembers, initialLoading]);

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    const filteredMembers = await trigger({ organizationId: organization.id, search: inputValue }).unwrap();
    return filteredMembers?.data.map((member) => ({
      value: member.id,
      label: member.email,
    })) || [];
  };

  const handleInviteMember = useCallback(async (newMembers: MemberSelect[]) => {
    const responses = await Promise.all(newMembers.map(async member => await createMember({
      email: member.label,
      organizationId,
      firstName: '',
      lastName: '',
    }))) as CreateMemberData[];

    if(responses.length) {
      return responses.map(response => response.data?.id);
    }

    return [];
  }, [createMember, organizationId])

  const handleAddMember = useCallback(async (values: CreateTeamFormSchema, id: string) => {
    let memberIds = values.members
      ?.filter((member: MemberSelect) => !member.__isNew__)
      .map((member: MemberSelect) => member.value) ?? [];

    const newMembers = values.members?.filter((member: MemberSelect) => member.__isNew__);
    const newMemberIds = newMembers?.length ? await handleInviteMember(newMembers): [];

    if (newMemberIds.length) {
      memberIds = [...memberIds, ...newMemberIds];
    }

    const { error } = await replaceTeamMembers({
      orgId: organizationId,
      teamId: id,
      data: { memberIds }
    });

    if (!error) {
      toast.success('Member added', { duration: 5000 });
    }
  }, [handleInviteMember, organizationId, replaceTeamMembers]);


  const handleSubmit = async (values: CreateTeamFormSchema) => {
    const team: Omit<Team, 'id' | 'memberCount'> = {
        name: values.name,
        type: values.teamType,
      };

      if (values.teamType === 'dynamic') {
        // @ts-ignore
        team.criteria = values.criteria;
      }

      const { data, error } = (await CreateTeam({
        orgId: organizationId,
        team,
      })) as any;

      if (!error) {
        if(values.teamType === 'static' && values.members?.length) {
          await handleAddMember(values, data.id);
        }

        navigate(`/${organizationId}/teams/${data.id}`);
      }
    };

  return (
    <ScrollableMainLayout>
      <Helmet title="Create Team" />
      <PageHeader
        eyebrow={<Link to={`/${organization?.id}/teams`}>Teams</Link>}
        title="Create Team"
        description="Use teams to group organization members together to manage access to resources and for sending notifications"
      />
      {/*@ts-ignore*/}
      <Formik initialValues={initialValues} validationSchema={createTeamFormSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, errors, values, setFieldValue }) => {
          return (
            <Form noValidate>
              <Box gap="4xl">
                <Field
                  label="Team Name"
                  name="name"
                  id="name"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                  error={errors.name}
                  isRequired
                />
                {values.teamType === 'static' ? (
                  <Box direction="row" gap="3xl" alignItems="center">
                    <Field
                      label="Members"
                      name="members"
                      id="members"
                      options={loadOptions}
                      component={FormikSelectInput}
                      isDisabled={isSubmitting}
                      isMulti
                      isCreatable
                      isAsync
                      cacheOptions
                      error={errors.members}
                      defaultOptions={membersListData}
                    />
                  </Box>
                ) : null}
                <Card>
                  <Card.Section>
                    <Field
                      label="Dynamic Team"
                      helpText="Automatically organize team membership based on criteria you specify"
                      name="teamType"
                      id="teamType"
                      isChecked={values.teamType === 'dynamic'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('teamType', e.target.checked ? 'dynamic' : 'static');
                      }}
                      component={FormikToggle}
                    />
                  </Card.Section>

                  {values.teamType === 'dynamic' ? (
                    <Card.Section padding={{ base: '2xl', tablet: '2xl 2xl 2xl 5xl' }} gap="xl">
                      <DynamicTeamsForm values={values} isSubmitting={isSubmitting} />
                    </Card.Section>
                  ) : null}
                </Card>

                {(error || createError || replaceError) && <ApiError error={error || createError || replaceError} />}

                <Box direction="row" gap="sm">
                  <Button variant="primary" type="submit" isLoading={isSubmitting}>
                    Create Team
                  </Button>
                  <Button
                    variant="tertiary"
                    isDisabled={isSubmitting}
                    onClick={() => navigate(`/${organization?.id}/teams`)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </ScrollableMainLayout>
  );
};
