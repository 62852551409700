import { useNavigate, useLocation } from 'react-router-dom';

import queryString from 'query-string';
import { useMemo } from 'react';

export default function useQueryStringState(
  defaults = {} as Record<string, any>,
): [any | undefined, (state: Record<string, any>) => void] {
  const nav = useNavigate();
  const location = useLocation();

  const queryStringState = useMemo(() => {
    const parsedQuery = queryString.parse(location.search, {
      parseBooleans: true,
      parseNumbers: true,
      arrayFormat: 'comma',
    });
    return {
      ...defaults,
      ...parsedQuery,
    };
  }, [location.search, defaults]);

  const updateQueryStringState = (newState: Record<string, any>) => {
    Object.keys(newState).forEach((k) => {
      if (newState[k] === defaults[k]) {
        delete newState[k];
      }
    });

    const qs = queryString.stringify(newState, { arrayFormat: 'comma' });
    nav(
      {
        pathname: location.pathname,
        search: qs,
      },
      { replace: true },
    );
  };
  return [queryStringState, updateQueryStringState];
}
