import { Box, Button, Card, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { Organization, useUpdateOrganizationMutation } from '../../services/organization';
import { Field, Form, Formik } from 'formik';
import { InferType } from 'yup';
import * as yup from 'yup';
import { ApiError } from '../ApiError';

export interface OrganizationInfoProps {
  organization: Organization;
}

const organizationInfoSchema = yup.object().shape({
  name: yup.string().required('Organization name is required'),
});

export type OrganizationInfoSchema = InferType<typeof organizationInfoSchema>;

export const OrganizationInfoForm = ({ organization }: OrganizationInfoProps) => {
  const initialValues = {
    name: organization.name,
  };

  const [updateOrganization, { error }] = useUpdateOrganizationMutation();

  const handleSubmitOrganizationInfo = async (values: OrganizationInfoSchema) => {
    const { error, data } = await updateOrganization({
      id: organization.id,
      data: values,
    });

    if (!error && data) {
      toast.success('Organization updated');
    }
  };

  return (
    <Card>
      <Card.Header title="Organization Name" />
      <Card.Section gap="xl">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={organizationInfoSchema}
          onSubmit={handleSubmitOrganizationInfo}
        >
          {({ errors, isSubmitting }) => (
            <Form noValidate>
              <Box gap="2xl">
                <Field
                  type="text"
                  label="Name"
                  name="name"
                  id="name"
                  autoComplete="off"
                  hideLabel
                  component={FormikTextInput}
                  error={errors.name}
                  isRequired
                  isDisabled={isSubmitting}
                />
                {error ? <ApiError error={error} title="Error updating organization" /> : null}
                <Box display="block">
                  <Button variant="primary" type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Card.Section>
    </Card>
  );
};
